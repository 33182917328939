import React, { useEffect, useState } from 'react'
import { Close } from 'Assets'
import PinCodeInput from 'Components/PinCodeInput'
import Loader from 'Components/Loader'
import AttemptsValidationMessages from './AttemptsValidationMessages'
import ActionButtons from './ActionButtons'
import {
  Body,
  BodyHeader,
  BodyResendSection,
  CloseButton,
  Header,
  SModal,
} from './styles'

interface Props {
  value: string
  show: boolean
  hasPinCodeError?: boolean
  isLoading?: boolean
  attempts: number
  onResendCode(): void
  onChange(value: string): void
  onConfirm(value: string): void
  onCancel(): void
}

export default function PinCodeConfirmModal({
  value,
  show,
  hasPinCodeError,
  attempts,
  onConfirm,
  onCancel,
  onChange,
  onResendCode,
  isLoading,
}: Props) {
  const [showLastAttemptAlert, setShowLastAttemptAlert] = useState<boolean>(
    false
  )

  useEffect(() => {
    if (attempts === 1) {
      setShowLastAttemptAlert(true)
    }
  }, [attempts])

  function handleConfirm() {
    if (showLastAttemptAlert) {
      setShowLastAttemptAlert(false)
    } else {
      onConfirm(value)
    }
  }

  function handlePinCodeValue(pinCode: string) {
    onChange(pinCode)
  }

  function handleReSendChallenge() {
    onResendCode()
  }

  return (
    <SModal show={show} size="md" onHide={onCancel}>
      <Header>
        <CloseButton onClick={onCancel}>
          <img src={Close} alt="Botão fechar modal" />
        </CloseButton>
      </Header>
      <Body>
        {isLoading ? (
          <Loader spinner />
        ) : (
          <>
            <BodyHeader>
              <h2>Código de segurança</h2>
              <p>
                Enviamos um código de segurança para o seu e-mail cadastrado.
                Para finalizar a operação, insira o código abaixo.
              </p>
            </BodyHeader>

            {[1, 2].includes(attempts) && (
              <AttemptsValidationMessages
                attempts={attempts}
                showLastAttemptAlert={showLastAttemptAlert}
              />
            )}

            {!showLastAttemptAlert && (
              <PinCodeInput
                hasPinCodeError={hasPinCodeError}
                onChange={handlePinCodeValue}
              />
            )}

            <BodyResendSection>
              {hasPinCodeError && (
                <h4>Este código é inválido. Verifique o código enviado.</h4>
              )}
              <h3>
                Você deseja reenviar o código?
                <button type="button" onClick={handleReSendChallenge}>
                  Reenviar código
                </button>
              </h3>
              <span>
                Caso não tenha mais acesso à este e-mail, favor entrar em
                contato com a Stone.
              </span>
            </BodyResendSection>

            <ActionButtons
              value={value}
              showLastAttemptAlert={showLastAttemptAlert}
              showCancelButton={attempts !== 3}
              handleConfirm={handleConfirm}
              handleCancel={onCancel}
            />
          </>
        )}
      </Body>
    </SModal>
  )
}
