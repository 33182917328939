import React from 'react'
import { hasStoneCode } from '../../../../../Services/base.auth'
import { appConfig } from '../../../../../Config'

export default function Sales() {
  const salesURL = `${appConfig.portalURL}/extratos/transacoes/vendas-por-transacao`
  if (hasStoneCode())
    return (
      <li className="menu__item">
        <a className="menu__handle" href={salesURL}>
          Vendas
        </a>
      </li>
    )
  return <></>
}
