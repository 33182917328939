import { IStoneCode } from '../Interfaces'

export const findItemByDocumentFrom = (
  stoneCodesList: IStoneCode[],
  document: string
): IStoneCode | undefined => {
  if (!stoneCodesList || !stoneCodesList.length) return undefined

  return stoneCodesList.find(element => element.cnpj === document)
}

export const getStoneCodeByDocumentFrom = (
  stoneCodesList: IStoneCode[],
  document: string
): string => {
  if (!stoneCodesList || !stoneCodesList.length) return ''

  const stoneCodeFound = findItemByDocumentFrom(stoneCodesList, document)

  return stoneCodeFound
    ? stoneCodeFound.stone_code
    : stoneCodesList[0].stone_code
}
