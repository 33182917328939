import styled from 'styled-components'
import { Colors } from 'Styles/theme'

export const HeaderContent = styled.div`
  margin-top: 20px;

  p {
    margin: 0px;
  }
`

export const Heading = styled.div`
  text-align: center;
  font-size: 16px;
`

export const Spotlight = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: ${Colors.darkgreen};
`

export const Title = styled.h3`
  margin: 1em 0 0;
  font-weight: 600;
  font-size: 24px;
  color: ${Colors.darkblue};
`
