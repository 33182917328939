import styled, { css } from 'styled-components'

import { Badge } from 'react-bootstrap'
import Card from 'Components/Card'

import { Colors } from 'Styles/theme'

export enum Variant {
  CANCELLED,
}

export const SCard = styled(Card)`
  display: flex;
  text-align: left;
  padding-left: 24px;
  margin: 8px;
  align-items: center;
`
const cancelledBadgeStyle = css`
  background: #fff;
  border: 1px solid ${Colors.mediumred};
  color: ${Colors.mediumred};
`

export const SBadge = styled(Badge)<{ variant: Variant }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 24px;
  border-radius: 50px;
  background: ${Colors.lightgreen};
  padding: 5px;
  min-width: 100px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;

  ${({ variant }) =>
    variant === Variant.CANCELLED ? cancelledBadgeStyle : null}
`
export const Title = styled.span`
  font-size: 16px;
  line-height: 24px;
  color: ${Colors.darkblue};
`
