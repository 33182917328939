import React from 'react'

import { LoanStatusEnum } from 'Enums'

import { SBadge, Title, Variant } from './styles'

interface Messages {
  status: LoanStatusEnum
}

interface MessageProps {
  badge: string
  badgeVariant?: Variant
  title: string
  subtitle: string
}

function Message({ badge, badgeVariant, title, subtitle }: MessageProps) {
  return (
    <>
      <SBadge variant={badgeVariant}>{badge}</SBadge>
      <Title>
        <strong>{title} </strong>
        {subtitle}
      </Title>
    </>
  )
}

export default function Messages({ status }: Messages) {
  switch (status) {
    case LoanStatusEnum.ACTIVE:
      return (
        <Message
          badge="Ativo"
          title="Empréstimo em andamento"
          subtitle="Acompanhe o status de quitação do empréstimo."
        />
      )
    case LoanStatusEnum.DISBURSEMENT_REQUESTED:
    case LoanStatusEnum.DISBURSEMENT_CONFIRMED:
    case LoanStatusEnum.ACCEPTED:
      return (
        <Message
          badge="Em análise"
          title="Empréstimo solicitado"
          subtitle="Seu pedido está sendo analisado"
        />
      )
    case LoanStatusEnum.SETTLED:
      return (
        <Message
          badge="Quitado"
          title="Parabéns!"
          subtitle="Você quitou o seu empréstimo"
        />
      )
    case LoanStatusEnum.SUSPENDED:
    case LoanStatusEnum.SUSPENDED_DISBURSEMENT:
      return (
        <Message
          badge="Pausado"
          title="Empréstimo pausado"
          subtitle="A retenção dos seus recebíveis está temporariamente pausada"
        />
      )
    case LoanStatusEnum.SUSPENDED_NON_DISBURSEMENT:
      return (
        <Message
          badge="Pausado"
          title="Empréstimo solicitado"
          subtitle="Seu pedido está sendo analisado"
        />
      )
    case LoanStatusEnum.CANCELLED:
      return (
        <Message
          badge="Cancelado"
          badgeVariant={Variant.CANCELLED}
          title="Empréstimo cancelado"
          subtitle="Houve um problema com a trava bancária"
        />
      )

    default:
      return <></>
  }
}
