import React from 'react'

import { addSpecialCharacterTo } from 'Utils/document'
import { Column, Label, Row } from './styles'
import { Link } from '../styles'

interface ContractDetails {
  document: string
  dueDate: string
  contractUri: string
}

export default function ContractDetails({
  document,
  dueDate,
  contractUri,
}: ContractDetails) {
  const contractDueDateFormatted = new Date(dueDate).toLocaleDateString('pt-br')
  return (
    <Row>
      <Column>
        <Label>Documento</Label>
        {addSpecialCharacterTo(document)}
      </Column>
      <Column>
        <Label>Vencimento do contrato</Label>
        {contractDueDateFormatted}
      </Column>
      <Column>
        <Link target="blank" href={contractUri}>
          Ver contrato
        </Link>
      </Column>
    </Row>
  )
}
