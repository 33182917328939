import React, { useContext } from 'react'
import { ErrorMapperEnum } from 'Enums/proposal'
import GeneralError from 'Components/LayoutState/Internals/GeneralError'
import Loader from 'Components/Loader'
import Card from 'Components/Card'
import { removeSpecialCharacterFrom } from 'Utils/document'
import CnpjNotAllowed from 'Components/LayoutState/Internals/CnpjNotAllowed'
import BankDataWarningState from 'Components/LayoutState/Internals/BankDataWarning'
import { Permission } from 'Enums/permissions'
import UserContext from 'Contexts/UserContext'
import { CreditLinesStoreContext } from 'Contexts/CreditLineContext'
import { IProposal } from 'Interfaces'
import AlreadyHasActiveLoan from 'Components/LayoutState/Internals/AlreadyHasActiveLoan'
import { appConfig } from 'Config'
import BankLockErrorState from 'Components/LayoutState/Internals/BankLockError'
import BankSavingsWarning from 'Components/LayoutState/Internals/BankSavingsWarning'

interface Props {
  document: string
  amount: number
  creditLineId: string
  canDoProposal(proposal: IProposal | null | boolean): void
  useProposal(
    document: string,
    creditAmount: number,
    creditLineId: string
  ): [IProposal | null, boolean, ErrorMapperEnum]
}

export default function DoProposal({
  document,
  amount,
  creditLineId,
  canDoProposal,
  useProposal,
}: Props) {
  const user = useContext(UserContext)
  const { refresh: refreshCreditLine } = useContext(CreditLinesStoreContext)

  const [proposal, isLoading, error] = useProposal(
    removeSpecialCharacterFrom(document),
    amount,
    creditLineId
  )

  if (isLoading) {
    return (
      <Card>
        <Loader spinner />
      </Card>
    )
  }

  if (error === ErrorMapperEnum.CREDIT_LOCK) {
    return <BankLockErrorState onClickButton={() => canDoProposal(false)} />
  }

  if (error === ErrorMapperEnum.INVALID_CNPJ) {
    return <CnpjNotAllowed onBack={() => canDoProposal(false)} />
  }

  if (error === ErrorMapperEnum.INVALID_BANK_ACCOUNT) {
    if (user?.permissions.includes(Permission.ALTER_BANK_ACCOUNT)) {
      return (
        <BankDataWarningState
          hasPermissionToUpdate
          onClickButton={() =>
            window.location.assign(
              `${appConfig.portalURL}/configuracoes/dados-cadastrais`
            )
          }
        />
      )
    }

    return <BankDataWarningState />
  }

  if (error === ErrorMapperEnum.INVALID_BANK_ACCOUNT_SAVING) {
    if (user?.permissions.includes(Permission.ALTER_BANK_ACCOUNT)) {
      return (
        <BankSavingsWarning
          hasPermissionToUpdate
          onClickButton={() =>
            window.location.assign(
              `${appConfig.portalURL}/configuracoes/dados-cadastrais`
            )
          }
        />
      )
    }
    return <BankSavingsWarning />
  }

  if (error === ErrorMapperEnum.CREDIT_LINE_IS_NOT_ACTIVE) {
    refreshCreditLine()
    return null
  }

  if (
    [
      ErrorMapperEnum.CREDIT_LINE_ALREADY_HAS_LOAN,
      ErrorMapperEnum.BORROWER_ALREADY_HAS_LOAN,
    ].includes(error)
  ) {
    return <AlreadyHasActiveLoan onBack={() => canDoProposal(false)} />
  }

  if (error === ErrorMapperEnum.GENERAL) {
    return <GeneralError onRetry={() => canDoProposal(false)} />
  }

  canDoProposal(proposal)

  return <></>
}
