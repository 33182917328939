import axios, { CancelTokenSource } from 'axios'
import { IProposal } from 'Interfaces'
import getProposal from './getProposal'

export interface IGetProposalProps {
  getCancelTokenSource(source: CancelTokenSource): void
  document: string
  creditAmount: number
  creditLineId: string
}

export interface IProposalModel {
  getProposal(props?: IGetProposalProps): Promise<IProposal>
}

export default (): IProposalModel => {
  return {
    getProposal: ({
      getCancelTokenSource,
      document,
      creditAmount,
      creditLineId,
    }: IGetProposalProps) => {
      const source = axios.CancelToken.source()

      const request = getProposal({
        axiosConfig: {
          cancelToken: source.token,
        },
        document,
        creditAmount,
        creditLineId,
      })

      getCancelTokenSource(source)

      return request
    },
  }
}
