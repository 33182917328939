import React from 'react'

import { HeaderContent, Heading, Spotlight, Title } from './styles'

export default function Header() {
  return (
    <Heading>
      <Title>Empréstimo Stone</Title>
      <HeaderContent>
        <p>
          Reforce o orçamento do seu negócio e fique tranquilo para evoluir
          ainda mais.
        </p>
        <p>
          Com o Empréstimo Stone, você contrata
          <Spotlight> apenas o necessário</Spotlight> e
          <Spotlight> paga do jeito mais fácil: vendendo</Spotlight>!
        </p>
      </HeaderContent>
    </Heading>
  )
}
