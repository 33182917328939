import React, { useContext } from 'react'

import LoanExtract from 'BusinessComponent/LoanExtract'
import TabSelectedContext from 'Contexts/TabSelectedContext'
import { TabFullScreenEnum } from 'Enums'
import ExtractFiltersProvider from 'Contexts/ExtractFiltersContext'
import { Container } from './styles'

export interface IExtractProps {
  document: string
}

const Extract: React.FC<IExtractProps> = ({ document }) => {
  const { setTabFullScreenSelected } = useContext(TabSelectedContext)

  function handleClose() {
    setTabFullScreenSelected(TabFullScreenEnum.DETAIL)
  }

  function handleRetry() {
    window.location.reload()
  }

  return (
    <ExtractFiltersProvider document={document}>
      <Container>
        <LoanExtract onClose={handleClose} onRetry={handleRetry} />
      </Container>
    </ExtractFiltersProvider>
  )
}

export default Extract
