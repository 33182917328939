import { darken } from 'polished'

const primaryGreen = '#0DB14B'

export const Colors = {
  black: '#242932',
  blue: '#477dca',
  darkblue: '#2D3844',
  cyanblue: '#677481',
  lavenderblue: '#ebebf0',
  darkgray: '#333',
  darkgrey2: '#3B4652',
  darkgrey3: '#646D76',
  gray6: '#F7F8FC',
  gray5: '#F8F9FD',
  gray4: '#EFEFEF',
  gray3: '#96A0AF',
  gray2: '#E5E5E5',
  gray: '#DCE0E9',
  stonegreen1: primaryGreen,
  stonegreen2: darken(0.07, primaryGreen),
  stonegreen3: darken(0.3, primaryGreen),
  darkgreen: '#088037',
  errorcolor: '#ed2b30',
  accent2: '#e74c3c',
  alertcolor: '#f7c31e',
  donecolor: '#6fba4c',
  receiptcolor: '#ffffe2',
  rategreen: '#6CB94A',
  titlegreen: '#50B84D',
  futurecolor: '#ffbe0d',
  lilac: '#8F56B2',
  lightred: '#F56565',
  lightgray: '#dddddd',
  lightblue: '#C3C8D2',
  lightgreen: '#14AA4B',
  lightazureishgray: '#f4f4f9',
  darkazureishgray: '#424b54',
  lightmediumgray: '#979797',
  mediumtodarkgray: '#666',
  mediumred: '#f96767',
  red: '#E35555',
  white: '#ffffff',
}

export const Typography = {
  baseFontSize: '1em',
  baseFontWeight: 400,
  baseFontFamily: 'Open Sans, Neue, Helvetica, Roboto, Arial, sans-serif',
  buttonFontFamily: 'Open Sans, Helvetica Neue, Helvetica, Roboto, Arial',
  headingFontWeight: 500,
}

export const Spacing = {
  headingLineHeight: 1.2,
  baseLineHeight: 1.5,
}
