import { useCallback, useEffect, useState } from 'react'
import { fetchCreditBankLockFaq } from 'Services/firebase'
import { IFirebaseFaqLock } from 'Interfaces/firebaseFaqLock.interface'

export interface IUseFirebaseProps {
  creditBankLockFaqFunction?(): Promise<IFirebaseFaqLock>
}

export interface IUseFirebase {
  faqLockList: IFirebaseFaqLock | null
  isLoading: boolean
  error: boolean
}

export function useFirebase({
  creditBankLockFaqFunction = fetchCreditBankLockFaq,
}: IUseFirebaseProps = {}): IUseFirebase {
  const [faqLockList, setFaqLockList] = useState<IFirebaseFaqLock | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)

  const loadFaqLock = useCallback(async () => {
    try {
      setError(false)
      setIsLoading(true)

      const data = await creditBankLockFaqFunction()
      setFaqLockList(data)
    } catch (err) {
      setIsLoading(false)
      setError(true)
    }
  }, [creditBankLockFaqFunction])

  useEffect(() => {
    loadFaqLock()
  }, [loadFaqLock])

  return {
    faqLockList,
    isLoading,
    error,
  }
}
