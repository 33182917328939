import styled from 'styled-components'

import { Colors } from 'Styles/theme'

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const Title = styled.h1`
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: ${Colors.darkblue};
  margin: 0;
`

export const Label = styled.label`
  line-height: 18px;
  color: ${Colors.gray3};
  font-size: 14px;
  font-weight: 450;
  margin: 24px 0px 5px;
`

export const InfoLabel = styled(Label)`
  font-size: 12px;
  font-weight: normal;
  line-height: 15px;
  color: ${Colors.cyanblue};
`
