import { Modal } from 'react-bootstrap'
import styled from 'styled-components'
import { Colors } from 'Styles/theme'

export const SModal = styled(Modal)`
  .modal-dialog {
    max-width: 602px;
  }

  .modal-content {
    border-radius: 10px;
    background: ${Colors.lightazureishgray};

    .area-tabs {
      margin-top: 32px;
      padding: 0 18px;
    }

    .content {
      padding: 12px 16px;
    }
  }
`

export const Body = styled(Modal.Body)`
  border-radius: 0px 0px 10px 10px;
  padding-bottom: 40px;

  * {
    font-family: CircularStd;
  }
`
export const Header = styled(Modal.Header)`
  border-radius: 10px 10px 0px 0px;
  padding: 25px 20px 0px;

  border: none;
`
export const Title = styled(Modal.Title)`
  line-height: 30px;
  letter-spacing: 0.4px;
  color: ${Colors.darkblue};
  font-family: CircularStd;
  font-size: 24px;
  font-weight: bold;
  margin-left: 20px;
`
