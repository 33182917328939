import React from 'react'

import LayoutState from 'Components/LayoutState/Internals/BaseState'
import SuccessNotify from 'Assets/success-notify.svg'

export default function CreditAlertCreated() {
  return (
    <LayoutState
      title="Seu aviso foi criado com sucesso!"
      subtitle="Agora é só esperar. Avisaremos assim que o Empréstimo Stone estiver disponível para contratação."
      icon={SuccessNotify}
      button={<div />}
    />
  )
}
