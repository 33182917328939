import React, { useEffect } from 'react'

import { LoanStatusEnum } from 'Enums'

import { ILoan } from 'Interfaces'
import { useHistory } from 'react-router-dom'
import { BannerExtract, Content, Line, SCard, Wrapper } from './styles'

import Header from './Header'
import StatusCard from './StatusCard'
import ProgressCard from './ProgressCard'
import TaxDetails from './TaxDetails'
import ContractDetails from './ContractDetails'
import PaymentDetails from './PaymentDetails'
import BankLockDetails from './BankLockDetails'

interface LoanDetails {
  loan: ILoan
  status: LoanStatusEnum
  onBack(): void
  onShowExtract(): void
  onKnowMore(): void
}

const statusWithoutProgressBar = [
  LoanStatusEnum.DISBURSEMENT_REQUESTED,
  LoanStatusEnum.DISBURSEMENT_CONFIRMED,
  LoanStatusEnum.ACCEPTED,
]

const hideBannerIfStatus = [
  ...statusWithoutProgressBar,
  LoanStatusEnum.CANCELLED,
]

export default function LoanDetails({
  loan,
  status,
  onBack,
  onShowExtract,
  onKnowMore,
}: LoanDetails) {
  const history = useHistory()

  useEffect(() => {
    history.push(`/emprestimos/${loan.borrower.document}/detalhes/${loan.id}`)
  }, [history, loan])

  return (
    <Wrapper data-testid="loan-details">
      <Content md={9}>
        <Header onBack={onBack} date={loan.creationDate} />
        <StatusCard status={status} />
        <SCard data-testid="loan-details-body-card">
          {!statusWithoutProgressBar.includes(status) && (
            <>
              <ProgressCard
                totalPaidAmount={loan.totalPaidAmount}
                currentDebtAmount={loan.currentDebtAmount}
                status={loan.status as LoanStatusEnum}
                onShowExtract={onShowExtract}
              />
              <Line />
            </>
          )}
          <TaxDetails
            creditAmount={loan.creditAmount}
            interestRate={loan.interestRate}
            operationFeeAmount={loan.operationFeeAmount}
            taxAmount={loan.taxAmount}
            currentDebtAmount={loan.currentDebtAmount}
            totalPaidAmount={loan.totalPaidAmount}
            status={status}
          />
          <Line />
          <ContractDetails
            document={loan.borrower.document}
            dueDate={loan.dueDate}
            contractUri={loan.contractUri}
          />
          <Line />
          <PaymentDetails retentionRate={loan.retentionRate} />
          <Line />
          <BankLockDetails onKnowMore={onKnowMore} />
        </SCard>
      </Content>

      {!hideBannerIfStatus.includes(status) && (
        <Content md={3}>
          <BannerExtract onClick={onShowExtract} />
        </Content>
      )}
    </Wrapper>
  )
}
