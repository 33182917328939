import styled from 'styled-components'
import Text from 'Components/Text'
import { Colors } from 'Styles/theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${Colors.gray5};

  .loan_management--content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1200px;
    width: 100%;
  }

  div.content {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 20px 120px;

    &__center {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 1200px;
    }
  }

  div.area-choose-doc {
    width: 452px;
    height: fit-content;
    margin-bottom: 20px;
  }
`

export const Label = styled(Text)`
  font-size: 16px;
  margin-bottom: 6px;
  font-weight: bold;
  font-size: 16px;
  font-family: 'Open Sans', 'Helvetica Neue', 'Helvetica', 'Roboto', 'Arial',
    sans-serif;
`
