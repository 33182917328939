import firebase from 'firebase'
import axios from 'axios'
import { IFirebaseFaqLock } from 'Interfaces/firebaseFaqLock.interface'
import { firebaseConfig } from '../Config'

firebase.initializeApp(firebaseConfig)

export const remoteConfig = firebase.remoteConfig()

const ONE_MINUTE_IN_MILLISECONDS = 60 * 1000

remoteConfig.settings = {
  minimumFetchIntervalMillis: ONE_MINUTE_IN_MILLISECONDS,
  fetchTimeoutMillis: ONE_MINUTE_IN_MILLISECONDS,
}

export enum EVENTS {
  CREATE_CREDIT_ALERT = 'criou_alerta_de_credito',
}

export enum FIREBASE_LOCALSTORAGE {
  ALERT = '@creditportal/ALERT_',
}

export function createLocalStorageKey(
  email: string,
  stoneCode: string,
  document: string
) {
  return `${FIREBASE_LOCALSTORAGE.ALERT}${email}_${stoneCode}_${document}`
}

export function createCreditAlert(
  document: string,
  stoneCode: string,
  email: string
): void {
  if (!document || !stoneCode || !email) {
    throw new Error('Miss value of params')
  }

  const params = {
    document,
    stoneCode,
    email,
  }

  firebase.analytics().logEvent(EVENTS.CREATE_CREDIT_ALERT, params)

  const key = createLocalStorageKey(email, stoneCode, document)

  // save on localstorage
  window.localStorage.setItem(key, JSON.stringify(params))
}

export function hasAlertCreated(
  document: string,
  stoneCode: string,
  email: string
): boolean {
  const key = createLocalStorageKey(email, stoneCode, document)
  return !!window.localStorage.getItem(key)
}

export async function fetchCreditBankLockFaq(): Promise<IFirebaseFaqLock> {
  const response = await axios.get<IFirebaseFaqLock>(
    `${firebaseConfig.authDomain}/credit_bank_lock_faq.json`
  )
  return response.data
}

export default firebase
