import React from 'react'
import LayoutState from 'Components/LayoutState/Internals/BaseState'
import ErrorCircle from 'Assets/error-circle.svg'
import { Button } from 'Components/Button'

interface Props {
  onClickUpdateButton(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void
}

export default function ErrorState({ onClickUpdateButton }: Props) {
  return (
    <LayoutState
      icon={ErrorCircle}
      title="Ops! Tivemos um problema :("
      subtitle="Clique no botão abaixo para atualizar a página e tentar novamente."
      button={<Button onClick={onClickUpdateButton}>Atualizar</Button>}
    />
  )
}
