import React, { useCallback, useState } from 'react'

import { IProposal } from 'Interfaces'
import Loader from 'Components/Loader'
import { ITabs, Panel, Tabs } from 'Components/Tabs'
import { TabThemes } from 'Components/Tabs/styles'
import FaqLockContent from 'BusinessComponent/FaqLockContent'
import { IFirebaseFaqLock } from 'Interfaces/firebaseFaqLock.interface'
import BankInfo from './BankInfo'
import CostInfo from './CostInfo'
import { Body, Header, SModal, Title } from './styles'
import WarningNewStone from './WarningNewStone'

export interface Props {
  isLoading?: boolean
  proposal: IProposal | null
  show: boolean
  faqItems: IFirebaseFaqLock
  handleConfirm(): void
  handleClose(value: boolean): void
}

enum TabSelected {
  DETAIL,
  FAQ_LOCK,
}

const tabs: ITabs[] = [
  {
    id: 'details',
    name: 'Detalhes',
    dataTestId: 'loanContractDetail-details',
  },
  {
    id: 'faq-lock',
    name: 'Trava bancária',
    dataTestId: 'loanContractDetail-faqLock',
  },
]

export default function LoanContractDetails({
  isLoading,
  proposal,
  show,
  handleConfirm,
  handleClose,
  faqItems,
}: Props) {
  const [userConfirmed, setUserConfirmed] = useState<boolean>(false)
  const [tabSelected, setTabSelected] = useState<number>(0)

  const handleModalClose = useCallback(() => {
    if (tabSelected !== TabSelected.FAQ_LOCK) {
      handleClose(true)
      return
    }

    setTabSelected(TabSelected.DETAIL)
  }, [tabSelected, handleClose])

  return (
    <SModal
      show={show}
      onHide={handleModalClose}
      size="lg"
      data-testid="contract-details"
    >
      <Header closeButton>
        <Title>Simulação do empréstimo</Title>
      </Header>

      <div className="area-tabs">
        <Tabs
          tabs={tabs}
          theme={TabThemes.CENTER_SMALL}
          selected={tabSelected}
          onSelect={setTabSelected}
        />
      </div>

      <Panel tabSelected={tabSelected}>
        <Body>
          {isLoading && <Loader spinner />}
          {proposal && (
            <>
              <CostInfo
                loanValue={proposal.creditAmount}
                hiringFee={proposal.operationFeeAmount}
                estimatedIOF={proposal.taxAmount}
                interestRate={proposal.interestRate}
                totalEffectiveCostPerMonth={
                  proposal.monthlyTotalEffectiveCostRate
                }
                totalEffectiveCostPerYear={
                  proposal.annualTotalEffectiveCostRate
                }
              />
              <BankInfo
                onKnowMore={() => setTabSelected(TabSelected.FAQ_LOCK)}
              />

              <WarningNewStone />
            </>
          )}
        </Body>

        <FaqLockContent items={faqItems} />
      </Panel>
    </SModal>
  )
}
