/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { appConfig } from '../../../../../Config'

export default function Initial() {
  return (
    <li className="menu__item">
      <a className="menu__handle" href={appConfig.portalURL}>
        Início
      </a>
    </li>
  )
}
