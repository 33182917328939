import styled from 'styled-components'
import { Button } from 'Components/Button'

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
  flex: 1;
`

export const CancelButton = styled(Button)`
  margin-right: 19px;
`
