import { chatConfig } from 'Config'

const initialize = () => {
  window.callZenDeskChat(chatConfig.token)
}

const configure = (name: string, email: string) => {
  window.$zopim(() => {
    window.$zopim.livechat.set({ name, email })
    const { stoneId, antecipacaoId, digitalId, emprestimoId } = chatConfig
    window.$zopim.livechat.departments.filter(
      Number(stoneId),
      Number(digitalId),
      Number(antecipacaoId),
      Number(emprestimoId)
    )
  })
}

export default { initialize, configure }
