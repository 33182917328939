/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { checkPermission, hasStoneCode } from 'Services/base.auth'
import { appConfig } from 'Config'
import Anticipation from '../Anticipation'
import Loan from '../Loan'

const baseURL = appConfig.portalURL
const financialURL = `${baseURL}/financeiro`

export default function Financial() {
  if (hasStoneCode() && checkPermission('REQUEST_CREDIT')()) {
    const isDropDownType = true

    return (
      <li className="menu__item">
        <a className="menu__handle menu__handle--controls" href={financialURL}>
          Financeiro
        </a>
        <div className="menu__area">
          <div className="menu__target menu__target--sub">
            <div className="menu__area">
              <ul className="menu__list">
                <Anticipation isDropDownType={isDropDownType} />
                <Loan />
              </ul>
            </div>
          </div>
        </div>
      </li>
    )
  }
  return <></>
}
