import React from 'react'
import { StyleText } from './styles'
import { SIZES } from './index.interface'

export interface Props extends React.HTMLAttributes<HTMLParagraphElement> {
  children?: string | number | JSX.Element
  size?: SIZES
  bold?: boolean
}

export default function Text({ children, size, bold, ...rest }: Props) {
  return (
    <StyleText data-bold={bold} data-size={size} {...rest}>
      {children}
    </StyleText>
  )
}
