import ReactSelect from 'react-select'
import styled from 'styled-components'

import { Colors } from 'Styles/theme'

interface SelectProps {
  height?: string
}

export const Select = styled<typeof ReactSelect>(ReactSelect)<SelectProps>`
  .select__control {
    font-family: CircularStd;
    font-size: 14px;
    color: ${Colors.darkblue};
    width: 100%;
    height: ${props => props.height || '58px'};
    box-sizing: border-box;
    border: 1px solid ${Colors.gray3};
    border-radius: 4px;
    padding-left: 10px;
  }

  .select__control:hover {
    border: 1px solid ${Colors.gray3};
  }

  .select__control--is-focused {
    box-shadow: none;
    outline: 2px solid ${Colors.gray3};
  }

  .select__indicator-separator {
    display: none;
  }

  .select__indicator {
    margin-right: 7px;
  }

  .select__menu {
    color: #3c3d3e;
  }
`
