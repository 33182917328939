import React, { useState } from 'react'

import { number2price } from 'Utils/money'
import InputMoney from 'Components/CurrencyInput'
import { IErrorValidation, Status } from 'Components/CurrencyInput/shared'
import { ICreditLines } from 'Interfaces'
import { Button, CreditProposal, MoneyValue } from './styles'

interface Props {
  creditLine: ICreditLines
  minValue: number
  onSimulate(value: number): void
}

export default function Form({ creditLine, minValue, onSimulate }: Props) {
  const [validationStatus, setValidationStatus] = useState<IErrorValidation>({
    type: Status.PENDING,
    message: '',
  })
  const [value, setValue] = useState<number>(0)
  function handleSimulate() {
    switch (validationStatus.type) {
      case Status.PENDING:
        setValidationStatus({
          type: Status.ERROR,
          message: 'Campo obrigatório',
        })
        break
      case Status.SUCCESS:
        onSimulate(value)
        break
      default:
        break
    }
  }
  return (
    <>
      <CreditProposal>
        <p>
          Limite disponível para empréstimo:
          <MoneyValue data-testid="available-credit-limit">
            {' '}
            {number2price(creditLine?.availableCreditLimit)}
          </MoneyValue>
        </p>
        <InputMoney
          minValue={minValue}
          maxValue={creditLine?.availableCreditLimit}
          minValueErrorMessage={`O valor deve ser maior que ${number2price(
            minValue
          )}`}
          placeholder="Valor desejado"
          onChange={setValue}
          handleError={setValidationStatus}
          customValidationError={validationStatus}
          overrideWithMaxValue
        />
      </CreditProposal>
      <Button data-testid="button-simulate" onClick={handleSimulate}>
        Simular
      </Button>
    </>
  )
}
