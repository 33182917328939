import React from 'react'
import {
  checkPermission,
  hasStoneCode,
} from '../../../../../Services/base.auth'
import { appConfig } from '../../../../../Config'

interface DropDownType {
  isDropDownType: boolean
}
export default function Anticipation({ isDropDownType }: DropDownType) {
  const resultIsEnabled = isDropDownType || !checkPermission('REQUEST_CREDIT')()
  const isVisible =
    hasStoneCode() && checkPermission('PREPAY_PAYMENT')() && resultIsEnabled
  if (isVisible) {
    const anticipationURL = `${appConfig.portalURL}/rav/pontual`

    return (
      <li className="menu__item">
        <a className="menu__handle" href={anticipationURL}>
          Antecipação
        </a>
      </li>
    )
  }
  return <></>
}
