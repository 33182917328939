import React, { useContext, useEffect, useState } from 'react'

import SimulationCard from 'BusinessComponent/SimulationCard'

import { createCreditAlert, hasAlertCreated } from 'Services/firebase'
import { getStoneCodeByDocumentFrom } from 'Utils/stonecodes'
import { ICurrentUser } from 'Interfaces/user.interface'
import { ICreditLines, IProposal } from 'Interfaces'

import Card from 'Components/Card'
import Loader from 'Components/Loader'
import UserContext from 'Contexts/UserContext'
import LoanNotAllowed from 'Components/LayoutState/Internals/LoanNotAllowed'
import AlertCreated from 'Components/LayoutState/Internals/AlertCreated'
import useProposal from 'Hooks/useProposal'
import { ErrorMapperEnum } from 'Enums/proposal'
import StoneCodesContext from 'Contexts/StoneCodesContext'
import useRemoteConfig from 'Hooks/useRemoteConfig'
import {
  MIN_LOAN_SIMULATION_VALUE,
  MIN_LOAN_SIMULATION_VALUE_DEFAULT_VALUE,
} from 'Constants'

import CpfConfirm from '../../CpfConfirm'

interface Props {
  loading: boolean
  creditLine: ICreditLines
  currentDocument: string
  onSimulate(proposal: IProposal | null): void
  firebaseCreateCreditAlert?(
    document: string,
    stoneCode: string,
    email: string
  ): void
  firebaseHasAlertCreated?(
    document: string,
    stoneCode: string,
    email: string
  ): boolean
  proposalHook?(
    document: string,
    creditAmount: number,
    creditLineId: string
  ): [IProposal | null, boolean, ErrorMapperEnum]
}

export default function CardWithAlert({
  loading,
  creditLine,
  currentDocument,
  onSimulate,
  firebaseCreateCreditAlert = createCreditAlert,
  firebaseHasAlertCreated = hasAlertCreated,
  proposalHook = useProposal,
}: Props) {
  const [alertCreated, setAlertCreated] = useState(false)
  const [showCpfConfirm, setShowCpfConfirm] = useState(false)

  const user = useContext<ICurrentUser | null>(UserContext)

  const {
    isLoading: isLoadingConfigs,
    value: minSimulationValue,
  } = useRemoteConfig({
    configName: MIN_LOAN_SIMULATION_VALUE,
    defaultValue: MIN_LOAN_SIMULATION_VALUE_DEFAULT_VALUE,
  })

  const minSimulationAmount = Number(minSimulationValue) / 100

  const [stoneCodes] = useContext(StoneCodesContext)

  function onCreateCreditAlert() {
    if (!user) return

    const stoneCode = getStoneCodeByDocumentFrom(stoneCodes, currentDocument)
    const document = currentDocument
    const { email } = user
    try {
      firebaseCreateCreditAlert(document, stoneCode, email)
      setAlertCreated(true)
    } catch (e) {
      setAlertCreated(false)
    }
  }

  useEffect(() => {
    const stoneCode = getStoneCodeByDocumentFrom(stoneCodes, currentDocument)
    if (user) {
      const alreadyHasAlertCreated = firebaseHasAlertCreated(
        currentDocument,
        stoneCode,
        user?.email
      )
      setAlertCreated(alreadyHasAlertCreated)
    }
  }, [currentDocument, user, stoneCodes, firebaseHasAlertCreated])

  if (loading || isLoadingConfigs) {
    return (
      <Card style={{ display: 'flex', justifyContent: 'center' }}>
        <Loader spinner />
      </Card>
    )
  }

  if (!loading && !creditLine?.availableCreditLimit) {
    if (alertCreated) {
      return <AlertCreated />
    }

    return (
      <>
        <CpfConfirm
          show={showCpfConfirm}
          onSuccess={onCreateCreditAlert}
          onCancel={() => setShowCpfConfirm(false)}
        />
        <LoanNotAllowed onCreateAlert={() => setShowCpfConfirm(true)} />
      </>
    )
  }

  return (
    <>
      {creditLine && (
        <SimulationCard
          creditLine={creditLine}
          minValue={minSimulationAmount}
          document={currentDocument}
          onSimulate={onSimulate}
          proposalHook={proposalHook}
        />
      )}
    </>
  )
}
