import React, { useEffect, useState } from 'react'

import Card from 'Components/Card'
import DoProposal from 'BusinessComponent/SimulationCard/DoProposal'
import { ICreditLines, IProposal } from 'Interfaces'
import { ErrorMapperEnum } from 'Enums/proposal'
import { useLocation } from 'react-router-dom'
import useProposal from 'Hooks/useProposal'
import { LoanSimulationQueryStrings } from 'Enums'
import { Wrapper } from './styles'
import Header from './Header'
import Form from './Form'
import Footer from './Footer'

interface Props {
  minValue: number
  creditLine: ICreditLines
  document: string
  onSimulate(proposal: IProposal | null): void
  proposalHook?(
    document: string,
    creditAmount: number,
    creditLineId: string
  ): [IProposal | null, boolean, ErrorMapperEnum]
}

export default function SimulationCard({
  minValue,
  creditLine,
  document,
  onSimulate,
  proposalHook = useProposal,
}: Props) {
  const [proposalValue, setProposalValue] = useState<number>(0)

  const { search } = useLocation()

  useEffect(() => {
    const query = new URLSearchParams(search)
    const currentAmount = query.get(LoanSimulationQueryStrings.AMOUNT)
    if (currentAmount) {
      onProposal(Number(currentAmount))
    }
  }, [search])

  function onProposal(value: number) {
    setProposalValue(value)
  }

  function handleProposal(proposal: IProposal | null) {
    if (proposal) {
      onSimulate(proposal)
    }

    setProposalValue(0)
  }

  return (
    <Wrapper>
      {!proposalValue && (
        <Card>
          <Header />
          <Form
            creditLine={creditLine}
            onSimulate={onProposal}
            minValue={minValue}
          />
          <Footer />
        </Card>
      )}

      {Boolean(proposalValue) && (
        <DoProposal
          document={document}
          amount={proposalValue}
          creditLineId={creditLine?.id}
          useProposal={proposalHook}
          canDoProposal={handleProposal}
        />
      )}
    </Wrapper>
  )
}
