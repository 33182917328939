import Cookie from 'js-cookie'
import { appConfig } from 'Config'

export interface ILogoutProps {
  location?: Location
}

export async function logout({
  location = window.location,
}: ILogoutProps = {}) {
  Cookie.remove('external_token', {
    path: '/',
    domain: `.${appConfig.portalURL}`,
  })

  const urlSearch = encodeURIComponent(location.search)
  let logoutUrl = `${appConfig.portalURL}/logout`

  if (urlSearch) {
    logoutUrl = `${logoutUrl}?redirect=emprestimo${urlSearch}`
  }

  location.assign(logoutUrl)
}
