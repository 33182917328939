import styled from 'styled-components'
import { Colors } from 'Styles/theme'

interface Props {
  backgroundColor?: string
  percentage: number
}

export const Container = styled.div`
  display: block;
  width: 100%;
  pointer-events: none;
`

export const BarBackgorund = styled.div`
  position: relative;
  width: 100%;
  border-radius: 2px;
  background-color: ${Colors.gray};
`

export const Bar = styled.div<Props>`
  display: block;
  box-sizing: border-box;
  border-radius: 2px;
  width: ${props => `${props.percentage}%`};
  max-width: 100%;
  height: 10px;
  background-color: ${props => props.backgroundColor ?? Colors.stonegreen1};
`
export const Description = styled.p`
  font-family: 'CircularStd';
  font-size: 12px;
  color: ${Colors.gray3};
  text-align: center;
  margin-top: 5px;
  margin-bottom: 0;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  white-space: normal;

  span {
    color: ${Colors.stonegreen1};
    font-weight: bold;
  }
`
