import React from 'react'
import Initial from '../Initial'
import Payments from '../Payments'
import Anticipation from '../Anticipation'
import Services from '../Services'
import Search from '../Search'
import Sales from '../Sales'
import Financial from '../Financial'
import Gps from '../Gps'
import Attendant from '../Attendant'
import Banking from '../Banking'

export default function NavBar() {
  const isDropDownType = false
  return (
    <div className="menu__area menu__area--nav">
      <div className="menu__target menu__target--top">
        <div className="menu__area menu__area--primary">
          <ul className="menu__list">
            <Attendant />
            <Initial />
            <Sales />
            <Banking />
            <Payments />
            <Financial />
            <Gps />
            <Anticipation isDropDownType={isDropDownType} />
            <Services />
          </ul>
        </div>
        <Search />
      </div>
    </div>
  )
}
