import React from 'react'
import { Alert } from 'Assets'

import { Highlight, SecondaryText, Text, Title, Wrapper } from './styles'

export default function LastAttemptAlert() {
  return (
    <Wrapper>
      <img src={Alert} alt="Ícone de alerta" />
      <Title>Atenção!</Title>
      <Text>
        Você tem <Highlight>apenas mais uma tentativa</Highlight>
      </Text>
      <SecondaryText>Deseja continuar?</SecondaryText>
    </Wrapper>
  )
}
