import { AxiosRequestConfig } from 'axios'
import { ICreditLines } from 'Interfaces'
import { customerApi } from 'Services/base.auth'

export interface IGetCreditLinesProps {
  axiosConfig?: AxiosRequestConfig
  stoneCode: string
}

export default async function getCreditLines(
  props: IGetCreditLinesProps
): Promise<ICreditLines[]> {
  const axiosConfig = props && props.axiosConfig
  const res = await customerApi.get(
    `/v0/credit/${props.stoneCode}/credit-lines?status=Active`,
    {
      ...axiosConfig,
    }
  )
  const { data }: { data: ICreditLines[] } = res.data

  return data
}
