import styled from 'styled-components'
import { Row } from 'react-bootstrap'

export const Container = styled(Row)`
  margin-top: 24px;
`

export const InputLabel = styled.span`
  display: block;
  margin-bottom: 8px;
`
