import React from 'react'
import { ILoan } from '../../../Interfaces'
import { LOAN_STATUS } from '../../../Enums'

interface IResponseManageValues {
  currentValue: number
  description(percentage: number): JSX.Element
}

export function applyRulesForCurrentValue(loan: ILoan): number {
  switch (loan.status) {
    case LOAN_STATUS.ACCEPTED:
    case LOAN_STATUS.DISBURSEMENT_REQUESTED:
    case LOAN_STATUS.DISBURSEMENT_CONFIRMED:
    case LOAN_STATUS.CANCELLED:
    case LOAN_STATUS.SUSPENDED_NON_DISBURSEMENT:
      return 0

    default:
      return loan.totalPaidAmount
  }
}

export function applyRulesForDescription(
  percentage: number,
  loan: ILoan
): JSX.Element {
  switch (loan.status) {
    case LOAN_STATUS.ACCEPTED:
      return <>Seu pedido está sendo analisado</>

    case LOAN_STATUS.DISBURSEMENT_REQUESTED:
      return <>Seu pedido está sendo analisado</>

    case LOAN_STATUS.DISBURSEMENT_CONFIRMED:
      return <>Seu pedido está sendo analisado</>

    case LOAN_STATUS.ACTIVE:
    case LOAN_STATUS.SUSPENDED_DISBURSEMENT:
      return (
        <>
          Você já quitou <span>{percentage}%</span> do seu empréstimo
        </>
      )

    case LOAN_STATUS.SUSPENDED_NON_DISBURSEMENT:
      return <>Seu pedido está sendo analisado</>

    case LOAN_STATUS.SETTLED:
      return <>Você quitou este empréstimo!</>

    case LOAN_STATUS.CANCELLED:
      return <>Houve um problema com a trava bancária</>

    case LOAN_STATUS.INACTIVE:
      return (
        <>
          <span>{percentage}%</span> do seu empréstimo foi quitado
        </>
      )

    default:
      return <></>
  }
}

export function manageValues(loan: ILoan): IResponseManageValues {
  return {
    currentValue: applyRulesForCurrentValue(loan),
    description(percentage: number) {
      return applyRulesForDescription(percentage, loan)
    },
  }
}
