import styled from 'styled-components'
import { Button } from 'Components/Button'
import { Colors } from 'Styles/theme'

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
  flex: 1;
`

export const ConfirmButton = styled(Button)`
  background-color: ${({ disabled }) =>
    disabled ? Colors.lightblue : Colors.darkgreen};

  &:hover {
    background-color: ${({ disabled }) =>
      disabled ? Colors.lightblue : Colors.darkgreen};
  }
`

export const CancelButton = styled(Button)`
  margin-right: 19px;
`
