import { createContext } from 'react'
import { IStoneCode } from 'Interfaces'

const StoneCodesContext = createContext<[IStoneCode[], boolean, boolean]>([
  [],
  false,
  false,
])

export default StoneCodesContext
