import React, { useContext, useEffect, useState } from 'react'
import CpfConfirmModal from 'BusinessComponent/CpfConfirmModal'
import useIdentityVerify from 'Hooks/useIdentityVerify'
import UserContext from 'Contexts/UserContext'
import { ICurrentUser } from 'Interfaces/user.interface'

interface ICpfConfirm {
  show: boolean
  subtitle?: string
  onSuccess(): void
  onCancel(): void
}
export default function CpfConfirm({
  show,
  subtitle,
  onSuccess,
  onCancel,
}: ICpfConfirm) {
  const [showModal, setShowModal] = useState<boolean>(true)

  const { verify, isLoading } = useIdentityVerify()
  const user = useContext<ICurrentUser | null>(UserContext)

  useEffect(() => {
    setShowModal(show)
  }, [show])

  async function handleConfirm(document: string) {
    await verify({ email: user?.email || '', document })
    onSuccess()
  }

  return (
    <CpfConfirmModal
      isLoading={isLoading}
      show={showModal}
      subtitle={subtitle}
      onConfirm={handleConfirm}
      onCancel={() => {
        setShowModal(false)
        onCancel()
      }}
    />
  )
}
