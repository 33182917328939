/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react'
import { appConfig } from 'Config'
import Avatar from 'Assets/avatar.svg'
import { fetchCompanyInfo } from 'Services/base.auth'
import { logout } from 'Helper/logout'
import UserContext from 'Contexts/UserContext'

import { identifyUser } from 'Services/appcues'

const baseURL = appConfig.portalURL
const registrationUrl = `${baseURL}/configuracoes/dados-cadastrais`
const contractURL = `${baseURL}/contratos`

export default function Profile() {
  const [user, setUser] = useState({ fantasyName: '', stoneCode: '' })
  const [showProfileMenu, setShowProfileMenu] = useState<Boolean>(false)

  const userCtx = useContext(UserContext)

  useEffect(() => {
    if (user && userCtx) {
      const fullName = `${userCtx.firstName} ${userCtx.lastName}`
      identifyUser(String(userCtx.id), {
        name: fullName,
        email: userCtx.email,
        stoneCode: user.stoneCode,
        company: user.fantasyName,
      })
    }
  }, [user, userCtx])

  useEffect(() => {
    async function getUser() {
      try {
        const response = await fetchCompanyInfo()
        const body = await response.json()

        setUser({ fantasyName: body.fantasy_name, stoneCode: body.stone_code })
      } catch (error) {
        // @todo we need treat better this error
        setUser({
          fantasyName: '',
          stoneCode: '',
        })
      }
    }

    getUser()
  }, [])

  function handleProfileClick() {
    setShowProfileMenu(!showProfileMenu)
  }

  return (
    <div className="menu__area menu__area--user">
      <div className="menu__target menu__target--right">
        <div className="menu__area">
          <ul className="menu__list">
            <li
              className={`menu__item menu__item--user ${
                showProfileMenu ? 'menu__item--active' : ''
              }`}
            >
              <a className="menu__handle menu__handle--user menu__handle--for-in-desktop">
                <span className="menu__user">{user.fantasyName}</span>
                <span className="menu__company">{user.stoneCode}</span>
              </a>
              <a
                className="menu__handle menu__handle--user menu__handle--for-in-mobile"
                onClick={handleProfileClick}
              >
                <span className="menu__picture">
                  <img
                    className="menu__media"
                    alt="Usuário sem foto"
                    src={Avatar}
                  />
                </span>
              </a>
              <div className="menu__area">
                <div className="menu__target menu__target--sub menu__target--user">
                  <div className="menu__area">
                    <div className="menu__list menu__list--company">
                      <span className="menu__user">{user.fantasyName}</span>
                      <span className="menu__company">{user.stoneCode}</span>
                    </div>
                    <ul className="menu__list">
                      <li className="menu__item menu__item--config">
                        <a
                          className="menu__handle menu__handle--config"
                          href={registrationUrl}
                        >
                          Meu cadastro
                        </a>
                      </li>
                      <li className="menu__item menu__item--config">
                        <a
                          className="menu__handle menu__handle--config"
                          href={contractURL}
                        >
                          Meu plano
                        </a>
                      </li>
                      <li className="menu__item menu__item--help">
                        <a
                          className="menu__handle menu__handle--help"
                          href="http://stone.com.br/ajuda"
                        >
                          Central de ajuda
                        </a>
                      </li>
                      <li className="menu__item menu__item--logoff">
                        <a
                          className="menu__handle menu__handle--logoff"
                          onClick={() => logout()}
                        >
                          Sair
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
