import React from 'react'
import { TimerBallon } from 'Assets'
import { LayoutState } from 'Components/LayoutState'
import { Button } from 'Components/Button'

interface Props {
  onBack(): void
}

const AlreadyHasActiveLoan = ({ onBack }: Props): JSX.Element => {
  return (
    <LayoutState
      icon={TimerBallon}
      title="No momento, o Empréstimo Stone não está liberado para esse CNPJ"
      subtitle="Para contratar novamente, você deve quitar o empréstimo em andamento"
      button={<Button onClick={onBack}>Voltar ao início</Button>}
    />
  )
}

export default AlreadyHasActiveLoan
