import React, { useContext, useEffect } from 'react'
import { IndicatorProps, ValueType, components } from 'react-select'
import { ArrowBottom } from 'Assets'
import { StoreContext } from 'Contexts/LoanDetailsContext'
import { Select } from './styles'

export type Option = { label: string; value: string }
interface DocumentSelectProps {
  options: Option[]
  onChange(value: string): void
  currentValue?: string | null
  height?: string
}

const DropdownIndicator = (props: IndicatorProps<Option>) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={ArrowBottom} alt="indicador" />
    </components.DropdownIndicator>
  )
}

function getCurrentOption(options: Option[], value?: string | null): Option {
  const [firstOption] = options

  if (value) {
    const currentOption = options.find(option => option.value === value)
    return currentOption || firstOption
  }

  return firstOption
}

export default function DocumentSelect({
  options,
  onChange,
  currentValue,
  ...props
}: DocumentSelectProps) {
  const loanDetailsContext = useContext(StoreContext)

  function syncCurrentOption() {
    onChange(getCurrentOption(options, currentValue).value)
  }

  useEffect(syncCurrentOption, [])

  function handleChange(option: ValueType<Option>) {
    const { value } = option as Option
    onChange(value)
    loanDetailsContext?.handleDocumentChange()
  }

  return (
    <Select
      classNamePrefix="select"
      options={options}
      onChange={handleChange}
      defaultValue={getCurrentOption(options, currentValue)}
      components={{ DropdownIndicator }}
      {...props}
    />
  )
}
