import React from 'react'

import { Container } from './styles'

interface OutlineButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  icon?: string
  iconAlt?: string
}

const OutlineButton: React.FC<OutlineButtonProps> = ({
  children,
  icon,
  iconAlt,
  className,
  ...rest
}) => {
  return (
    <Container className={className} {...rest}>
      {icon && <img className="icon" src={icon} alt={iconAlt} />}
      <span>{children}</span>
    </Container>
  )
}

export default OutlineButton
