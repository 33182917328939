import styled from 'styled-components'
import { Colors } from 'Styles/theme'

export const Wrapper = styled.section`
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  padding: 1.8em 1em;
  margin: 0 auto;

  @media screen and (max-width: 992px) {
    flex-direction: column;
  }
  @media screen and (max-width: 770px) {
    margin-bottom: 3em;
  }
`

export const LinkSection = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 992px) {
    justify-content: center;
    margin-bottom: 2em;
  }
`

export const Link = styled.a`
  display: flex;
  position: relative;
  font-size: 0.9em;
  align-items: center;
  margin-right: 36px;
  color: ${Colors.darkazureishgray};

  img {
    margin-right: 0.5em;
    width: 20px;
  }

  &:after {
    content: '';
    transition: all 0.1s ease-in;
    position: absolute;
    width: calc(100% + 10px);
    height: 2px;
    background: transparent;
    left: -2.5px;
    bottom: -5px;
  }

  &:hover {
    &:after {
      content: '';
      background: ${Colors.stonegreen2};
      bottom: -12.5px;
    }
  }

  &:first-child {
    img {
      width: 16px;
    }
  }

  &:last-child {
    margin-right: 0;
  }
`

export const PhoneSection = styled.div`
  font-family: 'Roboto';
  font-size: 0.8em;

  margin-left: 35px;

  @media screen and (max-width: 430px) {
    text-align: center;
  }

  @media screen and (max-width: 630px) {
    flex: 1 1 0%;
    margin: 0px;
  }
`

export const MediaSection = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;

  @media screen and (max-width: 992px) div:first-child {
    margin-left: 0px;
  }

  h4 {
    margin: 0;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: ${Colors.darkblue};
  }

  span {
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: ${Colors.darkazureishgray};
  }

  small {
    font-size: 11.5px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: ${Colors.gray3};
    display: block;
  }

  @media screen and (max-width: 430px) {
    flex-wrap: wrap;
  }

  @media screen and (max-width: 992px) {
    justify-content: center;
  }
`

export const Apps = styled.div`
  margin-left: 35px;

  a > img {
    width: 110px;
  }

  a:last-child img {
    width: 120px;
    margin-left: 15px;
  }

  @media screen and (max-width: 430px) {
    margin-top: 1.8em;
    flex: initial;
  }

  @media screen and (max-width: 630px) {
    margin-left: 0px;
    a:last-child img {
      width: 120px;
      margin-left: 0px;
    }
  }
`
