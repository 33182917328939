import styled from 'styled-components'
import Text from 'Components/Text'
import { Colors } from 'Styles/theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

export const Title = styled(Text)`
  padding: 0 16px;
  font-family: CircularStd;
  font-weight: 600;
  line-height: 30px;
  color: ${Colors.black};
`

export const SkeletonWrapper = styled.div`
  width: 100%;
`
export const Row = styled.div`
  display: flex;
  height: 75px;

  &:not(:last-of-type) {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: ${Colors.lightazureishgray};
  }
`

export const Cell = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  line-height: normal;
  padding-left: 16px;
  padding-right: 16px;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  max-width: 100%;
  min-width: 100px;
`

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 35px;
`

export const AreaImage = styled.div`
  width: 130px;
  white-space: normal;
  pointer-events: none;
`
