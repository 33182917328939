import React from 'react'

import { Container, PriceText } from './styles'

export interface Props {
  label: string
  text: string
  color?: string
}

const TextLabel: React.FC<Props> = ({ label, text, color }) => {
  return (
    <Container>
      <span className="label">{label}</span>
      <PriceText color={color}>{text}</PriceText>
    </Container>
  )
}

export default TextLabel
