import React from 'react'
import { LayoutState } from 'Components/LayoutState'
import { Warning } from 'Assets'
import { Button } from 'Components/Button'

interface Props {
  onRetry(): void
  noCard?: boolean
}

const GeneralError = ({ onRetry, noCard = false }: Props): JSX.Element => {
  return (
    <LayoutState
      noCard={noCard}
      icon={Warning}
      title="Ops! Tivemos um problema"
      subtitle="Clique no botão abaixo para atualizar a página e tentar novamente"
      button={<Button onClick={onRetry}>Tentar novamente</Button>}
    />
  )
}

export default GeneralError
