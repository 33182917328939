import React, { useContext } from 'react'

import {
  MobileAccessContext,
  MobileAccessContextStore,
} from 'Contexts/MobileAccessContext'

import { AppleStore, CloseBlack, MobileAccessWarning, PlayStore } from 'Assets'
import { Container } from './styles'

export interface Props {
  icon?: string
  iconAlt?: string
  title?: string
  description?: string
}

const MobileAccessWarningModal: React.FC<Props> = ({
  icon = MobileAccessWarning,
  iconAlt = 'imagem telefone com homem caminhando',
  title = 'Tenha o Portal Stone nas suas mãos',
  description = 'Faça o download do app da Stone na AppStore ou pelo Google Play.',
}) => {
  const { show, setShow } = useContext<MobileAccessContextStore>(
    MobileAccessContext
  )

  return (
    <Container
      show={show}
      onHide={() => setShow(false)}
      data-testid="mobileaccesswarning-modal"
      centered
    >
      <div className="area-close">
        <img
          onClick={() => setShow(false)}
          role="presentation"
          src={CloseBlack}
          alt="Icone de fechar"
        />
      </div>

      <img className="principal-icon" src={icon} alt={iconAlt} />
      <h1 data-testid="mobileaccesswarning-title">{title}</h1>
      <p data-testid="mobileaccesswarning-description">{description}</p>
    </Container>
  )
}

export default MobileAccessWarningModal
