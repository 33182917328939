import React from 'react'
import ReactDataTable, { IDataTableProps } from 'react-data-table-component'

import './Theme'

import { stoneCustomStyle } from './CustomStyles'
import Pagination from './CustomComponents/Pagination'

export default function DataTable<T>({
  columns,
  data,
  ...rest
}: IDataTableProps<T>) {
  return (
    <ReactDataTable
      customStyles={stoneCustomStyle}
      columns={columns}
      data={data}
      theme="stone"
      paginationComponent={Pagination}
      {...rest}
    />
  )
}
