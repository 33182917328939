import styled from 'styled-components'
import { Colors } from 'Styles/theme'

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const Label = styled.label`
  line-height: 18px;
  color: ${Colors.gray3};
  font-size: 14px;
  font-weight: 450;
`
