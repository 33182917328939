import React from 'react'

import { Container, Spinner } from './styles'

interface Props {
  spinner?: boolean
}

export default function Loader({ spinner }: Props) {
  if (spinner) {
    return <Spinner />
  }

  return (
    <Container>
      <div className="emd-loader__inner" />
    </Container>
  )
}
