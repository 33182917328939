import React from 'react'

import HasNotPermission from './internals/HasNotPermission'
import HasPermission from './internals/HasPermission'

interface Props {
  onClickButton?(): void
  hasPermissionToUpdate?: boolean
}

export default function BankSavingsWarning({
  hasPermissionToUpdate,
  onClickButton = () => {},
}: Props): JSX.Element {
  if (!hasPermissionToUpdate) {
    return <HasNotPermission />
  }

  return <HasPermission onUpdateData={onClickButton} />
}
