import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import { ILoan } from 'Interfaces'
import { TabEnum, TabFullScreenEnum } from 'Enums/tab'
import { useHistory, useLocation } from 'react-router-dom'
import { LoanSimulationQueryStrings } from 'Enums'

import TabSelectedContext from 'Contexts/TabSelectedContext'
import LoansContext from 'Contexts/LoansContext'
import { CreditLinesStoreContext } from 'Contexts/CreditLineContext'
import { removeSpecialCharacterFrom } from 'Utils/document'

const FIRST_PAGE = 1
export interface LoanDetailsContext {
  showLoanDetails: boolean
  setShowLoanDetails: Dispatch<SetStateAction<boolean>>
  currentLoan: ILoan | null
  currentDocument: string
  currentPage: number
  currentLoanId: string | null
  handleShowDetails: (loan: ILoan, document: string) => void
  handleClose(): void
  setCurrentLoanId: Dispatch<SetStateAction<string | null>>
  setCurrentPage: Dispatch<SetStateAction<number>>
  handleDocumentChange: () => void
  launchLoanDetails(loanId: string): void
}

export const StoreContext = createContext<LoanDetailsContext | null>(null)

export default ({ children }: { children: ReactNode }) => {
  const [showLoanDetails, setShowLoanDetails] = useState<boolean>(false)
  const [currentLoan, setCurrentLoan] = useState<ILoan | null>(null)
  const [currentLoanId, setCurrentLoanId] = useState<string | null>(null)
  const [currentDocument, setCurrentDocument] = useState<string>('')
  const [currentPage, setCurrentPage] = useState<number>(FIRST_PAGE)

  const { setTabFullScreenSelected, setTabSelected } = useContext(
    TabSelectedContext
  )
  const { loans, getLoans, isLoading } = useContext(LoansContext)
  const { refresh } = useContext(CreditLinesStoreContext)

  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const history = useHistory()

  const handleShowDetails = useCallback(
    (loan: ILoan, document: string) => {
      setShowLoanDetails(true)
      setCurrentLoan(loan)
      setCurrentDocument(document)
      setTabFullScreenSelected(TabFullScreenEnum.DETAIL)
    },
    [setTabFullScreenSelected]
  )

  useEffect(() => {
    if (currentLoanId && loans && !isLoading) {
      const loan = loans.data.find(({ id }) => id === currentLoanId)
      if (loan) {
        handleShowDetails(loan, currentDocument)
      }
    }
  }, [
    currentDocument,
    currentLoanId,
    getLoans,
    isLoading,
    loans,
    handleShowDetails,
  ])

  function handleClose() {
    if (showLoanDetails) {
      setShowLoanDetails(false)
      setCurrentLoanId(null)
      setTabFullScreenSelected(TabFullScreenEnum.NONE)
      query.set(
        LoanSimulationQueryStrings.DOCUMENT,
        removeSpecialCharacterFrom(currentDocument)
      )
      history.push({ search: query.toString() })
    }
  }

  function launchLoanDetails(loanId: string) {
    refresh()
    getLoans()
    setCurrentLoanId(loanId)
    setTabSelected(TabEnum.YOUR_LOANS)
    setTabFullScreenSelected(TabFullScreenEnum.DETAIL)
  }

  function handleDocumentChange() {
    setCurrentPage(FIRST_PAGE)
  }

  const store: LoanDetailsContext = {
    showLoanDetails,
    setShowLoanDetails,
    currentLoan,
    currentDocument,
    currentLoanId,
    currentPage,
    handleShowDetails,
    handleClose,
    setCurrentLoanId,
    setCurrentPage,
    launchLoanDetails,
    handleDocumentChange,
  }

  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
}
