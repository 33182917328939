import { ICurrentUser } from 'Interfaces/user.interface'
import { IStoneCode } from 'Interfaces'
import { getStoneCodeByDocumentFrom } from 'Utils/stonecodes'
import { createCreditAlert } from 'Services/firebase'

export interface ICreateAlertHelperProps {
  user: ICurrentUser
  stoneCodes: IStoneCode[]
  document: string
}

export default function createAlert({
  user,
  stoneCodes,
  document,
}: ICreateAlertHelperProps): boolean {
  const stoneCode = getStoneCodeByDocumentFrom(stoneCodes, document)
  const { email } = user

  try {
    createCreditAlert(document, stoneCode, email)
    return true
  } catch (e) {
    return false
  }
}
