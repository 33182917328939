import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 18px;

  p {
    margin: 0 10px;
    font-weight: 500;
  }
`
