import { token } from '../../Services/base.auth'
import customerAPI, {
  CustomerMethods,
  RepaymentMethods,
} from '../../Services/customer'
import { removeSpecialCharacterFrom } from '../../Utils/document'
import { ILoan, IPagination } from '../../Interfaces'
import { CancellationReasonEnum, LOAN_STATUS } from '../../Enums'

export interface IGetListLoanParameters {
  document: string
  api?: CustomerMethods
}

export function validateStatus(loan: ILoan) {
  const draftLoan = loan
  const isSuspended = draftLoan.status === LOAN_STATUS.SUSPENDED

  if (isSuspended) {
    if (draftLoan.disbursementDate) {
      draftLoan.status = LOAN_STATUS.SUSPENDED_DISBURSEMENT
    } else {
      draftLoan.status = LOAN_STATUS.SUSPENDED_NON_DISBURSEMENT
    }
  }

  return draftLoan
}

export function filterLoan(loan: ILoan) {
  return (
    (loan.status !== LOAN_STATUS.INACTIVE &&
      loan.status !== LOAN_STATUS.CANCELLED) ||
    loan.cancellationReason === CancellationReasonEnum.BANK_LOCK_EJECTED
  )
}

export function checkIfIsSplit(loan: ILoan): boolean {
  const repaymentMethodWithoutSpace = loan.repaymentMethod.replace(' ', '')

  return repaymentMethodWithoutSpace === RepaymentMethods.TRANSACTION_SPLIT
}

export default async function({
  document,
  api = customerAPI(token!),
}: IGetListLoanParameters): Promise<IPagination<ILoan[]>> {
  async function handleLoanItem(loan: ILoan) {
    const draftLoan = loan
    const newDraftWithSplitValidated = await splitAmount(draftLoan)

    Object.assign(draftLoan, newDraftWithSplitValidated)

    const newDraftWithStatusValidated = validateStatus(draftLoan)

    Object.assign(draftLoan, newDraftWithStatusValidated)

    return draftLoan
  }

  async function splitAmount(loan: ILoan): Promise<ILoan> {
    const draftLoan = loan
    const isActiveOrSuspended = [
      LOAN_STATUS.ACTIVE,
      LOAN_STATUS.SUSPENDED,
    ].includes(draftLoan.status)

    if (isActiveOrSuspended && checkIfIsSplit(draftLoan)) {
      const { data: response } = await api.paymentsForecast(
        draftLoan.id,
        cleanDocument
      )
      draftLoan.currentDebtAmount = response.data.projectedDebtAmount
    }

    return draftLoan
  }

  function compareLoans(firstLoan: ILoan, nextLoan: ILoan): number {
    const firstDate = new Date(firstLoan.creationDate).getTime()
    const nextDate = new Date(nextLoan.creationDate).getTime()

    if (firstLoan.status === LOAN_STATUS.SETTLED) return 1
    if (firstDate < nextDate) return 1
    if (firstDate > nextDate) return -1

    return 0
  }

  const cleanDocument = removeSpecialCharacterFrom(document)

  const { data: responseData } = await api.getLoans(cleanDocument)
  const result = await responseData.data
    .filter(filterLoan)
    .map(value => handleLoanItem(value))
  const loans = await Promise.all(result)

  const orderedLoans = loans.sort(compareLoans)

  return { ...responseData, data: orderedLoans }
}
