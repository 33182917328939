import { Info, Success } from 'Assets'
import React, { useEffect, useState } from 'react'
import { number2price, price2number } from 'Utils/money'

import { IErrorValidation, Status } from './shared'
import { IconWrapper, Input, Label, Wrapper } from './styles'

export interface Props {
  minValue?: number
  maxValue?: number
  minValueErrorMessage?: string
  maxValueErrorMessage?: string
  customValidationError?: IErrorValidation
  placeholder?: string
  overrideWithMaxValue?: boolean
  onChange(value: number): void
  handleError?(error: IErrorValidation): void
}

const pendingValidation: IErrorValidation = {
  type: Status.PENDING,
  message: '',
}

export default function CurrencyInput({
  minValue,
  maxValue,
  minValueErrorMessage,
  maxValueErrorMessage,
  onChange,
  handleError,
  customValidationError = pendingValidation,
  overrideWithMaxValue,
  placeholder,
}: Props) {
  const [error, setError] = useState<IErrorValidation>(pendingValidation)
  const [value, setValue] = useState<string>('')
  const [inputFocus, setInputFocus] = useState<boolean>(false)

  useEffect(() => {
    setError(customValidationError)
  }, [customValidationError])

  function getError(currentValue: number): IErrorValidation {
    if (currentValue === 0) {
      return pendingValidation
    }
    if (minValue && currentValue <= minValue) {
      return { type: Status.ERROR, message: minValueErrorMessage }
    }
    if (maxValue && currentValue > maxValue) {
      return { type: Status.ERROR, message: maxValueErrorMessage }
    }
    return { type: Status.SUCCESS, message: '' }
  }

  function handleChange(element: React.ChangeEvent<HTMLInputElement>) {
    let numberValue = price2number(element.target.value)
    if (overrideWithMaxValue && maxValue && numberValue > maxValue) {
      numberValue = maxValue
    }
    const validationError = getError(numberValue)
    if (handleError) {
      handleError(validationError)
    }
    setError(validationError)
    onChange(numberValue)
    setValue(number2price(numberValue))
  }

  function getInfoIcon(): string {
    switch (error.type) {
      case Status.ERROR:
        return Info
      case Status.SUCCESS:
        return Success
      default:
        return ''
    }
  }

  return (
    <>
      <Wrapper type={error.type} active={inputFocus}>
        <Input
          type="text"
          data-testid="currency-input"
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          onFocusCapture={() => setInputFocus(true)}
          onBlur={() => setInputFocus(false)}
        />
        <IconWrapper>
          {getInfoIcon() && <img src={getInfoIcon()} alt="ícone de alerta" />}
        </IconWrapper>
      </Wrapper>
      <Label data-testid="error-message">
        {error.type === Status.ERROR ? error.message : ''}
      </Label>
    </>
  )
}
