import styled, { css } from 'styled-components'
import { Colors } from '../../../../Styles/theme'

const flexboxMenu = css`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;
  white-space: nowrap;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
`

export const Wrapper = styled.section`
  border-top: 3px solid ${Colors.stonegreen1};

  .menu--portal-stone {
    position: relative;
    width: 100%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    ${flexboxMenu}
    align-items: flex-end;
    justify-content: space-between;
    padding: 15px 0px 20px 36px;

    .menu__brand {
      width: 76px;
      flex-basis: 100%;
    }

    .menu__media {
      display: block;
      width: 100%;
      height: auto;
    }

    .menu__area {
      position: relative;
      ${flexboxMenu}
      flex-direction: column;
    }

    .menu__area--nav {
      width: 76%;
      flex-basis: 76%;
      margin-left: -22px;
    }

    .menu__area--brand {
      padding-right: 20px;
    }

    .menu__area--primary {
      width: auto;
      flex-basis: auto;
    }

    .menu__target {
      position: relative;
      ${flexboxMenu}
      justify-content: space-between;
    }

    .menu__target--top,
    .menu__target--right {
      margin-bottom: -15px;
    }

    .menu__target--sub {
      position: absolute;
      flex-direction: column;
      opacity: 0;
      visibility: hidden;
      min-width: 200px;
      background: #fff;
      padding: 15px 0;
      box-shadow: 1px 2px 5px 1px rgba(153, 153, 153, 0.5);
      z-index: 10;
      transition: opacity 0.3s ease-in-out;
    }

    .menu__target--sub {
      .menu__area:not(:nth-child(1)) {
        .menu__title {
          padding: 34px 0 12px 28px;
        }
      }
    }

    .menu__list {
      position: relative;
      width: 100%;
      flex-basis: 100%;
      ${flexboxMenu}
      justify-content: flex-start;
    }

    .menu__list .menu__list {
      flex-direction: column;
    }

    .menu__list--company {
      display: none;
      font-size: 12px;
      padding: 12px 48px 12px 48px;

      .menu__company {
        color: ${Colors.lightmediumgray};
      }
    }

    .menu__item {
      position: relative;
      ${flexboxMenu}
      flex-direction: column;

      position: static;
      list-style-type: none;
      list-style-position: outside;
    }

    .menu__item:hover .menu__handle--controls:after,
    .menu__item:hover .menu__handle--user:after,
    .menu__item:hover .menu__target--sub {
      opacity: 1;
      visibility: visible;
      transition: opacity 0.3s ease-in-out;
    }

    .menu__item--user {
      .menu__target--sub {
        right: 4%;
      }
    }

    .menu__title {
      display: block;
      padding: 8px 0 12px 28px;
      margin: 0;

      font-size: 14px;
      color: #535353;
      font-weight: 400;
    }

    .menu__user,
    .menu__company {
      display: block;
      font-size: 13px;
      text-transform: capitalize;
      line-height: 1.5;
      font-weight: 600;
    }

    .menu__user {
      color: ${Colors.stonegreen1};
    }
    .menu__company {
      color: ${Colors.mediumtodarkgray};
    }

    .menu__picture {
      display: block;
      overflow: hidden;
      border-radius: 50%;
      max-width: 40px;
    }

    .menu__handle {
      position: relative;
      padding: 15px;
      margin: 0 8px;
      font-size: 14px;
      line-height: 1;
      color: ${Colors.mediumtodarkgray};
      cursor: pointer;
    }

    .menu__handle--user {
      padding: 5px 15px 10px 15px;
    }

    .current.menu__handle,
    .menu__handle:hover {
      color: ${Colors.stonegreen1};
    }
    .current.menu__handle {
      font-weight: 600;
    }

    .menu__item .menu__item .menu__handle {
      padding: 12px 48px 12px 48px;
      font-size: 12px;
      margin: 0;
    }

    .menu__item .menu__item .menu__handle--stone-code {
      position: relative;
      padding: 12px 70px 12px 48px;

      span {
        font-family: Open Sans, 'Helvetica Neue', 'Helvetica', 'Roboto', 'Arial',
          sans-serif;
        font-size: 12px;
      }

      strong {
        display: block;
        font-size: 16px;
        line-height: 1.5;
        font-family: Open Sans, 'Helvetica Neue', 'Helvetica', 'Roboto', 'Arial',
          sans-serif;
      }
    }

    .glyphicon-cog {
      color: ${Colors.lightmediumgray};
    }

    .glyphicon-cog:before {
      position: absolute;
      top: 18px;
      right: 32px;
      font-size: 24px;
    }

    .menu__item .menu__item .current.menu__handle,
    .menu__item .menu__item .menu__handle:hover {
      color: #fff;
      background: ${Colors.stonegreen1};
    }

    .menu__item .menu__item .menu__handle:hover:before {
      color: #fff;
    }

    .menu__handle--7000 {
      color: #0db14b;
      font-weight: 600;
    }

    .menu__handle--7000:before {
      content: '⚲';
      position: absolute;
      display: block;
      left: -2px;
      bottom: 13px;
      font-size: 20px;
      transform: rotate(-38deg);
    }

    .menu__handle--controls:before {
      content: '▾';
      position: absolute;
      font-size: 12px;
      top: 0;
      bottom: 0;
      right: 2px;
      margin: 17px auto;
    }

    .menu__handle--user:after,
    .menu__handle--controls:after {
      content: '▾';
      opacity: 0;
      visibility: hidden;
      transform: rotate(180deg);
      font-size: 44px;
      position: absolute;
      color: #fff;
      top: 0;
      bottom: 0;
      right: -9px;
      margin: -22px auto;
      text-shadow: 0px 1px 1px rgba(150, 150, 150, 0.4);
      z-index: 12;
    }

    .menu__handle--user:after {
      right: 26px;
    }

    .menu__handle--user:before {
      content: '∨';
      position: absolute;
      display: block;
      transform: scale(4, 2);
      font-size: 5px;
      right: 0;
      bottom: 18px;
      font-weight: 600;
      margin: auto;
      text-shadow: 0px 0px 0px #999;
    }
  }

  .menu--portal-stone {
    .menu__handle--for-in-desktop {
      display: block;
    }
    .menu__handle--for-in-mobile {
      display: none;
    }
  }

  @media screen and (max-width: 1000px) {
    .menu--portal-stone {
      .menu__handle--for-in-desktop {
        display: none;
      }
      .menu__handle--for-in-mobile {
        display: block;
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .menu--portal-stone {
      padding: 15px 16px 20px 16px;
    }
  }

  @media screen and (max-width: 1000px) {
    .menu--portal-stone {
      flex-flow: row;
      flex-wrap: wrap;

      .glyphicon-cog:before {
        right: 46px;
      }

      .menu__area--brand {
        width: 86.5%;
        flex-basis: 86.5%;
        padding: 0 0 15px;
      }

      .menu__area--nav {
        width: 100%;
        flex-basis: 100%;
        order: 3;

        .menu__target--top .menu__item .menu__area {
          display: none;
        }
      }

      .menu__area--user {
        padding: 0 0 15px;
      }

      .menu__list--company {
        display: block;
      }

      .menu__list--company:before {
        content: '';
        display: block;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 2px auto;
        position: absolute;
        width: 67%;
        height: 1px;
        background: rgba(153, 153, 153, 0.2);
      }

      .menu__handle--user:before,
      .menu__handle--controls:after,
      .menu__handle--controls:before {
        display: none;
      }

      .menu__item--user.menu__item--active .menu__handle--user:after,
      .menu__item--user.menu__item--active .menu__target--user {
        opacity: 1;
        visibility: visible;
      }

      .menu__item:hover .menu__handle--user:after,
      .menu__item:hover .menu__target--sub,
      .menu__target--user {
        opacity: 0;
        visibility: hidden;
      }

      .menu__item--user.menu__item--active .menu__handle--user:after,
      .menu__item--user.menu__item--active .menu__target--user {
        opacity: 1;
        visibility: visible;
      }

      .menu__item--user {
        .menu__target--sub {
          right: 28%;
          top: 8px;
        }
      }

      .menu__handle--user:after {
        right: 20px;
        bottom: -8px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .header--portal-stone {
      border: 5px solid #0db14b;
    }

    .menu--portal-stone {
      flex-wrap: nowrap;
      padding: 0;

      .menu__area--nav {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        background: #fff;
        box-shadow: 0px 0px 7px 0px rgba(150, 150, 150, 0.8);
        z-index: 4000;
      }

      .menu__area--brand {
        width: 100%;
        flex-basis: 100%;
        align-items: center;
        padding: 10px 0;
      }

      .menu__area--user {
        position: absolute;
        right: 0;
        top: 0;
        padding: 0;
      }

      .menu__area--primary {
        width: auto;
        flex-basis: auto;
        min-width: initial;
        flex-shrink: 0;
      }

      .menu__list {
        width: auto;
        flex-basis: auto;
      }

      .menu__target--top {
        margin-bottom: 0px;
        overflow-x: scroll;
        overflow-y: hidden;
        justify-content: flex-start;
      }

      .menu__target--right {
        margin-bottom: 0;
      }

      .menu__item--user {
        .menu__target--sub {
          right: 12%;
          top: 20px;
        }
      }

      .menu__item:hover .menu__handle--user:after,
      .menu__item:hover .menu__target--sub,
      .menu__target--user {
        opacity: 0;
        visibility: hidden;
      }

      .menu__item--user.menu__item--active .menu__handle--user:after,
      .menu__item--user.menu__item--active .menu__target--user {
        opacity: 1;
        visibility: visible;
      }

      .menu__handle {
        margin: 0;
      }

      .menu__handle--7000 {
        color: ${Colors.mediumtodarkgray};
        font-weight: 400;
      }

      .current.menu__handle {
        background: ${Colors.stonegreen1};
        color: #fff;
      }

      .menu__handle--user:before,
      .menu__handle--controls:after,
      .menu__handle--controls:before,
      .menu__handle--7000:before {
        display: none;
      }

      .menu__handle--user {
        padding: 5px 8px 5px 15px;
      }

      .menu__handle--user:after {
        right: 14px;
        bottom: -20px;
      }
    }
  }
`

export const MenuSection = styled.div`
  &::after {
    content: '';
  }
`
