import React from 'react'
import { checkPermission, hasStoneCode } from 'Services/base.auth'
import { Option } from './styles'

export default function Loan() {
  if (hasStoneCode() && checkPermission('REQUEST_CREDIT')()) {
    return (
      <li className="menu__item">
        <Option>Empréstimo</Option>
      </li>
    )
  }
  return <></>
}
