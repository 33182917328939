import styled, { keyframes } from 'styled-components'
import { Col, Row } from 'react-bootstrap'
import { Colors } from 'Styles/theme'
import { LoaderIcon } from 'Assets'

const rotate = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`

export const ContainerHead = styled(Row)`
  display: flex;
  align-items: center;
  font-size: 14px;
  height: 40px;
  color: ${Colors.gray3};
  font-weight: 450;
  padding: 0 0 0 20px;
  margin-top: 41px;
`

export const AmountToPayHead = styled(Col)`
  margin-left: -30px;
`

export const ReleaseTypeHead = styled(Col)`
  margin-left: 30px;
`

export const ContainerBody = styled(Row)`
  display: flex;
  align-items: center;
  font-size: 14px;
  height: 40px;
  color: ${Colors.darkazureishgray};
  font-weight: 500;
  padding: 0 0 0 20px;

  &:nth-child(2n) {
    background-color: ${Colors.gray6};
  }
`

export const AmountToPayData = styled(Col)`
  margin-left: -30px;
`

export const ReleaseTypeData = styled(Col)`
  margin-left: 30px;
`

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 14px 0;
  width: 100%;
`

export const Loader = styled.img.attrs({
  src: LoaderIcon,
})`
  animation: ${rotate} 0.5s linear infinite;
`
