import { AxiosRequestConfig } from 'axios'
import { IStoneCode } from 'Interfaces'
import api from 'Services/base.auth'

export interface IGetStoneCodesProps {
  axiosConfig?: AxiosRequestConfig
}

export function notDuplicate() {
  const alreadyHas: string[] = []

  return (item: IStoneCode) => {
    if (alreadyHas.includes(item.cnpj)) return false

    alreadyHas.push(item.cnpj)

    return true
  }
}

export default async function getStoneCodes(
  props: IGetStoneCodesProps = {}
): Promise<IStoneCode[]> {
  const axiosConfig = props && props.axiosConfig

  const { data }: { data: IStoneCode[] } = await api.get(
    '/v1/users/current/stonecodes',
    {
      ...axiosConfig,
    }
  )

  const skipDuplicated = notDuplicate()

  return data.filter(skipDuplicated)
}
