export enum Status {
  PENDING,
  ERROR,
  SUCCESS,
}

export interface IErrorValidation {
  type: Status
  message?: string
}
