import styled from 'styled-components'
import { Colors } from 'Styles/theme'

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  button {
    background: none;
    border: none;
    font-family: CircularStd;
    font-weight: 500;
    font-size: 14px;
    text-align: right;
    color: ${Colors.darkgreen};
  }
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const Title = styled.label`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${Colors.darkblue};
`
