import verify from './Verify'

export interface IVerifyProps {
  email: string
  document: string
}

export interface IIdentityModel {
  verify(props?: IVerifyProps): Promise<any>
}

export default (): IIdentityModel => {
  return {
    verify: ({ email, document }: IVerifyProps) => {
      const request = verify({
        email,
        document,
      })

      return request
    },
  }
}
