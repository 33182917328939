import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'
import { onFilterProps } from 'BusinessComponent/LoanExtract/Filters'
import { useLoanExtract } from 'Hooks/useLoanExtract'
import { removeSpecialCharacterFrom } from 'Utils/document'
import { IPagination } from 'Interfaces'
import { ILoanExtract } from 'Interfaces/loanExtract.interface'
import { AxiosResponse } from 'axios'
import { IDailyStatementMapped } from 'BusinessComponent/LoanExtract/ExtractDataTable/datatable'
import { IDailyStatementItem } from 'Interfaces/dailyStatement.interface'
import { LoanDetailsContext, StoreContext } from './LoanDetailsContext'

export interface IExtractFiltersProps {
  children: ReactNode
  document: string
}

export interface IExtractFiltersContext {
  error: boolean
  isLoading: boolean
  dateRange: Date[]
  setDateRange: React.Dispatch<React.SetStateAction<Date[]>>
  entryType: string
  setEntryType: React.Dispatch<React.SetStateAction<string>>
  loanExtract: IPagination<ILoanExtract> | null
  statements: IDailyStatementMapped[]
  handleFilter(props: onFilterProps): void
  handleLoadMore(): void
  handleDownloadCsv(): Promise<AxiosResponse<string>>
}

export const ExtractFiltersContext = createContext<IExtractFiltersContext>({
  error: false,
  isLoading: false,
  dateRange: [],
  entryType: '',
  setDateRange() {},
  setEntryType() {},
  loanExtract: null,
  statements: [],
  handleFilter() {},
  handleLoadMore() {},
  handleDownloadCsv() {
    return {} as Promise<AxiosResponse<string>>
  },
})

export function mapDailyStatements(dailyStatements: IDailyStatementItem[]) {
  const daily: IDailyStatementMapped[] = []

  dailyStatements.forEach(statement => {
    const { entries, ...item } = statement

    entries.forEach(entry =>
      daily.push({
        ...item,
        ...entry,
      })
    )
  })

  return daily
}

export default ({ children, document }: IExtractFiltersProps) => {
  const [dateRange, setDateRange] = useState<Date[]>([])
  const [entryType, setEntryType] = useState<string>('')
  const [page, setPage] = useState<number>(1)
  const [dateFrom, setDateFrom] = useState<string>('')
  const [dateTo, setDateTo] = useState<string>('')
  const [statements, setStatements] = useState<IDailyStatementMapped[]>([])

  const loanContext = useContext<LoanDetailsContext | null>(StoreContext)

  const {
    loanExtract,
    getLoanStatement,
    clearLoanExtract,
    getLoanStatementCsv,
    error,
    isLoading,
  } = useLoanExtract({
    document: removeSpecialCharacterFrom(document),
    borrowerId: loanContext?.currentLoan?.borrower.id!,
    loanId: loanContext?.currentLoan?.id!,
  })

  useEffect(() => {
    const dailyStatementMapped = mapDailyStatements(
      loanExtract?.data.dailyStatements ?? []
    )
    setStatements(item => item.concat(dailyStatementMapped))
  }, [loanExtract])

  useEffect(() => {
    getLoanStatement({
      dateFrom,
      dateTo,
      entryType,
      page,
    })
  }, [page, dateFrom, dateTo, entryType, getLoanStatement])

  useEffect(() => {
    const FIRST_PAGE = 1

    if (page === FIRST_PAGE) {
      clearLoanExtract()
      setStatements([])
    }
  }, [page, dateFrom, dateTo, entryType, dateRange, clearLoanExtract])

  function handleFilter({
    dateRange: dates,
    entryType: releaseType,
  }: onFilterProps) {
    if (dates.length) {
      const [start, end] = dates
      const dateStart = start.toISOString().split('T')[0]
      const dateEnd = end.toISOString().split('T')[0]

      setDateFrom(dateStart)
      setDateTo(dateEnd)
    }

    setPage(1)
    setEntryType(releaseType)
    setDateRange(dates)
  }

  function handleLoadMore() {
    setPage(currentPage => currentPage + 1)
  }

  function handleDownloadCsv() {
    return getLoanStatementCsv({
      dateFrom,
      dateTo,
      entryType,
      page,
    })
  }

  const store: IExtractFiltersContext = {
    error,
    isLoading,
    dateRange,
    setDateRange,
    entryType,
    setEntryType,
    loanExtract,
    statements,
    handleFilter,
    handleLoadMore,
    handleDownloadCsv,
  }

  return (
    <ExtractFiltersContext.Provider value={store}>
      {children}
    </ExtractFiltersContext.Provider>
  )
}
