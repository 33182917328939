import styled from 'styled-components'

import { Colors } from 'Styles/theme'

export const CustomGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`

export const Text = styled.p`
  font-family: CircularStd;
  font-size: 16px;
  color: ${Colors.darkblue};
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 16px;
`
