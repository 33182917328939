import styled from 'styled-components'
import { Colors } from 'Styles/theme'

export const Wrapper = styled.div`
  display: flex;
  margin: 6px 3px 20px 3px;
  border: 1px solid #c3c8d2;
  border-radius: 2px;
  height: 50px;
`

export const VisibilityButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background: white;
  padding: 0px;
  width: 140px;
  width: 155px;
  line-height: 18px;
  letter-spacing: 0.4px;
  white-space: nowrap;
  color: ${Colors.gray3};
  font-family: CircularStd;
  font-size: 12px;
  font-weight: 500;
  font-weight: 500;

  &:focus {
    outline: none;
  }

  img {
    padding: 10px;
    width: 50px;
  }
`

export const Input = styled.input`
  border: none;
  padding: 16px;
  width: 100%;
  height: 100%;
  line-height: 21px;
  color: ${Colors.gray3};
  font-family: CircularStd;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;

  &:focus {
    outline: none;
  }

  &::placeholder {
    font-size: 14px;
    color: ${Colors.gray3};
    font-family: CircularStd;
  }
`
