import axios, { CancelTokenSource } from 'axios'
import { IStoneCode } from 'Interfaces'
import getStoneCodes from './getCodes'

export interface IGetStoneCodesProps {
  getCancelTokenSource(source: CancelTokenSource): void
}

export interface IStoneCodeModel {
  getStoneCodes(props?: IGetStoneCodesProps): Promise<IStoneCode[]>
}

export default (): IStoneCodeModel => {
  return {
    getStoneCodes: ({ getCancelTokenSource }: IGetStoneCodesProps) => {
      const source = axios.CancelToken.source()

      const request = getStoneCodes({
        axiosConfig: {
          cancelToken: source.token,
        },
      })

      getCancelTokenSource(source)

      return request
    },
  }
}
