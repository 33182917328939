import React, { useState } from 'react'
import { EyeClose, EyeOpen } from 'Assets'

import { Input, VisibilityButton, Wrapper } from './styles'

export interface Props {
  value: string
  onChange(value: string): void
}

export default function PasswordInput({ value, onChange }: Props) {
  const [showPassword, setShowPassword] = useState<boolean>(false)

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    onChange(event.target.value)
  }

  return (
    <Wrapper>
      <Input
        placeholder="Digite sua senha"
        type={showPassword ? 'text' : 'password'}
        value={value}
        onChange={handleChange}
        data-testid="password-input"
      />
      <VisibilityButton onClick={() => setShowPassword(!showPassword)}>
        <img
          src={showPassword ? EyeClose : EyeOpen}
          alt="Visibilidade da senha"
        />
        {showPassword ? 'Ocultar' : 'Mostrar'}
      </VisibilityButton>
    </Wrapper>
  )
}
