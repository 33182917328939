import { useCallback, useState } from 'react'

export interface IUseNetworkError {
  error: boolean
  updateError(err: boolean): void
}

export default function useNetworkError(): IUseNetworkError {
  const [error, setError] = useState<boolean>(false)

  const updateError = useCallback((err: boolean) => {
    setError(err)
  }, [])

  return {
    error,
    updateError,
  }
}
