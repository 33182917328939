import styled from 'styled-components'

import { Modal } from 'react-bootstrap'

import { Colors } from 'Styles/theme'
import { Accordion } from 'Components/Accordion'

export const CustomModal = styled(Modal)`
  .modal-dialog {
    max-width: 602px;
  }

  .modal-content {
    background-color: ${Colors.lightazureishgray};
    padding: 16px;

    header {
      margin-bottom: 12px;
    }

    .area-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 34px;

      h2 {
        font-family: CircularStd;
        font-weight: bold;
        font-size: 24px;
        letter-spacing: -0.01em;

        color: ${Colors.darkazureishgray};

        margin-top: 12px;
        margin-left: 24px;
      }

      img {
        cursor: pointer;
        width: 14px;
        margin-right: 13px;
      }
    }

    .area-tabs {
      max-width: 282px;
    }
  }
`
export const CustomAccordion = styled(Accordion)`
  margin-top: 10px;
`
