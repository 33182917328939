import { createContext } from 'react'
import { IUseLoanReturn } from 'Hooks/useLoan'

const LoansContext = createContext<IUseLoanReturn>({
  error: false,
  getLoans: () => {},
  isLoading: false,
  loans: {
    data: [],
    messages: [],
    page: 1,
    pageSize: 10,
    total: 0,
  },
})

export default LoansContext
