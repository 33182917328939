import React from 'react'

import { IRepaymentEntry } from 'Interfaces/dailyStatement.interface'
import { Loader, LoaderContainer } from './styles'

export interface IDailyStatementMapped {
  day: number
  date: string
  initialDebtAmount: number
  finalDebtAmount: number
  debtAmountBeforeEntry: number
  debtAmountAfterEntry: number
  type: string
  description: string
  amount: number
  repaymentEntries?: IRepaymentEntry[]
}

export const LoaderTable: React.FC = () => (
  <LoaderContainer>
    <Loader />
  </LoaderContainer>
)
