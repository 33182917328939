import React from 'react'
import { LayoutState } from 'Components/LayoutState'
import { Button } from 'Components/Button'
import { PersonWarning } from 'Assets'

interface Props {
  onUpdateData(): void
}

const HasPermission = ({ onUpdateData }: Props): JSX.Element => {
  return (
    <LayoutState
      icon={PersonWarning}
      title="Você precisa atualizar seus dados bancários"
      subtitle={
        <>
          Identificamos que a conta bancária cadastrada é inválida. Para
          prosseguir, clique no
          <br />
          botão abaixo e atualize seus dados bancários. Em seguida, faça uma
          simulação do
          <br />
          empréstimo e conclua sua contratação.
        </>
      }
      button={
        <Button onClick={onUpdateData} data-testid="update-bank-info">
          Atualizar dados
        </Button>
      }
    />
  )
}

export default HasPermission
