import React from 'react'

import LastAttemptAlert from './LastAttemptAlert'
import { Highlight, Label, PrimaryLabel } from './styles'

interface Props {
  attempts: number
  showLastAttemptAlert?: boolean
}

interface IRemainingAttemptMessageProps {
  attempt: number
  label: string
}

function RemainingAttemptMessage({
  attempt,
  label,
}: IRemainingAttemptMessageProps) {
  return (
    <>
      <PrimaryLabel>
        Ao errar a senha 3 vezes, a sessão no Portal será encerrada e você
        deverá fazer login novamente
      </PrimaryLabel>
      <Label>
        Você tem mais
        <Highlight>
          <strong> {attempt}</strong> {label}
        </Highlight>
      </Label>
    </>
  )
}

export default function AttemptsValidationMessages({
  attempts,
  showLastAttemptAlert,
}: Props) {
  if (showLastAttemptAlert) {
    return <LastAttemptAlert />
  }
  switch (attempts) {
    case 1:
      return <RemainingAttemptMessage attempt={1} label="tentativa" />
    case 2:
      return <RemainingAttemptMessage attempt={2} label="tentativas" />
    default:
      return <Label>Por favor, digite sua senha</Label>
  }
}
