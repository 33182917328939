import { useEffect, useState } from 'react'
import axios, { CancelTokenSource } from 'axios'
import StoneCodeModel from 'Models/StoneCode'
import { IStoneCode } from 'Interfaces'
import canDo from 'Utils/canDo'

export default function useStoneCodes(): [IStoneCode[], boolean, boolean] {
  const [stoneCodes, setStoneCodes] = useState<IStoneCode[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [error, setError] = useState<boolean>(false)

  useEffect(() => {
    let sourceRequest: CancelTokenSource | null = null
    let canDoIt = true

    async function getStoneCodes() {
      setIsLoading(true)
      const stoneCodeModel = StoneCodeModel()
      try {
        const resultStoneCodes = await stoneCodeModel.getStoneCodes({
          getCancelTokenSource(source: CancelTokenSource): void {
            sourceRequest = source
          },
        })
        canDo(() => setStoneCodes(resultStoneCodes))(canDoIt)
      } catch (err) {
        if (!axios.isCancel(err)) {
          setError(true)
        }
      }
      canDo(() => setIsLoading(false))(canDoIt)
    }

    getStoneCodes()

    return () => {
      canDoIt = false

      if (sourceRequest) {
        sourceRequest.cancel('GetStoneCodes was cancelled manually')
      }
    }
  }, [])

  return [stoneCodes, isLoading, error]
}
