export function number2price(
  value: number,
  showSymbol: boolean = true
): string {
  return Intl.NumberFormat('pt-br', {
    style: showSymbol ? 'currency' : 'decimal',
    currency: 'BRL',
    minimumFractionDigits: 2,
  }).format(value ?? 0)
}

export const price2number = (str: string): number =>
  Number(str.replace(/\D/g, '')) / 100
