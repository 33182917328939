import styled from 'styled-components'

import { Colors } from 'Styles/theme'

export const Container = styled.div`
  background: #ffffff;
  border-bottom: 1px solid ${Colors.lavenderblue};
  height: 75px;
  font-family: Roboto, sans-serif;

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .icon {
    width: 0.9275em;
    margin-right: 0.5em;
  }

  p {
    margin: 0;
    font-size: 0.9575em;
    margin-right: 1.5em;
  }

  .link {
    font-weight: 600;
    font-size: 0.85em;

    &:hover {
      color: #086a2d;
    }
  }
`
