export const LOAN_STATUS = {
  ACCEPTED: 'Accepted',
  DISBURSEMENT_REQUESTED: 'DisbursementRequested',
  DISBURSEMENT_CONFIRMED: 'DisbursementConfirmed',
  ACTIVE: 'Active',
  SETTLED: 'Settled',
  INACTIVE: 'Inactive',
  SUSPENDED: 'Suspended',
  CANCELLED: 'Cancelled',
  // virtual status
  SUSPENDED_DISBURSEMENT: 'Suspended_Disbursement',
  SUSPENDED_NON_DISBURSEMENT: 'Suspended_NonDisbursement',
}

export const LOAN_STATUS_TRANSLATED = {
  [LOAN_STATUS.ACCEPTED]: 'Em análise',
  [LOAN_STATUS.DISBURSEMENT_REQUESTED]: 'Em análise',
  [LOAN_STATUS.DISBURSEMENT_CONFIRMED]: 'Em análise',
  [LOAN_STATUS.ACTIVE]: 'Em andamento',
  [LOAN_STATUS.SETTLED]: 'Quitado',
  [LOAN_STATUS.INACTIVE]: 'Cancelado',
  [LOAN_STATUS.CANCELLED]: 'Cancelado',
  [LOAN_STATUS.SUSPENDED]: 'Suspenso',
  [LOAN_STATUS.SUSPENDED_DISBURSEMENT]: 'Suspenso',
  [LOAN_STATUS.SUSPENDED_NON_DISBURSEMENT]: 'Suspenso',
}

export enum LoanStatusEnum {
  ACTIVE = 'Active',
  SETTLED = 'Settled',
  INACTIVE = 'Inactive',
  SUSPENDED = 'Suspended',
  CANCELLED = 'Cancelled',
  ACCEPTED = 'Accepted',
  DISBURSEMENT_REQUESTED = 'DisbursementRequested',
  DISBURSEMENT_CONFIRMED = 'DisbursementConfirmed',
  SUSPENDED_DISBURSEMENT = 'Suspended_Disbursement',
  SUSPENDED_NON_DISBURSEMENT = 'Suspended_NonDisbursement',
}
