import axios, { CancelTokenSource } from 'axios'
import { IProposalSubmission } from 'Interfaces'

import proposalSubmition from './proposalSubmition'

export interface IProposalSubmitionProps {
  getCancelTokenSource(source: CancelTokenSource): void
  document: string
  proposalId: string
  pinCode?: string
  challengeId?: string
}

export interface IProposalSubmitionModel {
  proposalSubmition(
    props?: IProposalSubmitionProps
  ): Promise<IProposalSubmission>
}

export default (): IProposalSubmitionModel => {
  return {
    proposalSubmition: ({
      getCancelTokenSource,
      document,
      proposalId,
      pinCode,
      challengeId,
    }: IProposalSubmitionProps) => {
      const source = axios.CancelToken.source()

      let customHeaders = {}

      if (challengeId && pinCode) {
        customHeaders = {
          'Authorization-ChallengeCode': pinCode,
          'Authorization-ChallengeID': challengeId,
        }
      }

      const request = proposalSubmition({
        axiosConfig: {
          cancelToken: source.token,
        },
        customHeaders,
        document,
        proposalId,
      })

      getCancelTokenSource(source)

      return request
    },
  }
}
