import React from 'react'

import { Wrapper } from './styles'

export default function Jumbotron({
  children,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return <Wrapper {...props}>{children}</Wrapper>
}
