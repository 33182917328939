import { useEffect, useState } from 'react'

import { remoteConfig } from 'Services/firebase'

interface IUseRemoteConfig {
  configName: string
  defaultValue: string
}

interface IUseRemoteConfigReturn {
  isLoading: boolean
  value: string
}

export default function useRemoteConfig({
  configName,
  defaultValue,
}: IUseRemoteConfig): IUseRemoteConfigReturn {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [value, setValue] = useState<string>(defaultValue)

  useEffect(() => {
    ;(async () => {
      try {
        if (remoteConfig.lastFetchStatus !== 'success') {
          setIsLoading(true)
          await remoteConfig.fetchAndActivate()
        }
        const remoteValue = remoteConfig.getString(configName)
        setValue(remoteValue || defaultValue)
      } catch (e) {
        setValue(defaultValue)
      }
      setIsLoading(false)
    })()
  }, [configName, defaultValue])

  return {
    isLoading,
    value,
  }
}
