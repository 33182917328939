import { createContext } from 'react'
import { IUseTabsReturn } from 'Hooks/useTabs'
import { TabFullScreenEnum } from 'Enums/tab'

const TabSelectedContext = createContext<IUseTabsReturn>({
  tabSelected: 0,
  setTabSelected: () => {},
  tabFullScreenSelected: TabFullScreenEnum.NONE,
  setTabFullScreenSelected: () => {},
})

export default TabSelectedContext
