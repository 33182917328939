interface IdentifyUserParams {
  name: string
  email: string
  stoneCode: string
  company: string
}

export const trackPage = () => {
  window?.Appcues?.page()
}

export const identifyUser = (userId: string, params: IdentifyUserParams) => {
  window?.Appcues?.identify(userId, params)
}

export const trackEvent = (eventName: string, params: Object = {}) => {
  window?.Appcues?.track(eventName, params)
}
