import React, { useEffect, useState } from 'react'

import { Close } from 'Assets'

import PasswordInput from 'Components/PasswordInput'

import Loader from 'Components/Loader'

import AttemptsValidationMessages from './AttemptsValidationMessages'
import ActionButtons from './ActionButtons'

import { Body, CloseButton, Header, SModal, Title } from './styles'

interface Props {
  value: string
  show: boolean
  isLoading?: boolean
  attempts: number
  onChange(value: string): void
  onConfirm(): void
  onCancel(): void
}

export default function PasswordConfirmModal({
  value,
  show,
  attempts,
  onConfirm,
  onCancel,
  onChange,
  isLoading,
}: Props) {
  const [showLastAttemptAlert, setShowLastAttemptAlert] = useState<boolean>(
    false
  )

  useEffect(() => {
    if (attempts === 1) {
      setShowLastAttemptAlert(true)
    }
  }, [attempts])

  function handleConfirm() {
    if (showLastAttemptAlert) {
      setShowLastAttemptAlert(false)
    } else {
      onConfirm()
    }
  }

  return (
    <SModal show={show} size="md" onHide={onCancel}>
      <Header>
        <Title>
          {attempts === 3 ? 'Confirmar Senha' : 'Ops! Senha incorreta'}
        </Title>
        <CloseButton onClick={onCancel}>
          <img src={Close} alt="Botão fechar modal" />
        </CloseButton>
      </Header>
      <Body>
        {isLoading ? (
          <Loader spinner />
        ) : (
          <>
            <AttemptsValidationMessages
              attempts={attempts}
              showLastAttemptAlert={showLastAttemptAlert}
            />
            {!showLastAttemptAlert && (
              <PasswordInput value={value} onChange={onChange} />
            )}
            <ActionButtons
              showLastAttemptAlert={showLastAttemptAlert}
              showCancelButton={attempts !== 3}
              handleConfirm={handleConfirm}
              handleCancel={onCancel}
            />
          </>
        )}
      </Body>
    </SModal>
  )
}
