import { useEffect, useState } from 'react'
import axios, { CancelTokenSource } from 'axios'
import ProposalModel from 'Models/Proposal'
import { IProposal } from 'Interfaces'
import canDo from 'Utils/canDo'
import { ErrorMapperEnum } from 'Enums/proposal'

export default function useProposal(
  document: string,
  creditAmount: number,
  creditLineId: string
): [IProposal | null, boolean, ErrorMapperEnum] {
  const [proposal, setProposal] = useState<IProposal | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [error, setError] = useState<ErrorMapperEnum>(ErrorMapperEnum.NO_ERROR)

  useEffect(() => {
    let sourceRequest: CancelTokenSource | null = null
    let canDoIt = true

    async function getProposal() {
      setError(ErrorMapperEnum.NO_ERROR)
      setIsLoading(true)

      const proposalModel = ProposalModel()

      try {
        const result = await proposalModel.getProposal({
          getCancelTokenSource(source: CancelTokenSource): void {
            sourceRequest = source
          },
          document,
          creditAmount,
          creditLineId,
        })

        canDo(() => setProposal(result))(canDoIt)
      } catch (err) {
        if (!axios.isCancel(err)) {
          setError(err.message)
        }
      }
      canDo(() => setIsLoading(false))(canDoIt)
    }

    getProposal()

    return () => {
      canDoIt = false

      if (sourceRequest) {
        sourceRequest.cancel('GetProposal was cancelled manually')
      }
    }
  }, [document, creditAmount, creditLineId])

  return [proposal, isLoading, error]
}
