import React, { useEffect, useState } from 'react'

import { appConfig } from 'Config'
import { Container } from 'Containers/navigation/partials/Header/LockBar/styles'
import { Lock } from 'Assets'
import { hasPartialLock } from 'Helper/cookie'

export default function LockBar() {
  const [show, setShow] = useState(false)

  useEffect(() => {
    setShow(hasPartialLock())
  }, [])

  return (
    (show && (
      <Container>
        <div className="content">
          <img className="icon" src={Lock} alt="imagem de um cadeado." />
          <p>Você possui uma trava de crédito ativa.</p>
          <a className="link" href={`${appConfig.portalURL}/trava-parcial`}>
            Ver Detalhes
          </a>
        </div>
      </Container>
    )) || <></>
  )
}
