import styled, { keyframes } from 'styled-components'

const fadeIn = keyframes`
  from { opacity: 0; margin-top: 10px; }
  to { opacity: 1; margin-top: 0px; }
`

export const Container = styled.div`
  animation: ${fadeIn} 0.1s linear;
`
