import React from 'react'

import { Colors } from 'Styles/theme'
import { CancelButton, ConfirmButton, Wrapper } from './styles'

interface Props {
  value: string
  showLastAttemptAlert: boolean
  showCancelButton: boolean
  handleConfirm(): void
  handleCancel(): void
}

export default function ActionButtons({
  value,
  showLastAttemptAlert,
  showCancelButton,
  handleConfirm,
  handleCancel,
}: Props) {
  const MIN_LENGTH_PIN_CODE = 6

  return (
    <Wrapper>
      {showCancelButton && (
        <CancelButton color={Colors.lightblue} onClick={handleCancel}>
          Cancelar
        </CancelButton>
      )}
      <ConfirmButton
        onClick={handleConfirm}
        data-testid="confirm-button"
        disabled={value.length < MIN_LENGTH_PIN_CODE}
      >
        {showLastAttemptAlert ? 'Continuar' : 'Confirmar'}
      </ConfirmButton>
    </Wrapper>
  )
}
