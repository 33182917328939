import { Colors } from 'Styles/theme'

export const stoneCustomStyle = {
  table: {
    style: {
      fontFamily: 'CircularStd',
    },
  },
  header: {
    style: {
      fontSize: '14px',
      fontWeight: 400,
      paddingLeft: 0,
    },
  },
  rows: {
    style: {
      height: '75px',
      fontSize: '14px',
      fontWeight: 450,
      color: Colors.darkblue,
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: Colors.lightazureishgray,
      },
    },
  },
  headCells: {
    inactiveSortStyle: {
      fontSize: '14px',
      fontWeight: 400,
      color: Colors.cyanblue,
      cursor: 'auto',
      '&:hover:focus': {
        color: Colors.cyanblue,
      },
    },
  },
  headRow: {
    style: {
      borderBottomWidth: '1px',
      borderBottomColor: Colors.lightblue,
    },
  },
}
