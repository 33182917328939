import React from 'react'
import { Warning } from 'Assets'
import { LayoutState } from 'Components/LayoutState'
import { Button } from 'Components/Button'

interface Props {
  onBack(): void
}

const CnpjNotAllowed = ({ onBack }: Props): JSX.Element => {
  return (
    <LayoutState
      icon={Warning}
      title="No momento, o Empréstimo Stone não está liberado para esse CNPJ"
      subtitle={
        <>
          Identificamos que o CNPJ informado tem pendências com a Receita
          Federal.
          <br />
          Antes de contratar, entre em contato com o órgão e regularize a
          situação.
        </>
      }
      button={
        <Button onClick={onBack} data-testid="go-back-button">
          Voltar ao início
        </Button>
      }
    />
  )
}

export default CnpjNotAllowed
