import styled from 'styled-components'

export const Wrapper = styled.section`
  text-align: center;
  max-width: 1200px;

  * {
    font-family: CircularStd;
  }
`
