import { ITheme, createTheme } from 'react-data-table-component'
import { Colors } from 'Styles/theme'

export const stoneTheme: ITheme = createTheme('stone', {
  text: {
    primary: Colors.darkblue,
  },
  divider: {
    default: Colors.lightazureishgray,
  },
})
