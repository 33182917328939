import React, { useContext, useEffect, useState } from 'react'

import { ICreditLines, ICurrentUser, IProposal } from 'Interfaces'

import LoanContractDetails from 'BusinessComponent/LoanContractDetails'
import LoanSuccessModal from 'BusinessComponent/LoanSuccessModal'
import { useHistory, useLocation } from 'react-router-dom'

import useSubmitProposal, { IUseSubmitProposal } from 'Hooks/useSubmitProposal'

import usePasswordValidation, {
  IUsePasswordValidation,
} from 'Hooks/usePasswordValidation'
import usePinCodeValidation, {
  IUsePinCodeValidation,
} from 'Hooks/usePinCodeValidation'
import { useFirebase } from 'Hooks/useFirebase'

import NetworkErrorContext from 'Contexts/NetworkErrorContext'
import { LoanDetailsContext, StoreContext } from 'Contexts/LoanDetailsContext'
import UserContext from 'Contexts/UserContext'
import { pushGtmEvent } from 'Services/gtm'
import * as AppcuesService from 'Services/appcues'
import { LoanSimulationQueryStrings, TrackEvents } from 'Enums'
import Cookies from 'js-cookie'
import CpfConfirm from '../../CpfConfirm'
import PasswordConfirm from '../../PasswordConfirm'
import PinCodeConfirm from '../../PinCodeConfirm'

interface Props {
  document: string
  proposal: IProposal | null
  creditLine: ICreditLines | null
  onCancel(): void
  submitProposalHook?(): IUseSubmitProposal
  passwordValidationHook?(): IUsePasswordValidation
  pinCodeValidationHook?(): IUsePinCodeValidation
}

export default function LoanContractModal({
  document,
  proposal,
  creditLine,
  onCancel,
  submitProposalHook = useSubmitProposal,
  passwordValidationHook = usePasswordValidation,
  pinCodeValidationHook = usePinCodeValidation,
}: Props) {
  const PIN_CODE_FLAG = JSON.parse(Cookies.get('external_tags') ?? '{}')
    .credito_otp_email

  const { updateError } = useContext(NetworkErrorContext)
  const loanDetailsContext = useContext<LoanDetailsContext | null>(StoreContext)

  const userContext = useContext<ICurrentUser | null>(UserContext)
  const [showContract, setShowContract] = useState<boolean>(true)
  const [showSuccessProposalSubmit, setShowSuccessProposalSubmit] = useState<
    boolean
  >(false)
  const [showCpfConfirm, setShowCpfConfirm] = useState<boolean>(false)
  const [showPasswordConfirm, setShowPasswordConfirm] = useState<boolean>(false)
  const [showPinCodeConfirm, setShowPinCodeConfirm] = useState<boolean>(false)
  const [hasPinCodeError, setHasPinCodeError] = useState<boolean>(false)
  const { search } = useLocation()
  const history = useHistory()
  const firebase = useFirebase()

  const { handleSubmit, proposalSubmission, error } = submitProposalHook()

  const query = new URLSearchParams(search)

  function updateURLParams() {
    if (proposal) {
      query.set(
        LoanSimulationQueryStrings.AMOUNT,
        String(proposal.creditAmount)
      )
      history.push({ search: query.toString() })
    }
    if (userContext && userContext.isPersonified) {
      query.set(LoanSimulationQueryStrings.ASSISTED_SALE, 'true')
      history.push({ search: query.toString() })
    }
  }

  useEffect(updateURLParams, [proposal])

  function sendEvent() {
    const params = {
      totalCreditLimit: creditLine?.availableCreditLimit,
      proposalAmmount: proposal?.creditAmount,
    }

    pushGtmEvent(TrackEvents.PROPOSAL_REQUESTED, params)

    AppcuesService.trackEvent(TrackEvents.PROPOSAL_REQUESTED, params)
  }

  useEffect(sendEvent, [])

  useEffect(() => {
    updateError(error)
  }, [error, updateError])

  function handleClose() {
    query.delete(LoanSimulationQueryStrings.AMOUNT)
    history.push({ search: query.toString() })
    setShowContract(false)
    setShowSuccessProposalSubmit(false)
    onCancel()
  }

  function handleConfirm() {
    pushGtmEvent(TrackEvents.PROPOSAL_CONFIRMED)
    AppcuesService.trackEvent(TrackEvents.PROPOSAL_CONFIRMED)
    setShowContract(false)
    setShowCpfConfirm(true)
  }

  function handleCpfConfirm() {
    setShowCpfConfirm(false)
    setShowPasswordConfirm(true)
    setShowPinCodeConfirm(true)
  }

  function onPinCodeError(hasError: boolean) {
    if (hasError) {
      setHasPinCodeError(true)
    } else {
      setShowPinCodeConfirm(false)
    }
  }

  function onValidatedPassword(pinCode?: string, challengeId?: string) {
    setShowPasswordConfirm(false)

    if (proposal) {
      handleSubmit(
        document,
        proposal.id,
        () => setShowSuccessProposalSubmit(true),
        onPinCodeError,
        pinCode,
        challengeId
      )
    }
  }

  function openDetails() {
    if (loanDetailsContext && proposalSubmission) {
      loanDetailsContext.launchLoanDetails(proposalSubmission.id)
    }
    handleClose()
  }

  return (
    <>
      {firebase.faqLockList && (
        <LoanContractDetails
          faqItems={firebase.faqLockList}
          show={showContract}
          proposal={proposal}
          handleConfirm={handleConfirm}
          handleClose={handleClose}
        />
      )}

      <CpfConfirm
        show={showCpfConfirm}
        subtitle="Informe seu CPF para continuar a contratação do Empréstimo."
        onSuccess={handleCpfConfirm}
        onCancel={handleClose}
      />

      {PIN_CODE_FLAG ? (
        <PinCodeConfirm
          pinCodeValidationHook={pinCodeValidationHook}
          hasPinCodeError={hasPinCodeError}
          show={showPinCodeConfirm}
          onSuccess={onValidatedPassword}
          onCancel={handleClose}
          onClearError={() => setHasPinCodeError(false)}
        />
      ) : (
        <PasswordConfirm
          passwordValidationHook={passwordValidationHook}
          show={showPasswordConfirm}
          onSuccess={onValidatedPassword}
          onCancel={handleClose}
        />
      )}

      {showSuccessProposalSubmit && proposalSubmission && (
        <LoanSuccessModal
          proposalSubmission={proposalSubmission}
          handleConfirm={openDetails}
        />
      )}
    </>
  )
}
