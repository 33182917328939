import React from 'react'
import Card from 'Components/Card'
import Text from 'Components/Text'

import { Container } from 'Components/LayoutState/Internals/BaseState/styles'
import { Colors } from 'Styles/theme'

interface Props {
  icon: string
  title: string
  subtitle: string | JSX.Element
  button?: JSX.Element
  children?: JSX.Element
}

interface Props {
  noCard?: boolean
}

const CardWrapper: React.FC = ({ children }) => {
  return <Card>{children}</Card>
}

const StateElement: React.FC<Props> = ({
  children,
  icon,
  subtitle,
  title,
  button,
}) => {
  return (
    <Container>
      <div className="area-image">
        <img src={icon} alt="Icone do estado" />
      </div>

      <Text bold size="24px" color={Colors.darkblue}>
        {title}
      </Text>

      <div className="subtitle-area">
        <Text size="16px" color={Colors.darkgrey2}>
          {subtitle}
        </Text>
      </div>

      {children}

      {button && <div className="area-button">{button}</div>}
    </Container>
  )
}

export default function LayoutState({ noCard, ...props }: Props) {
  if (noCard) {
    return <StateElement {...props} />
  }

  return (
    <CardWrapper>
      <StateElement {...props} />
    </CardWrapper>
  )
}
