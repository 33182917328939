import React from 'react'
import { PersonWarning } from 'Assets'
import LayoutState from 'Components/LayoutState/Internals/BaseState'
import { NormalText } from './styles'

const HasNotPermission: React.FC = () => {
  return (
    <LayoutState
      icon={PersonWarning}
      title="Você precisa atualizar seus dados bancários"
      subtitle={
        <>
          Identificamos que a conta bancária cadastrada é do tipo poupança, e
          para essa <br />
          operação, precisamos que seja do tipo conta corrente. Para prosseguir,
          atualize <br /> seus dados bancários por meio do nosso Atendimento.
        </>
      }
    >
      <>
        <NormalText>
          <span>Canais de atendimento</span>
          <br />
          <span>3004 9680</span> Regiões metropolitanas <br />
          <span>0800 326 0506</span> Demais regiões <br />
          <a href="mailto:meajuda@stone.com.br" target="blank">
            <span>meajuda@stone.com.br</span>
          </a>
        </NormalText>
      </>
    </LayoutState>
  )
}

export default HasNotPermission
