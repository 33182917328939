import { useState } from 'react'

import IdentityModel from 'Models/Identity'

export interface IVerify {
  email: string
  document: string
}

export interface IUseLoanReturn {
  isLoading: boolean
  error: boolean
  verify({ email, document }: IVerify): void
}

export default function useIdentityVerify(): IUseLoanReturn {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)

  const verify = async ({ email, document }: IVerify) => {
    setIsLoading(true)
    setError(false)

    try {
      await IdentityModel().verify({
        email,
        document,
      })

      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      setError(true)
    }
  }

  return {
    isLoading,
    error,
    verify,
  }
}
