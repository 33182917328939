import { AxiosError, CancelTokenSource } from 'axios'
import { appConfig } from 'Config'
import Cookie from 'js-cookie'
import ValidatePinCodeModel from 'Models/ValidatePinCode'
import { useCallback, useEffect, useRef, useState } from 'react'
import { pushGtmEvent } from 'Services/gtm'
import { logout } from 'Services/base.auth'
import canDo from 'Utils/canDo'
import { TrackEvents } from 'Enums'
import * as AppcuesService from 'Services/appcues'

export interface IChallengeRequestResponse {
  challengeId: string
}

export interface IUsePinCodeValidation {
  challengeId: string
  challengeRequest(): void
  attempts: number
  isLoading: boolean
  error: boolean
}

export default function usePinCodeValidation(): IUsePinCodeValidation {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)
  const [attempts, setAttempts] = useState<number>(3)
  const [challengeId, setChallengeId] = useState<string>('')

  const sourceRequest = useRef<CancelTokenSource | null>(null)
  const canDoIt = useRef<boolean>(true)

  async function handleLogout() {
    try {
      await logout()

      Cookie.remove('external_token', {
        path: '/',
        domain: `.${appConfig.portalURL}`,
      })

      window.location.assign(`${appConfig.portalURL}/logout`)
    } catch (err) {
      setError(true)
    }
  }

  const handleRequestError = useCallback((err: AxiosError) => {
    const errorCatched = err as AxiosError

    if (errorCatched.response) {
      const { status } = errorCatched.response

      switch (status) {
        case 401:
          canDo(() => {
            setAttempts(oldAttempts => {
              pushGtmEvent(TrackEvents.PROPOSAL_PASSWORD_WRONG)
              AppcuesService.trackEvent(TrackEvents.PROPOSAL_PASSWORD_WRONG)

              if (oldAttempts === 1) {
                handleLogout()
              }
              return oldAttempts - 1
            })
          })(canDoIt.current)
          break

        default:
          setError(true)
          break
      }
    } else {
      setError(true)
    }
  }, [])

  const challengeRequest = useCallback(async () => {
    setIsLoading(true)

    try {
      const response = await ValidatePinCodeModel().doChallenge()
      if (response.data) {
        setChallengeId(response.data.id)
      }
    } catch (err) {
      handleRequestError(err)
    }
    canDo(() => setIsLoading(false))(canDoIt.current)
  }, [handleRequestError])

  useEffect(() => {
    const { current } = sourceRequest

    return () => {
      canDoIt.current = false
      if (current) {
        current.cancel('pin code validation was cancelled manually')
      }
    }
  }, [sourceRequest, canDoIt])

  return { challengeId, challengeRequest, attempts, isLoading, error }
}
