import React from 'react'
import { hasTags } from '../../../../../Services/base.auth'
import { appConfig } from '../../../../../Config'

export default function Banking() {
  if (hasTags('banking')) {
    const bankingURL = `${appConfig.portalURL}/conta`

    return (
      <li className="menu__item">
        <a className="menu__handle" href={bankingURL}>
          Conta
        </a>
      </li>
    )
  }
  return <></>
}
