import React from 'react'
import PinField from 'react-pin-field'

import { Wrapper } from './styles'

export interface Props {
  cases?: number
  hasPinCodeError?: boolean
  onChange(value: string): void
}

export default function PinCodeInput({
  cases = 6,
  hasPinCodeError,
  onChange,
}: Props) {
  const ref = React.useRef<HTMLInputElement[]>([])
  return (
    <Wrapper>
      <PinField
        className={`${
          hasPinCodeError ? 'input-pincode__error' : 'input-pincode'
        }`}
        length={cases}
        ref={ref}
        onChange={onChange}
      />
    </Wrapper>
  )
}
