import React, { useState } from 'react'
import ArrowRight from '../../../../Assets/arrow-right.svg'

import { Container } from './styles'
import Touchable from '../../../Touchable'
import Text from '../../../Text'

interface IPaginationProps {
  rowsPerPage: number
  rowCount: number
  currentPage: number
  onChangePage(page: number): void
  numberDisplayPages?: number
}

function Pagination({
  rowCount,
  rowsPerPage,
  currentPage,
  onChangePage,
  numberDisplayPages = 5,
}: IPaginationProps) {
  const [currentGroup, setCurrentGroup] = useState<number>(0)

  const ONE_PAGE = 1

  const pageNumber = Math.ceil(rowCount / rowsPerPage)
  const hasMorePages = Math.ceil(pageNumber / numberDisplayPages) > ONE_PAGE

  function getArrayOfPages(): number[] {
    const arrayOfPages: number[] = []
    const startGroup = currentGroup * numberDisplayPages
    const limitRange = startGroup + numberDisplayPages
    const limitPage = limitRange > pageNumber ? pageNumber : limitRange
    let index = startGroup

    for (index; index < limitPage; index += 1) {
      arrayOfPages.push(index + 1)
    }

    return arrayOfPages
  }

  function handleAddGroup() {
    const limitQuantityGroup = Math.ceil(pageNumber / numberDisplayPages) - 1
    const newGroup =
      currentGroup >= limitQuantityGroup ? limitQuantityGroup : currentGroup + 1

    setCurrentGroup(newGroup)
  }

  return (
    <Container>
      <Text size="14px">Página</Text>

      {getArrayOfPages().map(item => (
        <Touchable
          className="page"
          key={`t${item}`}
          onClick={() => onChangePage(item)}
          style={{ padding: '5px 0' }}
          pressed={item === currentPage}
        >
          <Text size="14px">{item}</Text>
        </Touchable>
      ))}

      {hasMorePages && (
        <Touchable
          className="touchable-more-pages"
          onClick={handleAddGroup}
          style={{ display: 'flex', padding: '5px 10px 5px 0' }}
        >
          <Text size="14px">Mais páginas</Text>
          <img src={ArrowRight} alt="Seta para a direita" />
        </Touchable>
      )}
    </Container>
  )
}

export default Pagination
