import React from 'react'
import { IDataTableColumn } from 'react-data-table-component'
import { ArrowRightLight } from 'Assets'
import ProgressBar from 'Components/ProgressBar'
import { convertStatusToLang, number2price, utcFormatDate } from 'Utils'

import { ILoan } from 'Interfaces'
import { manageValues } from './progressbar'

import { AreaImage, Title } from '../styles'

export const DataTableTitle = (
  <Title bold size="24px">
    Gestão de empréstimos
  </Title>
)

export const columns: IDataTableColumn<ILoan>[] = [
  {
    name: 'Data de contratação',
    selector: 'creationDate',
    cell: row => (
      <>{row.creationDate ? utcFormatDate(row.creationDate) : '-'}</>
    ),
  },
  {
    name: 'Valor do empréstimo',
    selector: 'creditAmount',
    cell: row => <>{number2price(row.creditAmount)}</>,
  },
  {
    name: 'Valor quitado',
    selector: 'totalPaidAmount',
    cell: row => <>{number2price(row.totalPaidAmount)}</>,
  },
  {
    name: 'Valor a pagar',
    selector: 'currentDebtAmount',
    cell: row => <>{number2price(row.currentDebtAmount)}</>,
  },
  {
    name: 'Acompanhamento',
    selector: 'accompaniment',
    cell: row => {
      const { currentValue, description } = manageValues(row)
      const totalDebit = row.currentDebtAmount + row.totalPaidAmount
      return (
        <ProgressBar
          currentValue={currentValue}
          totalValue={totalDebit}
          description
          descriptionText={description}
        />
      )
    },
  },
  {
    name: 'Status',
    selector: 'status',
    cell: row => {
      const status = convertStatusToLang(row.status)

      return (
        <AreaImage title={status}>
          {status}
          <img
            style={{ position: 'absolute', right: 25 }}
            src={ArrowRightLight}
            alt="Seta para a direita"
          />
        </AreaImage>
      )
    },
  },
]
