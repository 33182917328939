import React from 'react'

import { SButton } from './styles'

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  color?: string
  disabled?: boolean
}

export default function Button({ children, color, ...props }: Props) {
  return (
    <SButton color={color} {...props}>
      <span>{children}</span>
    </SButton>
  )
}
