import axios from 'axios'
import Cookies from 'js-cookie'
import { appConfig } from '../Config'
import { removeCredentialsAndData } from './auth.service'

export const token = Cookies.get('external_token')
const PORTAL_API = appConfig.portalApiURL
const baseUrl = `${PORTAL_API}/v1`

const api = axios.create({
  baseURL: PORTAL_API,
  headers: {
    authorization: `Bearer ${token}`,
  },
})

export const customerApi = axios.create({
  baseURL: appConfig.customerApi,
  headers: {
    authorization: `Bearer ${token}`,
  },
})

export const logout = () => {
  return api.post(`/authenticate/invalidateToken`, { token }).then(response => {
    removeCredentialsAndData()
    return response
  })
}

export const currentUser = async () =>
  fetch(`${appConfig.customerApi}/v0/users/current`, {
    method: 'GET',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
    }),
  })

export const splitOnCommas = str => (str ? str.split(',') : [])

export const selectedStoneCodes = splitOnCommas(
  Cookies.get('external_stone_codes')
)

export const stoneCodeCollection = splitOnCommas(
  Cookies.get('external_stone_code_collection')
)

export const hasStoneCode = () => selectedStoneCodes.length > 0

export const permissions = () => {
  const permissionsCollection = Cookies.get('external_permission_collection')

  if (!permissionsCollection) return []

  return JSON.parse(permissionsCollection)
}

export const checkPermission = permission => {
  return () => {
    const permissionsCopy = permissions()
    return permissionsCopy && permissionsCopy.indexOf(permission) >= 0
  }
}

export const fetchCompanyInfo = () => {
  return fetch(`${baseUrl}/configurations/company-info`, {
    method: 'GET',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      merchantIds: selectedStoneCodes[0],
    }),
  })
}

export const hasTags = tag => {
  const tags = Cookies.get('external_tags')

  return tags ? Boolean(JSON.parse(tags)[tag]) : false
}

export default api
