import styled from 'styled-components'
import { Colors } from 'Styles/theme'

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`

export const Image = styled.img`
  margin: 10px 15px 15px 0px;
`

export const Label = styled.label`
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: ${Colors.darkblue};

  strong {
    font-weight: 700;
  }
`

export const Title = styled.label`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${Colors.darkblue};
`
