import { Dispatch, SetStateAction, useState } from 'react'
import { TabEnum, TabFullScreenEnum } from 'Enums'

export interface IUseTabsReturn {
  tabSelected: TabEnum
  setTabSelected: Dispatch<SetStateAction<TabEnum>>
  tabFullScreenSelected: TabFullScreenEnum
  setTabFullScreenSelected: Dispatch<SetStateAction<TabFullScreenEnum>>
}

export function useTabs(): IUseTabsReturn {
  const [tabSelected, setTabSelected] = useState<TabEnum>(TabEnum.SIMULATION)
  const [tabFullScreenSelected, setTabFullScreenSelected] = useState<
    TabFullScreenEnum
  >(TabFullScreenEnum.NONE)

  return {
    tabSelected,
    setTabSelected,
    tabFullScreenSelected,
    setTabFullScreenSelected,
  }
}
