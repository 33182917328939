import React from 'react'

import Jumbotron from 'Components/Jumbotron'
import { Button } from 'Components/Button'
import { NewStoneIllustration } from 'Assets'

import { Column, CustomGrid, Text } from './styles'

export default function WarningNewStone() {
  return (
    <Jumbotron style={{ padding: 0 }} data-testid="warning-new-stone">
      <CustomGrid>
        <Column>
          <Text style={{ fontWeight: 'bold' }}>A Stone está de cara nova!</Text>
          <Text>
            Acesse a nova Stone para concluir seu pedido de empréstimo. Use o
            mesmo e-mail e a mesma senha do Portal para acessar.
          </Text>

          <a rel="noreferrer" href="https://conta.stone.com.br" target="_blank">
            <Button style={{ marginTop: '1rem' }}>Acessar a nova Stone</Button>
          </a>
        </Column>

        <img src={NewStoneIllustration} alt="new stone illustration" />
      </CustomGrid>

      <Column>
        <Text style={{ fontSize: '9pt' }}>
          O Portal vai ser desabilitado em breve, mas seus empréstimos continuam
          os mesmos.
        </Text>
      </Column>
    </Jumbotron>
  )
}
