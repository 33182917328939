import styled from 'styled-components'

export const Container = styled.div`
  * {
    font-family: 'CircularStd';
  }

  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  margin-top: 48px;
  margin-bottom: 48px;

  div.area-image {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 26px;
  }

  p {
    width: 100%;
    text-align: center;
    margin-bottom: 19px;
  }

  div.area-button {
    width: 361px;
    margin-top: 25px;

    button.old-version {
      border-color: #0a8137;
      background: #0db14b;
      color: #fff;
      border-radius: 4px;
      display: block;
      margin: 0 auto;
      -webkit-font-smoothing: antialiased;
      font-weight: 600;
      font-size: 0.85rem;
      white-space: nowrap;
      line-height: 1;
      border-width: 1px;
      text-decoration: none;
      cursor: pointer;
      user-select: none;
      padding: 0.75em 1.5em;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);

      &:hover {
        background: linear-gradient(#0ebf51, #0c9e43);
      }
    }
  }
`
