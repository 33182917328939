import styled from 'styled-components'
import { Colors } from 'Styles/theme'

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const Label = styled.label`
  margin: 4px 0px;
  line-height: 18px;
  color: ${Colors.gray3};
  font-size: 14px;
  font-weight: 450;
`

// @ts-ignore
export const SecondaryLabel = styled(Label)`
  margin-top: 12px;
  font-weight: 500;
`

export const SeeExtract = styled(Label)`
  cursor: pointer;
  font-weight: 500;
  color: ${Colors.darkgreen};
`

export const Highlight = styled.span`
  color: ${Colors.lightgreen};
`

export const Currency = styled.label<{ color?: string }>`
  line-height: 30px;
  font-size: 24px;
  font-weight: 700;

  color: ${({ color }) => color || Colors.darkazureishgray};
`

export const OutlineButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: ${Colors.darkgreen};
  font-size: 14px;
  font-weight: 500;

  &:focus {
    outline: none;
  }
`
