import React from 'react'
import { TimerBallon } from 'Assets'
import { LayoutState } from 'Components/LayoutState'
import { Button } from 'Components/Button'

interface Props {
  onCreateAlert(): void
}

const LoanNotAllowed = ({ onCreateAlert }: Props): JSX.Element => {
  return (
    <LayoutState
      icon={TimerBallon}
      title="No momento, o Empréstimo Stone não está liberado para contratação"
      subtitle="Continue usando sua maquininha Stone e nós informaremos assim que estiver disponível."
      button={
        <Button data-testid="create-alert" onClick={onCreateAlert}>
          Criar aviso
        </Button>
      }
    />
  )
}

export default LoanNotAllowed
