import React, { useContext, useEffect, useState } from 'react'
import { ICreditLines, IProposal } from 'Interfaces'
import { removeSpecialCharacterFrom } from 'Utils/document'
import { ErrorMapperEnum } from 'Enums/proposal'
import useProposal from 'Hooks/useProposal'
import useSubmitProposal, { IUseSubmitProposal } from 'Hooks/useSubmitProposal'
import { useHistory, useLocation } from 'react-router-dom'
import usePasswordValidation, {
  IUsePasswordValidation,
} from 'Hooks/usePasswordValidation'
import { hasAlertCreated } from 'Services/firebase'
import GeneralError from 'Components/LayoutState/Internals/GeneralError'
import NetworkErrorContext from 'Contexts/NetworkErrorContext'
import { CreditLinesStoreContext } from 'Contexts/CreditLineContext'
import { LoanSimulationQueryStrings } from 'Enums'
import CardWithAlert from './internals/CardWithAlert'
import LoanContractModal from './internals/LoanContractModal'

import { Container } from './styles'

interface Props {
  document: string
  onCreditLine?(creditLine: ICreditLines[]): void
  proposalHook?(
    document: string,
    creditAmount: number,
    creditLineId: string
  ): [IProposal | null, boolean, ErrorMapperEnum]
  submitProposalHook?(): IUseSubmitProposal
  passwordValidationHook?(): IUsePasswordValidation
  firebaseHasAlertCreated?(
    document: string,
    stoneCode: string,
    email: string
  ): boolean
}

function getCurrentCreditLine(
  searchCreditLineId: string | null,
  creditLines: ICreditLines[]
): ICreditLines {
  const [firstCreditLine] = creditLines

  if (searchCreditLineId) {
    const currentOption = creditLines.find(
      option => option.id === searchCreditLineId
    )
    return currentOption || firstCreditLine
  }

  return firstCreditLine
}

export default function Simulation({
  document,
  onCreditLine,
  proposalHook = useProposal,
  submitProposalHook = useSubmitProposal,
  passwordValidationHook = usePasswordValidation,
  firebaseHasAlertCreated = hasAlertCreated,
}: Props) {
  const { search } = useLocation()
  const history = useHistory()

  const query = new URLSearchParams(search)
  const searchCreditLineId = query.get(
    LoanSimulationQueryStrings.CREDIT_LINE_ID
  )

  const { creditLines, isLoading, error } = useContext(CreditLinesStoreContext)
  const creditLine = getCurrentCreditLine(searchCreditLineId, creditLines)
  const [proposal, setProposal] = useState<IProposal | null>(null)
  const [show, setShow] = useState<boolean>(false)
  const { error: networkError, updateError } = useContext(NetworkErrorContext)

  function updateURLParams() {
    if (creditLine?.id) {
      query.set(LoanSimulationQueryStrings.CREDIT_LINE_ID, creditLine.id)
      history.push({ pathname: '/', search: query.toString() })
    } else if (searchCreditLineId) {
      query.delete(LoanSimulationQueryStrings.CREDIT_LINE_ID)
      history.push({ pathname: '/', search: query.toString() })
    }
  }

  useEffect(updateURLParams, [creditLine])

  useEffect(() => {
    updateError(false)
  }, [document, updateError])

  useEffect(() => {
    if (networkError) {
      setShow(false)
    }
  }, [networkError])

  useEffect(() => {
    if (onCreditLine) {
      onCreditLine(creditLines)
    }
  }, [creditLines, onCreditLine])

  function handleCancel() {
    setProposal(null)
    setShow(false)
  }

  function handleSimulation(proposalSimulation: IProposal | null): void {
    setProposal(proposalSimulation)
    setShow(true)
  }

  if (error || networkError) {
    return <GeneralError onRetry={() => window.location.reload()} />
  }

  return (
    <Container>
      <CardWithAlert
        loading={isLoading}
        creditLine={creditLine}
        currentDocument={document}
        onSimulate={handleSimulation}
        firebaseHasAlertCreated={firebaseHasAlertCreated}
        proposalHook={proposalHook}
      />

      {show && (
        <LoanContractModal
          passwordValidationHook={passwordValidationHook}
          submitProposalHook={submitProposalHook}
          document={removeSpecialCharacterFrom(document)}
          onCancel={handleCancel}
          proposal={proposal}
          creditLine={creditLine}
        />
      )}
    </Container>
  )
}
