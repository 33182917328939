import React from 'react'
import { hasStoneCode, hasTags } from 'Services/base.auth'
import { appConfig } from 'Config'

export default function Gps() {
  if (hasStoneCode() && hasTags('gps_recebedor')) {
    const gpsURL = `${appConfig.portalURL}/gps/conciliacao`
    return (
      <li className="menu__item">
        <a className="menu__handle" href={gpsURL}>
          GPS
        </a>
      </li>
    )
  }
  return <></>
}
