import styled from 'styled-components'
import { darken } from 'polished'
import { Colors } from 'Styles/theme'

interface Props {
  color?: string
}

const setPropsColor = (
  props: Props,
  colorFallback: string = Colors.darkgreen
) => props.color ?? colorFallback

export const SButton = styled.button`
  width: 100%;
  height: 48px;
  background-color: ${setPropsColor};
  border-radius: 4px;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease;
  outline: none;

  &:hover {
    background-color: ${props => darken(0.05, setPropsColor(props))};
  }

  &:active {
    background-color: ${props => darken(0.1, setPropsColor(props))};
  }

  &:disabled {
    cursor: default;
    background-color: ${Colors.lightblue};
  }

  span {
    display: block;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #ffffff;
  }
`
