import React, { useCallback, useContext, useEffect, useState } from 'react'
import { ITabs, Tabs } from 'Components/Tabs'

import { LoanDetailsContext, StoreContext } from 'Contexts/LoanDetailsContext'
import TabSelectedContext from 'Contexts/TabSelectedContext'
import { TabEnum } from 'Enums'
import { Container } from './styles'

interface Props {
  title: string
  subtitle: string
  hideHistory?: boolean
  hideSimulation?: boolean
}

const listTabs: ITabs[] = [
  { name: 'Simular Novo', id: 'new', dataTestId: 'tab-newsimulation' },
  { name: 'Seus Empréstimos', id: 'history', dataTestId: 'tab-history' },
]

export default function SubHeader({
  title,
  subtitle,
  hideHistory = true,
  hideSimulation = false,
}: Props) {
  const [tabs, setTabs] = useState<ITabs[]>(listTabs)
  const { tabSelected, setTabSelected } = useContext(TabSelectedContext)

  const loanDetailsContext = useContext<LoanDetailsContext | null>(StoreContext)

  const changeSelectedTab = () => loanDetailsContext?.handleClose()

  useEffect(changeSelectedTab, [tabSelected])

  const validateTabs = useCallback(() => {
    const draftListTabs = [...listTabs]

    draftListTabs[TabEnum.YOUR_LOANS].hidden = hideHistory
    draftListTabs[TabEnum.SIMULATION].hidden = hideSimulation

    setTabs(draftListTabs)
  }, [hideHistory, hideSimulation])

  useEffect(() => {
    validateTabs()
  }, [validateTabs])

  return (
    <Container>
      <div className="subheader--content">
        <section>
          <h1>{title}</h1>
          <h2>{subtitle}</h2>
        </section>
        <div>
          <Tabs tabs={tabs} selected={tabSelected} onSelect={setTabSelected} />
        </div>
      </div>
    </Container>
  )
}
