import { AxiosRequestConfig } from 'axios'
import Cookies from 'js-cookie'
import api from 'Services/base.auth'
import { IPasswordValidation } from 'Interfaces'

export interface IValidatePassword {
  axiosConfig?: AxiosRequestConfig
  password: string
}
export default async function validatePassword(
  props: IValidatePassword
): Promise<IPasswordValidation> {
  const token = Cookies.get('external_token')
  const { axiosConfig, password } = props

  const response = await api.post(`/authenticate/validatePassword`, {
    ...axiosConfig,
    token,
    password,
  })

  const { data }: { data: IPasswordValidation } = response

  return data
}
