import axios, { CancelTokenSource } from 'axios'
import { IChallengeRequest } from 'Interfaces'

import doChallenge from './getChallenge'

export interface IValidatePinCodeProps {
  getCancelTokenSource(source: CancelTokenSource): void
  challengeId: string
  code: string
}

export interface IValidatePinCodeModel {
  doChallenge(): Promise<IChallengeRequest>
}

export default (): IValidatePinCodeModel => {
  return {
    doChallenge: () => {
      const source = axios.CancelToken.source()

      const request = doChallenge({
        axiosConfig: {
          cancelToken: source.token,
        },
      })

      return request
    },
  }
}
