import React, { useState } from 'react'

import { cpf } from 'cpf-cnpj-validator'

import { Close } from 'Assets'

import CpfInput from 'Components/CpfInput'

import Loader from 'Components/Loader'

import { Button } from 'Components/Button'

import { Body, CloseButton, Header, SModal, Subtitle, Title } from './styles'

interface Props {
  show: boolean
  isLoading?: boolean
  subtitle?: string
  onConfirm(document: string): void
  onCancel(): void
}

export default function CpfConfirmModal({
  show,
  subtitle,
  onConfirm,
  onCancel,
  isLoading,
}: Props) {
  const [value, setValue] = useState('')
  function handleConfirm() {
    onConfirm(value)
  }

  return (
    <SModal show={show} size="md" onHide={onCancel}>
      <Header>
        <Title>Confirme seu CPF</Title>
        <CloseButton onClick={onCancel} data-testid="close-button">
          <img src={Close} alt="Botão fechar modal" />
        </CloseButton>
      </Header>
      <Body>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
        {isLoading ? (
          <Loader spinner />
        ) : (
          <>
            <CpfInput value={value} onChange={setValue} />
            <Button disabled={!cpf.isValid(value)} onClick={handleConfirm}>
              Continuar
            </Button>
          </>
        )}
      </Body>
    </SModal>
  )
}
