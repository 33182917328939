/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import Contrato from './Link/images/contrato.svg'
import Ajuda from './Link/images/ajuda.svg'
import FaleConosco from './Link/images/fale-conosco.svg'
import {
  Apps,
  Link,
  LinkSection,
  MediaSection,
  PhoneSection,
  Wrapper,
} from './styles'

const Footer: React.FC = () => {
  return (
    <Wrapper>
      <LinkSection>
        <Link href="http://contratos.stone.com.br/docs" target="_blank">
          <img src={Contrato} alt="contrato" />
          <span>Contrato</span>
        </Link>
        <Link href="https://stone.force.com/ajuda/s/" target="_blank">
          <img src={Ajuda} alt="ajuda" />
          <span>Central de Ajuda</span>
        </Link>
        <Link href=" https://www.stone.com.br/suporte-stone/" target="_blank">
          <img src={FaleConosco} alt="fale-conosco" />
          <span>Fale Conosco</span>
        </Link>
      </LinkSection>

      <MediaSection>
        <PhoneSection>
          <h4>Regiões Metropolitanas</h4>
          <span>3004-9680</span>
          <small>Atendimento 24h todos os dias</small>
        </PhoneSection>
        <PhoneSection>
          <h4>Demais Regiões</h4>
          <span>0800-326-0506</span>
        </PhoneSection>
      </MediaSection>
    </Wrapper>
  )
}

export default Footer
