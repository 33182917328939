import React, { useState } from 'react'

import { ArrowRight } from 'Assets'
import {
  AreaDescription,
  Container,
  Content,
  CustomCard,
  CustomIcon,
  Description,
  Title,
} from './styles'

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  title: string
  subtitle: string
  description: string
}

const Default: React.FC<Props> = ({
  title,
  subtitle,
  description,
  className,
}) => {
  const [show, setShow] = useState<boolean>(false)

  return (
    <Container data-testid="accordion-default" className={className}>
      <CustomCard onClick={() => setShow(value => !value)} show={show}>
        <Title data-testid="accordion-title">{title}</Title>
        <AreaDescription>
          <Description data-testid="accordion-subtitle">{subtitle}</Description>
          <CustomIcon src={ArrowRight} alt="Seta para a direita" show={show} />
        </AreaDescription>
      </CustomCard>

      <Content
        className={show ? 'show' : ''}
        data-testid="accordion-description"
      >
        {description}
      </Content>
    </Container>
  )
}

export default Default
