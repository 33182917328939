import React from 'react'
import { CustomAccordion } from 'BusinessComponent/FaqLockModal/styles'
import { IFirebaseFaqLock } from 'Interfaces/firebaseFaqLock.interface'

export interface Props {
  items: IFirebaseFaqLock
}

const FaqLockContent: React.FC<Props> = ({ items }) => {
  return (
    <div className="content">
      {items.questions.map(item => (
        <CustomAccordion
          title="Trava bancária"
          subtitle={item.question}
          description={item.answer}
        />
      ))}
    </div>
  )
}

export default FaqLockContent
