import customerApi, {
  CustomerLoanStatementOptions,
  CustomerMethods,
} from 'Services/customer'
import { token } from 'Helper/cookie'
import { ILoanExtract } from 'Interfaces/loanExtract.interface'
import { IPagination } from 'Interfaces'

export interface IGetStatementModelProps {
  document: string
  borrowerId: string
  loanId: string
  options?: CustomerLoanStatementOptions
}

export interface IGetStatementModelServices {
  customer?(token: string): CustomerMethods
}

export type IGetStatementModelFunction = IGetStatementModelProps &
  IGetStatementModelServices

export async function getStatement({
  document,
  borrowerId,
  loanId,
  options,
  customer = customerApi,
}: IGetStatementModelFunction): Promise<IPagination<ILoanExtract>> {
  const { loanStatements } = customer(token!)
  const statements = await loanStatements(document, borrowerId, loanId, options)

  return statements.data
}

export function getStatementCsv({
  document,
  borrowerId,
  loanId,
  options,
  customer = customerApi,
}: IGetStatementModelFunction) {
  const { loanStatementsCsv } = customer(token!)
  return loanStatementsCsv(document, borrowerId, loanId, options)
}
