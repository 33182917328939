import styled from 'styled-components'

import { Colors } from 'Styles/theme'

interface WrapperProps {
  invalid?: boolean
}

export const Wrapper = styled.div<WrapperProps>`
  position: relative;
  display: flex;
  align-items: center;
  color: ${Colors.darkblue};
  font-size: 0.875rem;
  line-height: 1rem;

  .react-calendar {
    border: 0;
    box-shadow: 0 0 0.625rem rgba(0, 0, 0, 0.15);

    &__tile {
      color: ${Colors.lightblue};

      &--active {
        background: rgba(8, 128, 55, 0.4);
        color: ${Colors.white};
      }

      &--rangeStart,
      &--rangeEnd {
        background: ${Colors.darkgreen} !important;
        color: ${Colors.white} !important;
        border-radius: 0;
      }

      &--rangeStart {
        border-bottom-left-radius: 1.125rem;
        border-top-left-radius: 1.125rem;
      }

      &--rangeEnd {
        border-bottom-right-radius: 1.125rem;
        border-top-right-radius: 1.125rem;
      }

      &--active:enabled:hover,
      &--active:enabled:focus {
        background: ${Colors.darkgreen};
        color: ${Colors.white};
      }

      &--hasActive:enabled:hover,
      &--hasActive:enabled:focus {
        background: rgba(8, 128, 55, 0.4);
        color: ${Colors.white};
      }
    }
  }

  .react-daterange-picker {
    &__wrapper {
      align-items: center;
      border-radius: 0.25rem;
      border: 0.063rem solid ${Colors.gray3};
      height: 49px;
      padding: 0.188rem 0.438rem;
      width: 245px;

      ${props => props.invalid && `border-color: ${Colors.accent2};`}
    }

    &__inputGroup {
      min-width: auto;

      &__input {
        user-select: none;
      }
    }

    &__range-divider {
      align-items: center;
      display: flex;
    }

    &__button {
      height: 24px;
      padding: 0;
      width: 24px;
    }

    &__clear-button {
      display: flex;
      order: -1;
    }

    input {
      color: ${Colors.darkblue};
    }
  }

  .react-daterange-picker__inputGroup__leadingZero {
    display: none;
  }

  .react-calendar__navigation__label {
    font-weight: bold;

    ::first-letter {
      text-transform: uppercase;
    }
  }

  .react-calendar__month-view__weekdays__weekday {
    abbr[title] {
      font-size: 0.75rem;
      line-height: 1rem;
      text-decoration: none;
    }
  }
`

export const Feedback = styled.label`
  left: 0;
  top: 56px;
  width: 245px;
  color: ${Colors.accent2};
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 0.875rem;
  position: absolute;

  @media (max-width: 380px) {
    left: -15px;
  }
`
