import React from 'react'

import { CloseBlack } from 'Assets'
import { ITabs, Tabs } from 'Components/Tabs'
import { TabThemes } from 'Components/Tabs/styles'
import { IFirebaseFaqLock } from 'Interfaces/firebaseFaqLock.interface'
import FaqLockContent from 'BusinessComponent/FaqLockContent'
import { CustomModal } from './styles'

export interface Props {
  show?: boolean
  items: IFirebaseFaqLock
  onCancel(): void
}

const tabs: ITabs[] = [
  {
    id: 'bank-lock',
    name: 'Trava bancária',
  },
]

const FaqLockModal: React.FC<Props> = ({ show, items, onCancel }) => {
  return (
    <CustomModal show={show} onHide={onCancel}>
      <header>
        <div className="area-title">
          <h2>Entenda a trava bancária</h2>
          <img
            src={CloseBlack}
            alt="icone de fechar"
            onClick={onCancel}
            aria-hidden
          />
        </div>

        <div className="area-tabs">
          <Tabs
            tabs={tabs}
            theme={TabThemes.CENTER_SMALL}
            onSelect={() => {}}
          />
        </div>
      </header>

      <FaqLockContent items={items} />
    </CustomModal>
  )
}

export default FaqLockModal
