import styled from 'styled-components'
import { Colors } from '../../Styles/theme'

interface Props {
  pressed?: boolean
}

export const Container = styled.div<Props>`
  display: block;
  border-radius: 2px;
  width: fit-content;
  cursor: pointer;
  transition: background-color 0.1s ease;
  background-color: ${props => (props.pressed ? Colors.gray4 : 'transparent')};

  &:hover {
    background-color: ${Colors.gray4};
  }
`
