import React from 'react'

import LayoutState from 'Components/LayoutState/Internals/BaseState'
import { SandClock } from 'Assets'
import { Button } from 'Components/Button'

interface Props {
  onClickCreateAlert(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void
}

export default function CreditAlertCreate({ onClickCreateAlert }: Props) {
  return (
    <LayoutState
      title="No momento, Empréstimo Stone não liberado pra contratação."
      subtitle="Continue usando sua maquininha Stone e nós informaremos assim que estiver disponível."
      icon={SandClock}
      button={<Button onClick={onClickCreateAlert}>Criar alerta</Button>}
    />
  )
}
