import React, { useCallback, useContext, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useHistory, useLocation } from 'react-router-dom'

import { ExtractFiltersContext } from 'Contexts/ExtractFiltersContext'
import { useTranslation } from 'react-multi-lang'
import GeneralError from 'Components/LayoutState/Internals/GeneralError'
import Header from './Header'
import Summary from './Summary'
import MarginWrapper from './MarginWrapper'
import ExportActions from './ExportActions'
import Divider from './Divider'
import Filters from './Filters'
import ExtractDataTable from './ExtractDataTable'
import ExtractSkeleton from './Skeleton'
import { Container, SideSection } from './styles'

interface Props {}

interface Dispatch {
  onClose(): void
  onRetry(): void
}

export type LoanExtractProps = Props & Dispatch

const LoanExtract: React.FC<LoanExtractProps> = ({ onClose, onRetry }) => {
  const {
    error,
    loanExtract,
    statements,
    handleLoadMore,
    handleDownloadCsv,
  } = useContext(ExtractFiltersContext)

  const history = useHistory()
  const location = useLocation()

  const updateRouter = useCallback(() => {
    history.push(`${location.pathname}/extrato`)
  }, [history, location])

  useEffect(updateRouter, [])

  const t = useTranslation()

  async function onDownloadCsv() {
    const response = await handleDownloadCsv()

    const urlBlob = window.URL.createObjectURL(
      new Blob([response.data], { type: 'text/csv' })
    )

    const link = document.createElement('a')
    const fileName = `${loanExtract?.data.loanId}-${t(
      'statements.statement'
    )}.csv`

    link.href = urlBlob
    link.download = fileName
    link.click()

    window.URL.revokeObjectURL(urlBlob)
    link.remove()
  }

  function handlePrintScreen() {
    window.print()
  }

  const hasDailyStatements = useCallback(() => {
    return Boolean(statements.length)
  }, [statements])

  if (error) {
    return (
      <MarginWrapper style={{ margin: '42px auto' }}>
        <GeneralError onRetry={onRetry} />
      </MarginWrapper>
    )
  }

  if (!loanExtract) {
    return <ExtractSkeleton />
  }

  return (
    <Container>
      <Header loanExtract={loanExtract} onClose={onClose} />
      <MarginWrapper style={{ marginTop: 42 }}>
        <Row>
          <Col md={9}>
            <Summary loanExtract={loanExtract} />
            <Divider />
            <Filters loanExtract={loanExtract} />
            <ExtractDataTable
              loanExtract={loanExtract}
              onLoadMore={handleLoadMore}
            />
          </Col>

          <SideSection md={3}>
            {hasDailyStatements() && (
              <ExportActions
                onDownloadCsv={onDownloadCsv}
                onPrintScreen={handlePrintScreen}
              />
            )}
          </SideSection>
        </Row>
      </MarginWrapper>
    </Container>
  )
}

export default LoanExtract
