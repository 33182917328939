import React from 'react'

import { Container } from './styles'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: JSX.Element | JSX.Element[]
  style?: React.CSSProperties
  pressed?: boolean
  onClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void | undefined
}

export default function Touchable({
  children,
  style,
  pressed,
  onClick,
  ...rest
}: Props) {
  return (
    <Container {...rest} style={style} onClick={onClick} pressed={pressed}>
      {children}
    </Container>
  )
}
