import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  .input-pincode {
    width: 3.75em;
    height: 4.64em;
    background: #ffffff;
    border-radius: 5px;
    border: 2px solid #d6dde4;
    outline: none;
    text-align: center;
    margin: 8px 18px 8px 0;

    &:focus {
      outline: none;
      transform: scale(1.05);
    }
  }

  .input-pincode__error {
    width: 3.75em;
    height: 4.64em;
    background: #ffffff;
    border-radius: 5px;
    border: 2px solid #e74c3c;
    outline: none;
    text-align: center;
    margin: 8px 18px 8px 0;

    &:focus {
      outline: none;
      transform: scale(1.05);
    }
  }
`
