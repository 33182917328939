import Cookies from 'js-cookie'
import axios from 'axios'
import { Roles } from 'Enums'
import { PORTAL_API } from './constants'

export const removeCredentialsAndData = (InjectedCookies = Cookies) => {
  InjectedCookies.remove('tags')
  InjectedCookies.remove('token')
  // partial-lock Cookies
  InjectedCookies.remove('hasPartialLock')
  InjectedCookies.remove('isStoneLock')
  InjectedCookies.remove('documentsWithLock')

  InjectedCookies.remove('current_banking_account_id')
  InjectedCookies.remove('current_banking_owner_id')
  InjectedCookies.remove('email')
  InjectedCookies.remove('stone_codes')
  InjectedCookies.remove('name')
  InjectedCookies.remove('stone_code_collection')
  InjectedCookies.remove('permission_collection')
  InjectedCookies.remove('role')
  InjectedCookies.remove('is_personified')
  InjectedCookies.remove('user_id')
  InjectedCookies.remove('created_at')
  InjectedCookies.remove('external_tags')
  InjectedCookies.remove('external_token')
  InjectedCookies.remove('external_permission_collection')
  InjectedCookies.remove('external_stone_code_collection')
  InjectedCookies.remove('external_role')
  InjectedCookies.remove('external_stone_codes')
  // window.Intercom('shutdown');
  // FS.clearUserCookie();
}

/* eslint new-cap: [2, {"capIsNewExceptions": ["window.Intercom"]}] */
const AuthService = ($http = axios, $q, InjectedCookies = Cookies) => {
  const authUrl = `${PORTAL_API}/authenticate`

  const isClient = () => {
    const role = InjectedCookies.get('external_role')
    return role === Roles.CLIENT
  }

  const isOperator = () => {
    const role = InjectedCookies.get('external_role')
    return role === Roles.OPERATOR
  }

  const isRisk = () => {
    const role = InjectedCookies.get('external_role')
    return role === Roles.RISK
  }

  const isPersonified = () => InjectedCookies.get('is_personified') === 'true'

  const permissions = () => {
    const permissionsCollection = InjectedCookies.get(
      'external_permission_collection'
    )
    return JSON.parse(permissionsCollection)
  }

  const isLoginOk = () =>
    $q((resolve, reject) => {
      const token = window.SSO.success
        ? window.SSO.token || Cookies.get('external_token')
        : Cookies.get('external_token')

      if (!token) {
        return reject()
      }

      return $http
        .post(`${authUrl}/validate`, {
          token,
        })
        .then(
          response => {
            if (response.data.success) {
              return resolve()
            }
            removeCredentialsAndData()
            return reject()
          },
          () => {
            removeCredentialsAndData()
            return reject()
          }
        )
    })

  return {
    isClient,
    isOperator,
    isRisk,
    isPersonified,
    permissions,
    isLoginOk,
  }
}

export default AuthService
