import React from 'react'

import { number2price, numberFormat } from 'Utils'

import { LoanStatusEnum } from 'Enums'

import { getPercentage } from 'Components/ProgressBar/utils'
import ProgressBar from 'Components/ProgressBar'
import { Colors } from 'Styles/theme'

import {
  Column,
  Currency,
  Highlight,
  Label,
  Row,
  SecondaryLabel,
  SeeExtract,
} from './styles'

interface ProgressCard {
  totalPaidAmount: number
  currentDebtAmount: number
  status: LoanStatusEnum
  onShowExtract(): void
}

const hideProgressForStatus = [
  LoanStatusEnum.DISBURSEMENT_REQUESTED,
  LoanStatusEnum.DISBURSEMENT_CONFIRMED,
  LoanStatusEnum.SUSPENDED_NON_DISBURSEMENT,
  LoanStatusEnum.CANCELLED,
]

const FILL_PROGRESS = 100

export default function ProgressCard({
  totalPaidAmount,
  currentDebtAmount,
  status,
  onShowExtract,
}: ProgressCard) {
  const showProgress = !hideProgressForStatus.includes(status)
  const totalDebit = currentDebtAmount + totalPaidAmount

  const progress =
    status === LoanStatusEnum.SETTLED
      ? FILL_PROGRESS
      : getPercentage(totalPaidAmount, totalPaidAmount + currentDebtAmount)

  return (
    <>
      <Row>
        <Column>
          <Label>Valor pago</Label>
          <Currency color={Colors.lightgreen}>
            {number2price(totalPaidAmount)}
          </Currency>
        </Column>
        <Column>
          <Label>Valor a pagar</Label>
          <Currency>{number2price(currentDebtAmount)}</Currency>
        </Column>
        <Column />
        <Column>
          {showProgress && (
            <SeeExtract onClick={onShowExtract}>Ver extrato</SeeExtract>
          )}
        </Column>
      </Row>
      {showProgress && (
        <div data-testid="loan-details-progress-bar">
          <ProgressBar currentValue={totalPaidAmount} totalValue={totalDebit} />
          <SecondaryLabel>
            Você já quitou <Highlight>{numberFormat(progress)}%</Highlight> do
            seu empréstimo.
          </SecondaryLabel>
        </div>
      )}
    </>
  )
}
