import { BarCode, Clock, Money } from 'Assets/SimulationCard'
import React from 'react'

import {
  Feature,
  FeatureContent,
  ImageWrapper,
  Subtitle,
  Wrapper,
} from './styles'

export default function Footer() {
  return (
    <Wrapper>
      <Feature>
        <ImageWrapper>
          <img src={Clock} alt="Ícone de relógio" />
        </ImageWrapper>
        <FeatureContent>
          <Subtitle>Continue antecipando</Subtitle>
          <p>
            Continue gerando fluxo de caixa com suas antecipações normalmente.
          </p>
        </FeatureContent>
      </Feature>
      <Feature>
        <ImageWrapper>
          <img src={Money} alt="Ícone de dinheiro" />
        </ImageWrapper>
        <FeatureContent>
          <Subtitle>Receba em até 5 dias úteis</Subtitle>
          <p>
            Ao contratar o nosso crédito, você recebe o dinheiro o quanto antes
            para começar a aproveitar.
          </p>
        </FeatureContent>
      </Feature>
      <Feature>
        <ImageWrapper>
          <img src={BarCode} alt="Ícone de código de barras" />
        </ImageWrapper>
        <FeatureContent>
          <Subtitle>Sem boletos</Subtitle>
          <p>
            Você paga o empréstimo conforme realiza suas vendas, respeitando
            sempre o seu faturamento.
          </p>
        </FeatureContent>
      </Feature>
    </Wrapper>
  )
}
