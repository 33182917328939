/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { hasStoneCode } from '../../../../../Services/base.auth'
import { appConfig } from '../../../../../Config'

export default function Payments() {
  const baseStatementURL = `${appConfig.portalURL}/extratos`
  const myEarningsURL = `${baseStatementURL}/meus-ganhos/calendario#detalheOperacoes`
  const myEarningsByPeriod = `${baseStatementURL}/meus-ganhos/periodo`
  if (hasStoneCode()) {
    return (
      <li className="menu__item">
        <a className="menu__handle menu__handle--controls" href={myEarningsURL}>
          Pagamentos
        </a>
        <div className="menu__area">
          <div className="menu__target menu__target--sub">
            <div className="menu__area">
              <ul className="menu__list">
                <li className="menu__item">
                  <a className="menu__handle" href={myEarningsURL}>
                    Calendário
                  </a>
                </li>
                <li className="menu__item">
                  <a className="menu__handle" href={myEarningsByPeriod}>
                    Pagamentos por período
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </li>
    )
  }
  return <></>
}
