import React from 'react'

import { Button } from 'Components/Button'
import { Colors } from 'Styles/theme'
import { CancelButton, Wrapper } from './styles'

interface Props {
  showLastAttemptAlert: boolean
  showCancelButton: boolean
  handleConfirm(): void
  handleCancel(): void
}

export default function ActionButtons({
  showLastAttemptAlert,
  showCancelButton,
  handleConfirm,
  handleCancel,
}: Props) {
  return (
    <Wrapper>
      {showCancelButton && (
        <CancelButton color={Colors.lightblue} onClick={handleCancel}>
          Cancelar
        </CancelButton>
      )}
      <Button onClick={handleConfirm} data-testid="confirm-button">
        {showLastAttemptAlert ? 'Continuar' : 'Confirmar'}
      </Button>
    </Wrapper>
  )
}
