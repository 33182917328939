export function splitDateTime(isoDate: string) {
  // "2019-10-24T19:15:51"
  const dateTime = isoDate.split('T')
  const date = dateTime[0].split('-')
  const time = dateTime[1].split(':')

  return {
    dateTime,
    date,
    time,
  }
}

export function utcFormatDate(isoDate: string): string {
  const { date, time } = splitDateTime(isoDate)

  const nonZeroBased = (value: string): string => {
    return String(parseInt(value, 10) - 1)
  }

  date[1] = nonZeroBased(date[1])

  const params = [...date, ...time]

  // typescript doesnt support spread arguments on date class
  // @ts-ignore
  return new Date(Date.UTC(...params)).toLocaleDateString('pt-BR')
}
