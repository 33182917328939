import { t } from 'react-multi-lang'
import {
  format,
  formatISO,
  isAfter,
  isBefore,
  isSameMonth,
  isSameYear,
} from 'date-fns'

enum DateRepresentation {
  DATE = 'date',
}

interface HumanizeOptions {
  ignoreDays: boolean
}

const defaultHumanizeOptions: HumanizeOptions = {
  ignoreDays: false,
}

export const getMonthDifferential = (days: number) => {
  const diff = Math.floor(days / 30)
  const plural = diff > 1

  if (!diff) {
    return ''
  }

  return `${diff} ${plural ? t('calendar.months') : t('calendar.month')}`
}

export const getDayDifferential = (days: number) => {
  const diff = days % 30
  const plural = diff > 1

  if (!diff) {
    return ''
  }

  return `${diff} ${plural ? t('calendar.days') : t('calendar.day')}`
}

const getSuffix = (type: string, amount: number) => {
  const isPlural = amount > 1
  let suffix = ''

  switch (type) {
    case 'year':
      suffix = isPlural ? t('calendar.years') : t('calendar.year')
      break

    case 'month':
      suffix = isPlural ? t('calendar.months') : t('calendar.month')
      break

    case 'day':
      suffix = isPlural ? t('calendar.days') : t('calendar.day')
      break

    default:
      break
  }

  return suffix
}

export const humanize = (
  days: number,
  options: HumanizeOptions = defaultHumanizeOptions
) => {
  const { ignoreDays } = options
  const tokenSeparator = ', '
  const lastSeparatorRegEx = /, ([^,]*)$/
  const lastItemSeparator = ` ${t('default.and')} $1`
  const tokens = []

  let result = ''
  let years = 0
  let months = 0
  let daysLeft = days

  years = Math.floor(daysLeft / 365)

  if (years) {
    daysLeft -= years * 365
  }

  if (daysLeft) {
    months = Math.floor(daysLeft / 30)
    daysLeft -= Math.ceil(months * 30)
  }

  years && tokens.push(`${years} ${getSuffix('year', years)}`)
  months && tokens.push(`${months} ${getSuffix('month', months)}`)

  if (!ignoreDays) {
    daysLeft && tokens.push(`${daysLeft} ${getSuffix('day', daysLeft)}`)
  }

  result = tokens.join(tokenSeparator)
  result = result.replace(lastSeparatorRegEx, lastItemSeparator)

  return result
}

export const dateToISO = (value: number | Date) => {
  return formatISO(value, { representation: DateRepresentation.DATE })
}

export const formatDate = (date: Date) => {
  return format(date, 'dd/MM/yyyy')
}

export const removeDateHours = (datetime: any) => {
  return datetime && datetime.setHours(0, 0, 0, 0)
}

export const isBelowDate = (min: Date, date: Date) => {
  return isBefore(removeDateHours(date), removeDateHours(new Date(min)))
}

export const isAboveDate = (max: Date, date: Date) => {
  return isAfter(removeDateHours(date), removeDateHours(new Date(max)))
}

export const isBelowMonth = (min: Date, date: Date) => {
  return isBefore(date, min)
}

export const isAboveMonth = (max: Date, date: Date) => {
  return isAfter(date, max)
}

export const isBelowYear = (min: Date, date: Date) => {
  return isBefore(date, min) && !isSameMonth(date, min)
}

export const isAboveYear = (max: Date, date: Date) => {
  return isAfter(date, max) && !isSameMonth(date, max)
}

export const isBelowDecade = (min: Date, date: Date) => {
  return (
    isBefore(date, min) && !isSameMonth(date, min) && !isSameYear(date, min)
  )
}

export const isAboveDecade = (max: Date, date: Date) => {
  return isAfter(date, max) && !isSameMonth(date, max) && !isSameYear(date, max)
}
