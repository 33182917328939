import React, { SyntheticEvent, useContext, useEffect, useState } from 'react'
import { Col } from 'react-bootstrap'

import { CalendarRange } from 'Components/Calendar'
import { ILoanExtract } from 'Interfaces/loanExtract.interface'
import Select, { Option } from 'Components/Select'
import { Colors } from 'Styles/theme'
import { IPagination } from 'Interfaces'
import { ExtractFiltersContext } from 'Contexts/ExtractFiltersContext'
import { Button } from 'Components/Button'
import { Container, InputLabel } from './styles'

export interface onFilterProps {
  dateRange: Date[]
  entryType: string
}
interface FilterProps {
  loanExtract: IPagination<ILoanExtract>
}

const options: Option[] = [
  {
    label: 'Todos',
    value: '',
  },
  {
    label: 'Crédito concedido',
    value: 'Principal',
  },
  {
    label: 'Tarifa de contratação',
    value: 'Fee',
  },
  {
    label: 'Adicional de IOF',
    value: 'AdditionalTax',
  },
  {
    label: 'Parcela de quitação diária',
    value: 'Repayment',
  },
  {
    label: 'Juros diário',
    value: 'Interest',
  },
  {
    label: 'IOF diário',
    value: 'DailyTax',
  },
]

const Filters: React.FC<FilterProps> = ({ loanExtract }) => {
  const { loanCreationDate } = loanExtract.data

  const [dates, setDates] = useState<Date[]>([
    new Date(loanCreationDate ?? ''),
    new Date(),
  ])
  const [type, setType] = useState<string>('')

  const { dateRange, entryType, handleFilter } = useContext(
    ExtractFiltersContext
  )

  useEffect(() => {
    if (dateRange.length) {
      setDates(dateRange)
    }
  }, [dateRange])

  function onCalendarChange(value: Date[]) {
    const [start, end] = value
    setDates([start, end])
  }

  function onCalendarClear(e: SyntheticEvent) {
    e.stopPropagation()
    setDates([new Date(loanCreationDate ?? ''), new Date()])
  }

  function onFilter() {
    handleFilter({
      dateRange: dates,
      entryType: type,
    })
  }

  return (
    <Container>
      <Col sm={12} md={6} lg={4}>
        <InputLabel>Filtrar por período</InputLabel>
        <CalendarRange
          maxDate={new Date()}
          minDate={new Date(loanCreationDate ?? '')}
          onChange={onCalendarChange}
          onClear={onCalendarClear}
          value={dates}
        />
      </Col>

      <Col sm={12} md={6} lg={4}>
        <InputLabel>Tipo de lançamento</InputLabel>
        <Select
          options={options}
          currentValue={entryType}
          onChange={setType}
          height="49px"
        />
      </Col>

      <Col sm={12} md={6} lg={4}>
        <InputLabel>&nbsp;</InputLabel>
        <Button
          className="hide-printing"
          color={Colors.lightgreen}
          onClick={onFilter}
          data-testid="filter-button"
        >
          Filtrar
        </Button>
      </Col>
    </Container>
  )
}

export default Filters
