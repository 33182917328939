import { CancelTokenSource } from 'axios'
import ProposalSubmitionModel from 'Models/ProposalSubmission'
import { useEffect, useRef, useState } from 'react'
import { IProposalSubmission } from 'Interfaces'
import { pushGtmEvent } from 'Services/gtm'
import { TrackEvents } from 'Enums'
import canDo from 'Utils/canDo'
import * as AppcuesService from 'Services/appcues'

export interface IUseSubmitProposal {
  handleSubmit(
    document: string,
    proposalId: string,
    onSuccess: () => void,
    onPinCodeError?: (hasError: boolean) => void,
    pinCode?: string,
    challengeId?: string
  ): void
  proposalSubmission: IProposalSubmission | null
  isLoading: boolean
  error: boolean
}

const PIN_CODE_VALIDATION_ERROR_MESSAGE = 'Could not validate challenge'

export default function useSubmitProposal(): IUseSubmitProposal {
  const [isLoading, setIsLoading] = useState(false)
  const [
    proposalSubmission,
    setProposalSubmission,
  ] = useState<IProposalSubmission | null>(null)
  const [error, setError] = useState<boolean>(false)

  let sourceRequest: CancelTokenSource | null = null
  const canDoIt = useRef(true)

  async function handleSubmit(
    document: string,
    proposalId: string,
    onSuccess: () => void,
    onPinCodeError: (hasError: boolean) => void,
    pinCode?: string,
    challengeId?: string
  ) {
    const getCancelTokenSource = (source: CancelTokenSource) => {
      sourceRequest = source
    }
    const proposalSubmitionModel = ProposalSubmitionModel()

    setIsLoading(true)

    try {
      const response = await proposalSubmitionModel.proposalSubmition({
        getCancelTokenSource,
        document,
        proposalId,
        pinCode,
        challengeId,
      })
      setProposalSubmission(response)
      pushGtmEvent(TrackEvents.PROPOSAL_ACCEPTED)
      AppcuesService.trackEvent(TrackEvents.PROPOSAL_ACCEPTED)
      onPinCodeError(false)
      onSuccess()
    } catch (err) {
      if (err.response.data.messages[0] === PIN_CODE_VALIDATION_ERROR_MESSAGE) {
        onPinCodeError(true)
      } else {
        setError(true)
      }
    }
    canDo(() => setIsLoading(false))(canDoIt.current)
  }

  useEffect(() => {
    return () => {
      canDoIt.current = false
      if (sourceRequest) {
        sourceRequest.cancel('password validation was cancelled manually')
      }
    }
  }, [sourceRequest, canDoIt])

  return { handleSubmit, proposalSubmission, isLoading, error }
}
