import styled from 'styled-components'
import { Colors } from 'Styles/theme'

export const CreditProposal = styled.div`
  margin: 54px auto 10px;
  max-width: 360px;
  width: 100%;
`
export const MoneyValue = styled.span`
  font-size: 1em;
  font-weight: 700;
`
export const Button = styled.button`
  font-family: CircularStd;
  background: ${Colors.darkgreen};
  border: none;
  border-radius: 4px;
  max-width: 361px;
  width: 100%;
  height: 53px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
  cursor: pointer;
`
