import styled from 'styled-components'
import { Colors } from 'Styles/theme'

export const Label = styled.div`
  font-style: normal;
  font-size: 14px;
  margin: 3px;
  letter-spacing: 0.4px;
  color: ${Colors.darkblue};

  strong {
    font-weight: bold;
  }
`
export const Highlight = styled.span`
  color: ${Colors.darkgreen};
`

export const PrimaryLabel = styled(Label)`
  margin-bottom: 27px;
`
