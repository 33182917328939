import styled from 'styled-components'

import { Colors } from 'Styles/theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 155px;

  .label {
    font-style: normal;
    font-weight: 450;
    font-size: 16px;
    line-height: 20px;
    color: ${Colors.gray3};
  }
`

export const PriceText = styled.span`
  font-family: CircularStd;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  margin-top: 4px;
  color: ${props => props.color ?? Colors.darkazureishgray};
`
