import React from 'react'

import { CreditCardMachine } from 'Assets'

import { numberFormat } from 'Utils'
import { Column, Image, Label, Row, Title } from './styles'

interface PaymentDetails {
  retentionRate: number
}

export default function PaymentDetails({ retentionRate }: PaymentDetails) {
  return (
    <Row>
      <Column>
        <Image src={CreditCardMachine} alt="Ícone da máquina de cartão Stone" />
      </Column>
      <Column>
        <Title>Pagamento</Title>
        <Label>
          Serão retidos{' '}
          <strong>
            {numberFormat(retentionRate)}% do que a Stone for te pagar
          </strong>{' '}
          para quitar o empréstimo.
        </Label>
      </Column>
    </Row>
  )
}
