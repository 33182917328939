import React from 'react'

import { LoanStatusEnum } from 'Enums'

import Messages from './Messages'

import { SCard } from './styles'

interface Props {
  status: LoanStatusEnum
}

export default function StatusCard({ status }: Props) {
  return (
    <SCard>
      <Messages status={status} />
    </SCard>
  )
}
