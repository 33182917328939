import axios, { AxiosInstance, AxiosResponse } from 'axios'

import { BankLockStatusEnum } from 'Enums/bankLock'
import { ILoan, IPagination } from 'Interfaces'
import { ICreditLock } from 'Interfaces/creditLock.interface'
import { ILoanExtract } from 'Interfaces/loanExtract.interface'
import { ISimpleResponse } from 'Interfaces/simple-response.interface'
import { IPaymentForecast } from 'Interfaces/payment-forecast.interface'
import { LOAN_STATUS } from 'Enums/loan'

export enum RepaymentMethods {
  TRANSACTION_SPLIT = 'TransactionSplit',
}

export interface CustomerLoanStatementOptions {
  page?: number
  dateFrom?: string
  dateTo?: string
  entryType?: string
  mediaTypes?: string
}

export interface CustomerMethods {
  api: AxiosInstance
  getLoans(document: string): Promise<AxiosResponse<IPagination<ILoan[]>>>
  paymentsForecast(
    loanId: string,
    document: string
  ): Promise<AxiosResponse<ISimpleResponse<IPaymentForecast>>>
  creditLockContract(
    document: string,
    status?: string
  ): Promise<AxiosResponse<ISimpleResponse<ICreditLock[]>>>
  loanStatements(
    document: string,
    borrowerId: string,
    loanId: string,
    options?: CustomerLoanStatementOptions
  ): Promise<AxiosResponse<IPagination<ILoanExtract>>>
  loanStatementsCsv(
    document: string,
    borrowerId: string,
    loanId: string,
    options?: CustomerLoanStatementOptions
  ): Promise<AxiosResponse<string>>
}

function baseAPI(token: string): CustomerMethods {
  const api = axios.create({
    baseURL: process.env.REACT_APP_CUSTOMER_API,
    headers: {
      authorization: `Bearer ${token}`,
    },
  })

  const loanStatusList = [
    LOAN_STATUS.ACCEPTED,
    LOAN_STATUS.DISBURSEMENT_REQUESTED,
    LOAN_STATUS.DISBURSEMENT_CONFIRMED,
    LOAN_STATUS.ACTIVE,
    LOAN_STATUS.SETTLED,
    LOAN_STATUS.INACTIVE,
    LOAN_STATUS.SUSPENDED,
    LOAN_STATUS.CANCELLED,
  ]

  /*
   * documentation to the endpoint below is
   * @docs https://docs.credit.stone.com.br/reference/get-full-loans-statement
   */
  function loanStatements<T = IPagination<ILoanExtract>>(
    document: string,
    borrowerId: string,
    loanId: string,
    {
      page = 1,
      dateFrom = '',
      dateTo = '',
      entryType = '',
      mediaTypes = 'application/json',
    } = {}
  ) {
    const url = new URLSearchParams()

    page && url.append('pageNumber', String(page))
    dateFrom && url.append('date.From', dateFrom)
    dateTo && url.append('date.To', dateTo)
    entryType && url.append('entryType', entryType)

    return api.get<T>(
      `/v0/credit/${document}/borrowers/${borrowerId}/loan/${loanId}/extract?${url.toString()}`,
      {
        headers: {
          Accept: mediaTypes,
          'Content-Type': mediaTypes,
        },
      }
    )
  }

  return {
    api,

    getLoans(document) {
      const statusFiltered = loanStatusList
        .map(status => `status=${status}`)
        .join('&')

      return api.get<IPagination<ILoan[]>>(
        `/v0/credit/${document}/loans?${statusFiltered}&pageSize=100` // Endpoint does not support sorting, we are sorting on the front end
      )
    },

    paymentsForecast(loanId, document) {
      return api.get(
        `/v0/credit/${loanId}/payments-forecast?document=${document}`
      )
    },

    creditLockContract(document, status = BankLockStatusEnum.EFFECTIVE) {
      return api.get(
        `/v0/merchants/credit-lock-contracts?document=${document}&status=${status}`
      )
    },

    loanStatements,

    async loanStatementsCsv(document, borrowerId, loanId, options) {
      return loanStatements<string>(document, borrowerId, loanId, {
        ...options,
        mediaTypes: 'text/csv',
      })
    },
  }
}

export default (token: string) => baseAPI(token)
