import 'bootstrap/dist/css/bootstrap.min.css'
import './fontFaces.css'

import { createGlobalStyle } from 'styled-components'
import { Colors, Spacing, Typography } from './theme'

const baseSpacing = `calc(${Spacing.baseLineHeight} * 1em)`
const smallSpacing = `calc(${baseSpacing} / 2)`

export default createGlobalStyle`
  html {
    box-sizing: border-box;
  }
  *, *::after, *::before {
    box-sizing: inherit;
  }
  body {
    font-feature-settings: "kern", "liga", "pnum";
    -webkit-font-smoothing: antialiased;
    color: ${Colors.darkgray};
    font-family: ${Typography.baseFontFamily};
    font-size: ${Typography.baseFontSize};
    line-height: ${Spacing.baseLineHeight};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: Raleway, ${Typography.baseFontFamily};
    font-size: ${Typography.baseFontSize};
    line-height: ${Spacing.headingLineHeight};
    margin: 0 0 ${smallSpacing};
  }

  p {
    margin: 0 0 ${smallSpacing};
  }

  a {
    color: ${Colors.stonegreen1};
    text-decoration: none;
    transition: color 0.1s linear;

    &:active,
    &:focus,
    &:hover {
      color: ${Colors.darkazureishgray};
      text-decoration: none;
    }

    &:active,
    &:focus {
      outline: none;
    }
  }

  hr {
    display: none;
    border-bottom: 1px solid ${Colors.lightgray};
    border-left: none;
    border-right: none;
    border-top: none;
    margin: ${baseSpacing} 0;
  }

  img,
  picture {
    margin: 0;
    max-width: 100%;
  }

  ul,
  ol {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  button:focus {
    outline: none;
  }

  #main_content {
    border-top: 1px solid #ddd;
  }

  @media print {
    .hide-printing { display: none; }
  }

  .filter-blur {
    filter: blur(15px);
  }
`
