import React, { useContext, useState } from 'react'

import LoanDetails from 'BusinessComponent/LoanDetails'
import FaqLockModal from 'BusinessComponent/FaqLockModal'
import { LoanStatusEnum, TabFullScreenEnum } from 'Enums'
import { useFirebase } from 'Hooks/useFirebase'
import { LoanDetailsContext, StoreContext } from 'Contexts/LoanDetailsContext'
import TabSelectedContext from 'Contexts/TabSelectedContext'

export default function LoanDetailsGateway() {
  const loanDetailsContext = useContext<LoanDetailsContext | null>(StoreContext)
  const { setTabFullScreenSelected } = useContext(TabSelectedContext)
  const [faqLockModal, setFaqLockModal] = useState<boolean>(false)

  const firebase = useFirebase()
  const loan = loanDetailsContext?.currentLoan

  function handleShowExtract() {
    setTabFullScreenSelected(TabFullScreenEnum.EXTRACT)
  }

  if (loan && loanDetailsContext && loanDetailsContext.showLoanDetails) {
    return (
      <>
        <LoanDetails
          loan={loan}
          status={loan.status as LoanStatusEnum}
          onBack={loanDetailsContext.handleClose}
          onShowExtract={handleShowExtract}
          onKnowMore={() => setFaqLockModal(true)}
        />

        {firebase.faqLockList && (
          <FaqLockModal
            show={faqLockModal}
            items={firebase.faqLockList}
            onCancel={() => setFaqLockModal(false)}
          />
        )}
      </>
    )
  }

  return null
}
