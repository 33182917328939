import React from 'react'
import { OutlineButton } from 'Components/Button'
import { Csv } from 'Assets'

export interface SideSectionProps {
  onDownloadCsv(): void
  onPrintScreen(): void
}

const SideSection: React.FC<SideSectionProps> = ({
  onDownloadCsv,
  onPrintScreen,
}) => {
  return (
    <>
      <OutlineButton
        onClick={onDownloadCsv}
        className="space-button hide-printing"
        icon={Csv}
        iconAlt="ícone csv"
      >
        Exportar como CSV
      </OutlineButton>

      <OutlineButton className="hide-printing" onClick={onPrintScreen}>
        Imprimir
      </OutlineButton>
    </>
  )
}

export default SideSection
