import React from 'react'
import { appConfig } from '../../../../../Config'
import AuthService from '../../../../../Services/auth.service'

export default function Attendant() {
  if (AuthService().isOperator() || AuthService().isRisk()) {
    const attendantURL = `${appConfig.portalURL}/atendente`

    return (
      <li className="menu__item">
        <a className="menu__handle" href={attendantURL}>
          Atendente
        </a>
      </li>
    )
  }
  return <></>
}
