import styled from 'styled-components'
import { Colors } from 'Styles/theme'

export const NormalText = styled.p`
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: ${Colors.darkgrey3};

  span {
    color: ${Colors.darkgrey2};
    font-weight: 500;
    text-align: center;
    color: ${Colors.darkgrey2};
  }
`
