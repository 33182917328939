import styled from 'styled-components'
import Text from 'Components/Text'

export const Wrapper = styled.div`
  display: ${props => (props.hidden ? 'none' : 'block')};
  max-width: 450px;
  width: 100%;
  margin-bottom: 30px;
`

export const Label = styled(Text)`
  font-family: CircularStd;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 8px;
  color: #1d242b;
`
