import { useCallback, useState } from 'react'
import { ILoan, IPagination } from 'Interfaces'
import LoanModel, { ILoanModel } from 'Models/Loan'
import customerApi, { CustomerMethods } from 'Services/customer'
import { token } from 'Services/base.auth'

export interface IUseLoanProps {
  document: string
  api?: CustomerMethods
  loanModel?(): ILoanModel
}

export interface IUseLoanReturn {
  isLoading: boolean
  error: boolean
  loans: IPagination<ILoan[]> | undefined
  getLoans(): void
}

export function useLoan({
  document,
  api = customerApi(token!),
  loanModel = LoanModel,
}: IUseLoanProps): IUseLoanReturn {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [error, setError] = useState<boolean>(false)
  const [loans, setLoans] = useState<IPagination<ILoan[]>>()
  const [apiCustomer] = useState<CustomerMethods>(api)

  const getLoans = useCallback(async () => {
    setIsLoading(true)
    setError(false)

    try {
      const result = await loanModel().getListLoan({
        document,
        api: apiCustomer,
      })

      setLoans(result)
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      setError(true)
    }
  }, [document, apiCustomer, loanModel])

  return {
    isLoading,
    error,
    loans,
    getLoans,
  }
}
