/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import LogoSVG from '../../../../../Assets/logo.svg'

export default function Logo() {
  return (
    <div className="menu__area menu__area--brand">
      <a className="menu__brand">
        <img className="menu__media" alt="Stone" src={LogoSVG} />
      </a>
    </div>
  )
}
