import styled from 'styled-components'

import { Colors } from 'Styles/theme'

export const Title = styled.h1`
  font-weight: 600;
  margin: 0;
`

export const SecondaryTitle = styled.span`
  font-weight: 400;
  display: inline;
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  font-size: 28px;
  color: ${Colors.darkazureishgray};

  button {
    display: flex;
    border: none;
    background: none;
    margin-right: 32px;
  }

  img {
    width: 20px;
    height: 20px;
  }
`
