import React from 'react'

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

import { Colors } from 'Styles/theme'

import { Cell, PaginationWrapper, Row, SkeletonWrapper } from './styles'

const TableRow = (
  <Row>
    <Cell>
      <Skeleton height={20} width={70} />
    </Cell>
    <Cell>
      <Skeleton height={20} width={96} />
    </Cell>
    <Cell>
      <Skeleton height={20} width={96} />
    </Cell>
    <Cell>
      <Skeleton height={20} width={120} />
    </Cell>
    <Cell>
      <Skeleton height={20} width={96} />
    </Cell>
    <Cell>
      <Skeleton height={20} width={96} />
    </Cell>
  </Row>
)

const Pagination = (
  <PaginationWrapper>
    <Skeleton height={34} width={404} />
  </PaginationWrapper>
)

export default function LoansSkeleton() {
  return (
    <SkeletonWrapper>
      <SkeletonTheme
        color={Colors.lightazureishgray}
        highlightColor={Colors.white}
      >
        {TableRow}
        {TableRow}
        {TableRow}
        {TableRow}
        {TableRow}
        {TableRow}
        {Pagination}
      </SkeletonTheme>
    </SkeletonWrapper>
  )
}
