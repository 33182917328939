import styled from 'styled-components'
import { Modal } from 'react-bootstrap'
import { Colors } from 'Styles/theme'

export const Container = styled(Modal)`
  max-width: 100vw;

  .modal-content {
    display: flex;
    align-items: center;
    padding: 19px 24px 58px;

    .area-close {
      width: 100%;
      display: flex;
      justify-content: flex-end;

      img {
        width: 14px;
      }
    }

    .principal-icon {
      max-width: 268px;
      margin-top: 35px;

      @media screen and (max-width: 767px) {
        max-width: 167px;
      }
    }

    h1 {
      max-width: 323px;
      font-family: CircularStd;
      font-weight: bold;
      font-size: 32px;
      text-align: center;
      line-height: 24px;
      color: ${Colors.black};
      margin-top: 38px;
      margin-bottom: 30px;

      @media screen and (max-width: 767px) {
        max-width: 203px;
        font-size: 20px;
      }
    }

    p {
      max-width: 375px;
      font-family: CircularStd;
      font-size: 20px;
      text-align: center;
      line-height: 20px;
      color: ${Colors.black};
      margin-bottom: 40px;

      @media screen and (max-width: 767px) {
        max-width: 199px;
        font-size: 16px;
      }
    }
  }
`
