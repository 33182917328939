import React, { useEffect, useState } from 'react'
import { Bar, BarBackgorund, Container, Description } from './styles'
import { getPercentage } from './utils'

interface Props {
  currentValue: number
  totalValue: number
  description?: boolean
  descriptionText?: (percentage: number) => JSX.Element
}

export default function ProgressBar({
  currentValue,
  totalValue,
  description,
  descriptionText,
}: Props) {
  const [percentage, setPercentage] = useState<number>(0)
  const [displayDescription, setDisplayDescription] = useState<JSX.Element>()

  useEffect(() => {
    if (!totalValue) {
      setPercentage(0)
      return
    }
    setPercentage(getPercentage(currentValue, totalValue))
  }, [currentValue, totalValue])

  useEffect(() => {
    if (descriptionText) {
      setDisplayDescription(descriptionText(percentage))
    }
  }, [percentage, descriptionText])

  return (
    <Container>
      <BarBackgorund>
        <Bar percentage={percentage} />
      </BarBackgorund>

      {description && displayDescription && (
        <Description>{displayDescription}</Description>
      )}
    </Container>
  )
}
