import React, { useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Select from 'Components/Select'

import {
  addSpecialCharacterTo,
  removeSpecialCharacterFrom,
} from 'Utils/document'
import StoneCodesContext from 'Contexts/StoneCodesContext'
import { CreditLinesStoreContext } from 'Contexts/CreditLineContext'
import { LoanSimulationQueryStrings } from 'Enums'
import { Label, Wrapper } from './styles'

interface Props {
  onChange(document: string): void
  hide?: boolean
}

export default function StoneCodesDocumentSelect({
  onChange,
  hide = false,
}: Props) {
  const { search } = useLocation()
  const history = useHistory()
  const [stoneCodes, isLoading] = useContext(StoneCodesContext)
  const {
    isLoading: isLoadingAllDocuments,
    currentDocument,
    updateDocument,
  } = useContext(CreditLinesStoreContext)

  const query = new URLSearchParams(search)
  const searchDocument = query.get(LoanSimulationQueryStrings.DOCUMENT)

  const options = stoneCodes.map(({ cnpj, fantasy_name }) => ({
    value: cnpj,
    label: `${cnpj} - ${fantasy_name}`,
  }))

  function handleChangeDocument(document: string) {
    query.set(
      LoanSimulationQueryStrings.DOCUMENT,
      removeSpecialCharacterFrom(document)
    )
    history.push({ search: query.toString() })
    updateDocument(removeSpecialCharacterFrom(document))
    onChange(document)
  }

  if (isLoading || isLoadingAllDocuments || !currentDocument) {
    return null
  }

  const currentDocumentFormatted = addSpecialCharacterTo(currentDocument)

  return (
    <Wrapper hidden={hide}>
      <Label>Escolha um documento</Label>
      <Select
        options={options}
        onChange={handleChangeDocument}
        currentValue={
          (searchDocument && addSpecialCharacterTo(searchDocument)) ||
          currentDocumentFormatted
        }
      />
    </Wrapper>
  )
}
