import Cookies from 'js-cookie'
import {
  addSpecialCharacterTo,
  removeSpecialCharacterFrom,
} from 'Utils/document'

export const token = Cookies.get('external_token')

export function hasPartialLock() {
  return Cookies.get('external_hasPartialLock') === 'true'
}

export function documentHasBankLock(document: string): boolean {
  const documentFormatted = addSpecialCharacterTo(
    removeSpecialCharacterFrom(document)
  )
  const EMPTY_ARRAY_STRING = JSON.stringify([])
  const cookie =
    (Cookies.get('external_documentsWithLock') as string) ?? EMPTY_ARRAY_STRING
  const listDocumentLock = JSON.parse(cookie) as string[]

  return listDocumentLock.includes(documentFormatted)
}
