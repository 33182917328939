import styled from 'styled-components'

export const Container = styled.div`
  display: block;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.0819766);
  border-radius: 10px;
  padding: 20px 0;

  @media screen and (min-width: 1024px) {
    padding: 20px 32px;
  }
`
