import React, { useContext, useEffect, useState } from 'react'
import Card from 'Components/Card'
import Loader from 'Components/Loader'
import { getStoneCodeByDocumentFrom } from 'Utils/stonecodes'
import { hasAlertCreated } from 'Services/firebase'
import UserContext from 'Contexts/UserContext'
import StoneCodesContext from 'Contexts/StoneCodesContext'
import createAlert from 'Pages/LoanManagement/helper/createAlert'
import CreditAlertCreated from 'Components/LayoutState/Internals/CreditAlertCreated'
import CreditAlertCreate from 'Components/LayoutState/Internals/CreditAlertCreate'
import { CreditLinesStoreContext } from 'Contexts/CreditLineContext'
import TabSelectedContext from 'Contexts/TabSelectedContext'
import { TabEnum } from 'Enums'

export interface HasCreditLineProps {
  document: string
  hasAlertCreatedInjection?(): boolean
}

const HasCreditLine: React.FC<HasCreditLineProps> = ({
  document,
  hasAlertCreatedInjection = hasAlertCreated,
}) => {
  const [alertCreated, setAlertCreated] = useState(false)
  const user = useContext(UserContext)
  const [stoneCodes] = useContext(StoneCodesContext)
  const { setTabSelected } = useContext(TabSelectedContext)

  const { isLoading, creditLines } = useContext(CreditLinesStoreContext)

  useEffect(() => {
    if (!user) return

    const stoneCode = getStoneCodeByDocumentFrom(stoneCodes, document)

    const alreadyHasAlertCreated = hasAlertCreatedInjection(
      document,
      stoneCode,
      user.email
    )

    setAlertCreated(alreadyHasAlertCreated)
  }, [user, document, stoneCodes, hasAlertCreatedInjection])

  function onAlertCreated() {
    if (!user) return

    const alertWasCreated = createAlert({
      user,
      document,
      stoneCodes,
    })

    setAlertCreated(alertWasCreated)
  }

  if (isLoading) {
    return (
      <Card style={{ display: 'flex', justifyContent: 'center' }}>
        <Loader spinner />
      </Card>
    )
  }

  if (creditLines.length) {
    setTabSelected(TabEnum.SIMULATION)
    return null
  }

  return (
    (alertCreated && <CreditAlertCreated />) || (
      <CreditAlertCreate onClickCreateAlert={onAlertCreated} />
    )
  )
}

export default HasCreditLine
