import PasswordConfirmModal from 'BusinessComponent/PasswordConfirmModal'
import usePasswordValidation, {
  IUsePasswordValidation,
} from 'Hooks/usePasswordValidation'
import React, { useContext, useEffect, useState } from 'react'
import NetworkErrorContext from 'Contexts/NetworkErrorContext'

interface IPasswordConfirm {
  show: boolean
  onSuccess(): void
  onCancel(): void
  passwordValidationHook?(): IUsePasswordValidation
}
export default function PasswordConfirm({
  show,
  onSuccess,
  onCancel,
  passwordValidationHook = usePasswordValidation,
}: IPasswordConfirm) {
  const [showModal, setShowModal] = useState<boolean>(true)
  const [value, setValue] = useState<string>('')
  const { updateError } = useContext(NetworkErrorContext)
  const {
    handleValidation,
    attempts,
    isLoading,
    error,
  } = passwordValidationHook()

  useEffect(() => {
    setShowModal(show)
  }, [show])

  useEffect(() => {
    updateError(error)
  }, [error, updateError])

  function handleConfirm() {
    if (value) {
      handleValidation(value, onSuccess)
      setValue('')
    }
  }

  return (
    <PasswordConfirmModal
      isLoading={isLoading || !attempts}
      show={showModal}
      value={value}
      attempts={attempts}
      onChange={setValue}
      onConfirm={handleConfirm}
      onCancel={() => {
        setShowModal(false)
        onCancel()
      }}
    />
  )
}
