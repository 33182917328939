import { useContext, useEffect } from 'react'
import { ICurrentUser } from 'Interfaces'
import { Roles } from 'Enums'
import chat from 'Services/chat'

import UserContext from 'Contexts/UserContext'

export default function Chat() {
  const user = useContext<ICurrentUser | null>(UserContext)

  useEffect(chat.initialize, [])

  useEffect(() => {
    if (user) {
      const { isPersonified, role, firstName, lastName, email } = user
      if (!isPersonified && role === Roles.CLIENT) {
        const fullName = `${firstName} ${lastName}`
        chat.configure(fullName, email)
      }
    }
  }, [user])

  return null
}
