import React, { createContext, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'

export interface MobileAccessContextStore {
  show: boolean
  setShow: React.Dispatch<React.SetStateAction<boolean>>
}

export const MobileAccessContext = createContext<MobileAccessContextStore>({
  show: false,
  setShow: () => {},
})

const MobileAccess: React.FC = ({ children }) => {
  const [show, setShow] = useState<boolean>(false)

  useEffect(() => {
    setShow(isMobile)
  }, [])

  const store: MobileAccessContextStore = {
    show,
    setShow,
  }

  return (
    <MobileAccessContext.Provider value={store}>
      {children}
    </MobileAccessContext.Provider>
  )
}

export default MobileAccess
