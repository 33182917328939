import { useState } from 'react'

import axios, { CancelTokenSource } from 'axios'
import CreditLinesModel from 'Models/CreditLines'
import { ICreditLines } from 'Interfaces'

export interface IUseCreditLines {
  isLoading: boolean
  getAllCreditLines(document: string[]): void
  currentDocument: string
  allCreditLines: any
}

export interface CreditLinesByDocument {
  document: string
  creditLine: ICreditLines
  error?: boolean
}

function compareCreditLines(
  first: CreditLinesByDocument,
  next: CreditLinesByDocument
): number {
  if (first.error) {
    return 1
  }

  if (next.error) {
    return -1
  }

  const firstCreditLine = first.creditLine
  const nextCreditLine = next.creditLine

  if (
    firstCreditLine.availableCreditLimit < nextCreditLine.availableCreditLimit
  ) {
    return 1
  }

  if (
    firstCreditLine.availableCreditLimit > nextCreditLine.availableCreditLimit
  ) {
    return -1
  }

  return 0
}

export default function useCreditLines(): IUseCreditLines {
  const [allCreditLines, setAllCreditLines] = useState<any>([])
  const [currentDocument, setCurrentDocument] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const getAllCreditLines = async (documents: string[]) => {
    setIsLoading(true)

    let sourceRequest: CancelTokenSource | null = null

    const getCancelTokenSource = (source: CancelTokenSource) => {
      sourceRequest = source
    }

    const creditLinesModel = CreditLinesModel()

    const creditLinesByDocumentPromises = documents.map(async document => {
      try {
        const response = await creditLinesModel?.getCreditLines({
          getCancelTokenSource,
          stoneCode: document,
        })
        const [firstCreditLine] = response
        if (firstCreditLine) {
          return { document, creditLine: firstCreditLine }
        }
      } catch (err) {
        if (!axios.isCancel(err)) {
          return { document, creditLine: null, error: true }
        }
      }
      return null
    })

    const creditLinesByDocumentResolved = await Promise.all(
      creditLinesByDocumentPromises
    )

    const creditLinesByDocument = creditLinesByDocumentResolved.filter(
      Boolean
    ) as CreditLinesByDocument[]

    const orderedCreditLines = creditLinesByDocument.sort(compareCreditLines)
    const [bestCreditLine] = orderedCreditLines

    const [firstDocument] = documents

    const newDocument = bestCreditLine?.document || firstDocument

    setAllCreditLines(orderedCreditLines)
    setCurrentDocument(newDocument)
    setIsLoading(false)

    return sourceRequest
  }

  return {
    allCreditLines,
    currentDocument,
    isLoading,
    getAllCreditLines,
  }
}
