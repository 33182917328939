import styled from 'styled-components'
import { darken } from 'polished'
import { Colors } from 'Styles/theme'

export const Container = styled.button`
  width: 235px;
  height: 49px;
  background-color: transparent;
  padding: 18px auto;

  border: 1px solid ${Colors.darkgreen};
  box-sizing: border-box;
  border-radius: 4px;

  text-align: center;
  color: ${Colors.darkgreen};
  font-family: CircularStd;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  &:hover {
    border: 1px solid ${darken(0.05, Colors.darkgreen)};
    color: ${darken(0.05, Colors.darkgreen)};
  }

  &:active {
    border: 1px solid ${darken(0.1, Colors.darkgreen)};
    color: ${darken(0.1, Colors.darkgreen)};
  }

  .icon {
    margin-right: 20px;
    margin-left: -20px;
  }
`
