import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { ILoan } from 'Interfaces'
import { LoanDetailsContext, StoreContext } from 'Contexts/LoanDetailsContext'

import { ErrorState } from 'Components/LayoutState'
import LoansDataTable from 'BusinessComponent/LoansDataTable'
import CurrentDocumentContext from 'Contexts/CurrentDocumentContext'
import LoansContext from 'Contexts/LoansContext'
import { Container } from './styles'
import HasCreditLine from './HasCreditLine'

interface Props {
  currentDocument: string
}

export default function History({ currentDocument }: Props) {
  const { loans, isLoading, error } = useContext(LoansContext)
  const loanDetailsContext = useContext<LoanDetailsContext | null>(StoreContext)

  function handleRowClicked(loan: ILoan) {
    loanDetailsContext?.handleShowDetails(loan, currentDocument)
  }

  const history = useHistory()

  useEffect(() => {
    history.push({ pathname: '/emprestimos', search: history.location?.search })
  }, [history])

  if (error) {
    return <ErrorState onClickUpdateButton={() => window.location.reload()} />
  }

  if (!loans?.data.length) {
    return <HasCreditLine document={currentDocument} />
  }

  return (
    <CurrentDocumentContext.Provider value={currentDocument}>
      <Container>
        <LoansDataTable
          loans={loans}
          onRowClicked={handleRowClicked}
          paginationDefaultPage={loanDetailsContext?.currentPage}
          onChangePage={(page: number) =>
            loanDetailsContext?.setCurrentPage(page)
          }
          isLoading={isLoading}
        />
      </Container>
    </CurrentDocumentContext.Provider>
  )
}
