import styled from 'styled-components'

import { Colors } from 'Styles/theme'

export const Wrapper = styled.div`
  display: flex;
  margin: 4em 0 0;
  border-top: solid 1px ${Colors.lavenderblue};
  padding: 1em 0 0;

  @media screen and (max-width: 1023px) {
    flex-wrap: wrap;
  }
`

export const Feature = styled.div`
  display: flex;
  margin: 25px;
`
export const FeatureContent = styled.div`
  text-align: left;

  p {
    letter-spacing: 0.32px;
    color: ${Colors.cyanblue};
    font-size: 16px;
  }
`

export const ImageWrapper = styled.div`
  img {
    padding-right: 15px;
    width: 45px;
    max-width: none;
  }
`

export const Subtitle = styled.h4`
  margin: 5px 0px;
  letter-spacing: 0.4px;
  font-size: 20px;
  font-weight: 500;
`
