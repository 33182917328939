import React from 'react'
import { ClosedPadlock } from 'Assets'
import { Button } from 'Components/Button'
import LayoutState from 'Components/LayoutState/Internals/BaseState'

interface Props {
  onClickButton(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void
}

export default function BankLockErrorState({ onClickButton }: Props) {
  return (
    <LayoutState
      icon={ClosedPadlock}
      title="Ops! Identificamos uma trava bancária ativa"
      subtitle={
        <>
          Existe outra operação de crédito com garantia em recebíveis nesta
          conta.
          <br />É preciso quitar a transação em andamento antes de contratar um
          Empréstimo Stone.
        </>
      }
      button={
        <Button onClick={onClickButton} data-testid="go-back-button">
          Voltar ao início
        </Button>
      }
    />
  )
}
