import styled from 'styled-components'
import Jumbotron from 'Components/Jumbotron'
import { Colors } from 'Styles/theme'

export const Title = styled.h2`
  font-size: 16px;
  font-weight: bold;
  line-height: 30px;
  margin: 0;
  color: #3b4652;
`
export const SubTitle = styled.p`
  margin-top: 19px;
  font-size: 16px;
  color: #3b4652;
  margin-top: 4px;
  margin-bottom: 0;
`
export const CustomJumbotron = styled(Jumbotron)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 23px 16px 16px;

  button {
    font-family: CircularStd;
    font-weight: normal;
    font-size: 16px;
    text-align: right;
    color: ${Colors.darkgreen};
    border: none;
    background: none;
  }
`
