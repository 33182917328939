export enum ErrorMapperEnum {
  NO_ERROR = 'NO_ERROR',
  GENERAL = 'GENERAL',
  CREDIT_LOCK = 'CREDIT_LOCK',
  INVALID_CNPJ = 'INVALID_CNPJ',
  INVALID_BANK_ACCOUNT = 'INVALID_BANK_ACCOUNT',
  INVALID_BANK_ACCOUNT_SAVING = 'INVALID_BANK_ACCOUNT_SAVING',
  CREDIT_LINE_IS_NOT_ACTIVE = 'CREDIT_LINE_IS_NOT_ACTIVE',
  CREDIT_LINE_ALREADY_HAS_LOAN = 'CREDIT_LINE_ALREADY_HAS_LOAN',
  BORROWER_ALREADY_HAS_LOAN = 'BORROWER_ALREADY_HAS_LOAN',
}

export const APIErrorResponseMapperEnum: {
  [type: string]: string
} = {
  [ErrorMapperEnum.INVALID_CNPJ]:
    'Seu CNPJ não está apto na Receita Federal. Você precisa regularizar a situação antes de contratar o empréstimo.',
  [ErrorMapperEnum.INVALID_BANK_ACCOUNT]:
    'Identificamos que a conta bancária cadastrada é inválida. Nesse caso, não é possível concluir a contratação. Para prosseguir, é necessário atualizar a conta bancária.',
  [ErrorMapperEnum.CREDIT_LINE_IS_NOT_ACTIVE]: 'Credit line is not active',
  [ErrorMapperEnum.CREDIT_LINE_ALREADY_HAS_LOAN]:
    'Credit line already has a loan',
  [ErrorMapperEnum.BORROWER_ALREADY_HAS_LOAN]:
    'Borrower already has an ongoing loan',
  [ErrorMapperEnum.CREDIT_LOCK]:
    'Você tem outra operação de crédito e em garantia os seus recebíveis de cartão. Essa operação precisa ser encerrada antes de contratar um novo empréstimo.',
  [ErrorMapperEnum.INVALID_BANK_ACCOUNT_SAVING]:
    'O domicílio bancário configurado é Conta Poupança e por isso não é possível seguir com a contratação',
}
