import React from 'react'
import ReactDOM from 'react-dom'
import 'normalize.css'
import App from './App'
import { token } from './Services/base.auth'

ReactDOM.render(
  <App token={token as string} />,
  document.getElementById('root')
)
