import React from 'react'
import { Col } from 'react-bootstrap'

import TextLabel from 'Components/TextLabel'
import { Colors } from 'Styles/theme'
import { ILoanExtract } from 'Interfaces/loanExtract.interface'
import { number2price } from 'Utils'
import { IPagination } from 'Interfaces'
import { Container } from './styles'

interface Props {
  loanExtract: IPagination<ILoanExtract>
}

const Summary: React.FC<Props> = ({ loanExtract }) => {
  const {
    balance: { additionalTax, repayments, dailyTax, fee, interest, principal },
  } = loanExtract.data

  const totalToPay =
    principal + fee + additionalTax + repayments + dailyTax + interest
  const totalPaid = repayments < 0 ? repayments * -1 : 0

  return (
    <Container>
      <Col md={4}>
        <TextLabel
          label="Valor pago"
          text={number2price(totalPaid)}
          color={Colors.stonegreen1}
        />
      </Col>

      <Col md={4}>
        <TextLabel label="Valor a pagar" text={number2price(totalToPay)} />
      </Col>

      <Col md={4}>
        <TextLabel label="Total de juros" text={number2price(interest ?? 0)} />
      </Col>
    </Container>
  )
}

export default Summary
