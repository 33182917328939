import { ILoan, IPagination } from 'Interfaces'
import { ILoanExtract } from 'Interfaces/loanExtract.interface'
import { AxiosResponse } from 'axios'
import getListLoan, { IGetListLoanParameters } from './getLoans'
import {
  IGetStatementModelFunction,
  getStatement,
  getStatementCsv,
} from './getStatement'

export interface ILoanModel {
  getListLoan(props: IGetListLoanParameters): Promise<IPagination<ILoan[]>>
  getStatement(
    props: IGetStatementModelFunction
  ): Promise<IPagination<ILoanExtract>>
  getStatementCsv(
    props: IGetStatementModelFunction
  ): Promise<AxiosResponse<string>>
}

export default (): ILoanModel => {
  return {
    getListLoan,
    getStatement,
    getStatementCsv,
  }
}
