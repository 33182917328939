import styled, { FlattenSimpleInterpolation, css } from 'styled-components'

import { Colors } from 'Styles/theme'

export enum TabThemes {
  DEFAULT,
  CENTER_SMALL,
}

export interface STabListProps {
  theme?: TabThemes
}

const SelectedTabThemeDefault = css`
  border-bottom: 4px solid ${Colors.darkgreen};
  color: ${Colors.darkgreen};
`

const SelectedTabThemeCenterSmall = css`
  border-bottom: 4px solid ${Colors.darkgreen};
  color: ${Colors.darkblue};
`

export const TabThemeDefault = css`
  display: inline-block;
  transition: color 0.1s linear;
  margin-right: 40px;
  outline: none;
  cursor: pointer;
  padding-bottom: 3px;
  line-height: 30px;
  color: ${Colors.darkblue};
  font-family: CircularStd;
  font-size: 24px;
  font-weight: 600;
  font-style: normal;

  &:last-child {
    margin-right: 0;
  }
`

export const TabThemeCenterSmall = css`
  width: 100%;
  text-align: center;
  transition: color, border-bottom 0.1s linear;
  margin-right: 2px;
  outline: none;
  cursor: pointer;
  padding-bottom: 16px;
  line-height: 30px;
  color: ${Colors.gray3};
  font-family: CircularStd;
  font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid ${Colors.lightblue};
`

export function getStateFromTheme(
  theme: TabThemes
): FlattenSimpleInterpolation {
  switch (theme) {
    case TabThemes.CENTER_SMALL:
      return SelectedTabThemeCenterSmall

    default:
      return SelectedTabThemeDefault
  }
}

export const Container = styled.div`
  width: 100%;
`

export const STabList = styled.ul<STabListProps>`
  display: flex;
  padding: 0px;
  width: 100%;

  li {
    ${({ theme }) => {
      switch (theme) {
        case TabThemes.CENTER_SMALL:
          return TabThemeCenterSmall

        default:
          return TabThemeDefault
      }
    }}

    &:hover {
      ${({ theme }) => {
        return getStateFromTheme(theme)
      }}
    }

    &.selected {
      ${({ theme }) => {
        return getStateFromTheme(theme)
      }}
    }
  }
`
