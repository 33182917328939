export function removeSpecialCharacterFrom(document: string) {
  return document.replace(/[./-]/g, '')
}

export function addMaskCpf(document: string) {
  if (!document) return ''

  if (document.length !== 11) {
    throw new Error('CPF has not a valid length')
  }

  const docPt1 = document.substr(0, 3)
  const docPt2 = document.substr(3, 3)
  const docPt3 = document.substr(6, 3)
  const docPt4 = document.substr(9, 2)

  return `${docPt1}.${docPt2}.${docPt3}-${docPt4}`
}

export function addMaskCnpj(document: string) {
  if (!document) return ''

  if (document.length !== 14) {
    throw new Error('CNPJ has an invalid length')
  }

  const docPt1 = document.substr(0, 2)
  const docPt2 = document.substr(2, 3)
  const docPt3 = document.substr(5, 3)
  const docPt4 = document.substr(8, 4)
  const docPt5 = document.substr(12, 2)

  return `${docPt1}.${docPt2}.${docPt3}/${docPt4}-${docPt5}`
}

export function addSpecialCharacterTo(document: string) {
  if (!document) return ''

  if (document.length !== 11 && document.length !== 14) {
    throw new Error('Value can not match as cpf or cnpj')
  }

  return document.length === 11 ? addMaskCpf(document) : addMaskCnpj(document)
}

export const getDocumentFromUrlSearch = (search: string): string => {
  if (!search) return ''

  try {
    const [, value] = search
      .replace('?', '')
      .split('&')
      .map(query => query.split('='))
      .find(([key]) => key === 'document')

    return value
  } catch (_) {
    return ''
  }
}
