import React from 'react'

import { Container } from './styles'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode
}

export default function Card({ children, ...rest }: Props) {
  return <Container {...rest}>{children}</Container>
}
