import styled from 'styled-components'

import { Colors } from 'Styles/theme'

export const Container = styled.div`
  padding: 36px 0;
  border-bottom: 1px solid ${Colors.lightblue};

  .title {
    font-size: 28px;
    line-height: 35px;
    font-weight: normal;
    margin-bottom: 0;

    span {
      font-weight: bold;
    }
  }

  .close {
    cursor: pointer;
  }
`
export const FlexAlignmnet = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`
