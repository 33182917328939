import { useCallback, useState } from 'react'
import { ILoanExtract } from 'Interfaces/loanExtract.interface'
import LoanModel, { ILoanModel } from 'Models/Loan'
import { CustomerLoanStatementOptions } from 'Services/customer'
import { IPagination } from 'Interfaces'
import { AxiosResponse } from 'axios'

export interface IUseLoanExtractProps {
  document: string
  borrowerId: string
  loanId: string
  loanModel?(): ILoanModel
}

export interface IUseLoanExtract {
  loanExtract: IPagination<ILoanExtract> | null
  isLoading: boolean
  error: boolean
  getLoanStatement(options?: CustomerLoanStatementOptions): void
  getLoanStatementCsv(
    options?: CustomerLoanStatementOptions
  ): Promise<AxiosResponse<string>>
  clearLoanExtract(): void
}

export function useLoanExtract({
  document,
  borrowerId,
  loanId,
  loanModel = LoanModel,
}: IUseLoanExtractProps): IUseLoanExtract {
  const [loanExtract, setLoanExtract] = useState<IPagination<
    ILoanExtract
  > | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [loan] = useState(loanModel())

  const getLoanStatement = useCallback<
    (options?: CustomerLoanStatementOptions) => void
  >(
    async (options?: CustomerLoanStatementOptions) => {
      try {
        setIsLoading(true)

        const result = await loan.getStatement({
          document,
          borrowerId,
          loanId,
          options,
        })

        setLoanExtract(result)
        setIsLoading(false)
      } catch (err) {
        setError(true)
        setIsLoading(false)
      }
    },
    [document, borrowerId, loanId, loan]
  )

  const getLoanStatementCsv = useCallback<
    (options?: CustomerLoanStatementOptions) => Promise<AxiosResponse<string>>
  >(
    (options?: CustomerLoanStatementOptions) => {
      return loan.getStatementCsv({
        document,
        borrowerId,
        loanId,
        options,
      })
    },
    [document, borrowerId, loanId, loan]
  )

  const clearLoanExtract = useCallback(() => {
    setLoanExtract(null)
  }, [])

  return {
    loanExtract,
    isLoading,
    error,
    getLoanStatement,
    clearLoanExtract,
    getLoanStatementCsv,
  }
}
