import React from 'react'
import { ILoan, IPagination } from 'Interfaces'
import Card from 'Components/Card'
import DataTable from 'Components/DataTable'
import { Container } from './styles'
import { DataTableTitle, columns } from './helper/datatable'

import LoanSkeleton from './Skeleton'

interface Props {
  loans: IPagination<ILoan[]>
  onRowClicked(row: ILoan): void
  isLoading?: boolean
  paginationDefaultPage?: number
  onChangePage?: (page: number, totalRows: number) => void
}

export default function LoansDataTable({
  loans,
  onRowClicked,
  isLoading,
  ...props
}: Props) {
  return (
    <Container>
      <Card>
        <DataTable
          title={DataTableTitle}
          columns={columns}
          data={loans.data}
          paginationResetDefaultPage={isLoading}
          onRowClicked={onRowClicked}
          progressPending={isLoading}
          progressComponent={<LoanSkeleton />}
          persistTableHead
          pagination
          {...props}
        />
      </Card>
    </Container>
  )
}
