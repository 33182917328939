import React from 'react'

import { CustomJumbotron, SubTitle, Title } from './styles'

export interface Props {
  onKnowMore(): void
}

export default function BankInfo({ onKnowMore }: Props) {
  return (
    <CustomJumbotron>
      <div>
        <Title>Trava bancária</Title>
        <SubTitle>
          Este empréstimo utiliza seus recebíveis como garantia.
        </SubTitle>
      </div>

      <button data-testid="bankinfo-button" type="button" onClick={onKnowMore}>
        Entenda
      </button>
    </CustomJumbotron>
  )
}
