import styled from 'styled-components'

import { Colors } from 'Styles/theme'
import Card from 'Components/Card'

export interface ShowProps {
  show?: boolean
}

export const Container = styled.div`
  position: relative;
  font-family: CircularStd;
`

export const CustomCard = styled(Card)<ShowProps>`
  cursor: pointer;
  padding: 18px 23px 37px 25px;
  box-shadow: none;

  ${({ show }) =>
    show &&
    ` border-radius: 10px 10px 0 0;
      border-bottom: 1px solid ${Colors.lightazureishgray};
    `}
`

export const AreaDescription = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Title = styled.p`
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  color: ${Colors.darkblue};
`

export const Description = styled.p`
  max-width: 396px;
  font-size: 16px;
  line-height: 20px;
  color: ${Colors.darkblue};
  margin-bottom: 0;
`

export const CustomIcon = styled.img<ShowProps>`
  width: 12px;
  ${props => props.show && `transform: rotate(90deg);`}
  transition: transform .2s ease;
`

export const Content = styled.div`
  background-color: white;
  width: 100%;
  color: ${Colors.gray3};
  font-size: 16px;
  line-height: 20px;
  padding: 27px 36px 25px 26px;
  border-radius: 0 0 10px 10px;
  z-index: -1;

  height: 0;
  margin-top: -50px;
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s, opacity 0.3s ease, margin-top 0.3s ease;

  &.show {
    height: auto;
    margin-top: 0;
    opacity: 1;
    visibility: visible;
  }
`
