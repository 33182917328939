import React, { useContext } from 'react'

import {
  MobileAccessContext,
  MobileAccessContextStore,
} from 'Contexts/MobileAccessContext'
import Footer from './partials/Footer'
import Header from './partials/Header'

interface Props {
  children: JSX.Element
}

export default function Main({ children }: Props) {
  const { show: filterBlur } = useContext<MobileAccessContextStore>(
    MobileAccessContext
  )

  const writeClassFilterBlur = (filterBlur && 'filter-blur') || ''

  return (
    <div className={writeClassFilterBlur}>
      <Header />
      <div id="main_content" />
      {children}
      <div id="main_content" />
      <Footer />
    </div>
  )
}
