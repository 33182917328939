export const PORTAL_API = 'https://portalapi.stone.com.br:4443'
export const PORTAL_API_WITHOUT_PORT = 'https://portalapi.stone.com.br'
export const CUSTOMER_API = 'https://customer-api.stone.com.br'
export const TEST_PORTAL_API = 'https://testportalapi.stone.com.br:4443'
export const NPS_API = 'https://rc-nps-api-us.azurewebsites.net'
export const PORTAL_DYNAMIC_LINK = 'https://portalstone.page.link/app'
export const DASHBOARD_URL = 'https://minha.stone.com.br'
export const STONE_ACCOUNT_PROVIDER_ID = 3
export const KEYCLOAK_URL = 'https://login.stone.com.br'
export const IGNORE_SSO = false
export const MIN_VALIDITY = 1
