import styled from 'styled-components'

import Jumbotron from 'Components/Jumbotron'

import { Colors } from 'Styles/theme'

export const SJumbotron = styled(Jumbotron)`
  padding: 5px 0px 20px 0px;

  h1 {
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.4px;
    margin: 0;
  }
`
export const Section = styled.section`
  padding: 0px 30px 0px 30px;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
export const Title = styled.span`
  line-height: 30px;
  letter-spacing: 0.4px;
  color: #3b4652;
  font-size: 16px;
  display: block;
`
export const Currency = styled.span`
  padding: 5px;
  line-height: 30px;
  letter-spacing: 0.4px;
  color: #1d242b;
  font-size: 24px;
  font-weight: 700;
`
export const Symbol = styled.span`
  line-height: 30px;
  letter-spacing: 0.4px;
  color: ${Colors.gray3};
  font-size: 16px;
  font-weight: 500;
`
export const Row = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
`
// @ts-ignore
export const RowWithBorder = styled(Row)`
  margin: 0px;
  border-bottom: 1px solid ${Colors.gray};
  padding: 15px 0px 15px 0px;
`

export const Collapse = styled.div<{ isOpen: boolean }>`
  transition: height 360ms;
  margin-top: 15px;
  border-top: 1px solid rgba(45, 56, 68, 0.1);
  background-color: #f4f4f4;
  height: ${({ isOpen }) => (isOpen ? '166' : '0')}px;
  overflow: hidden;
`

export const Details = styled.span`
  color: ${Colors.darkgreen};
  cursor: pointer;
`
export const Label = styled.span`
  font-size: 16px;
  padding: 0px 30px 0px 30px;
`

export const SubTitle = styled.span`
  line-height: 22px;
  letter-spacing: 0.4px;
  color: ${Colors.darkblue};
  font-size: 16px;
  font-weight: 500;
`

export const Small = styled.small`
  color: ${Colors.gray3};
  font-style: italic;
  text-align: right;
`

export const ListWrapper = styled.div`
  border-bottom: 1px solid ${Colors.darkblue};
`
