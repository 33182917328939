import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'

import { removeSpecialCharacterFrom } from 'Utils/document'

import StoneCodesContext from 'Contexts/StoneCodesContext'

import useCreditLines from 'Hooks/useCreditLines'

import { ICreditLines } from 'Interfaces'

export interface ICreditLineContext {
  creditLines: ICreditLines[]
  error: boolean
  refresh: () => void
  isLoading: boolean
  currentDocument: string
  updateDocument: (document: string) => void
}

export const CreditLinesStoreContext = createContext<ICreditLineContext>({
  creditLines: [],
  error: false,
  refresh: () => {},
  isLoading: false,
  currentDocument: '',
  updateDocument: () => {},
})

export default ({ children }: { children: ReactNode }) => {
  const [creditLines, setCreditLines] = useState<ICreditLines[]>([])
  const [documents, setDocuments] = useState<string[]>([])
  const [isLoadingStoneCodes, setIsLoadingStoneCodes] = useState<boolean>(true)
  const [error, setError] = useState<boolean>(false)

  const {
    isLoading,
    getAllCreditLines,
    currentDocument,
    allCreditLines,
  } = useCreditLines()

  const [stoneCodes] = useContext(StoneCodesContext)

  useEffect(() => {
    if (stoneCodes.length) {
      const formattedDocuments: string[] = stoneCodes.map<string>(stoneCode =>
        removeSpecialCharacterFrom(stoneCode.cnpj)
      )
      setDocuments(formattedDocuments)
      setIsLoadingStoneCodes(false)
    }
  }, [stoneCodes])

  function getAllCreditLinesByDocuments() {
    if (documents.length) {
      getAllCreditLines(documents)
    }
  }

  useEffect(getAllCreditLinesByDocuments, [documents])

  function updateDocument(document: string) {
    setError(false)
    const documentWithCreditLine = allCreditLines.find(
      (creditline: { document: string }) => creditline.document === document
    )

    const newCreditLine = documentWithCreditLine?.creditLine || []
    if (documentWithCreditLine?.error) {
      setError(true)
    }
    setCreditLines([newCreditLine])
  }

  function refresh() {
    getAllCreditLines(documents)
  }

  const store: ICreditLineContext = {
    creditLines,
    error,
    refresh,
    isLoading: isLoading || isLoadingStoneCodes,
    currentDocument,
    updateDocument,
  }

  return (
    <CreditLinesStoreContext.Provider value={store}>
      {children}
    </CreditLinesStoreContext.Provider>
  )
}
