import React, { useEffect, useState } from 'react'
import { getLanguage, useTranslation } from 'react-multi-lang'
import { CalendarTileProperties } from 'react-calendar'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import { isEmpty } from 'ramda'

import { IoIosCloseCircle } from 'react-icons/io'
import { CalendarSelect } from 'Assets'

import {
  formatDate,
  isAboveDate,
  isAboveDecade,
  isAboveMonth,
  isAboveYear,
  isBelowDate,
  isBelowDecade,
  isBelowMonth,
  isBelowYear,
  removeDateHours,
} from 'Helper/date'

import { Colors } from 'Styles/theme'
import { CalendarRangeProps } from './types'
import { Feedback, Wrapper } from './styles'

export default function CalendarRange(props: CalendarRangeProps) {
  const { maxDate, minDate, onChange, onClear, value } = props

  const [feedback, setFeedback] = useState<string>('')
  const [max, setMax] = useState<Date>()
  const [min, setMin] = useState<Date>()

  const [initialPeriod, finalPeriod] = value

  const t = useTranslation()

  const handleTileDisabled = ({ date, view }: CalendarTileProperties) => {
    let shouldDisableTile = false

    if (
      view === 'month' &&
      ((min && isBelowMonth(min, date)) || (max && isAboveMonth(max, date)))
    ) {
      shouldDisableTile = true
    }

    if (
      view === 'year' &&
      ((min && isBelowYear(min, date)) || (max && isAboveYear(max, date)))
    ) {
      shouldDisableTile = true
    }

    if (
      view === 'decade' &&
      ((min && isBelowDecade(min, date)) || (max && isAboveDecade(max, date)))
    ) {
      shouldDisableTile = true
    }

    return shouldDisableTile
  }

  useEffect(() => {
    const verifyFeedback = () => {
      if (min && isBelowDate(min, initialPeriod)) {
        return setFeedback(
          t('validation.calendar.date-min-range', {
            value: formatDate(min),
          })
        )
      }

      if (max && isAboveDate(max, finalPeriod)) {
        return setFeedback(
          t('validation.calendar.date-max-range', {
            value: formatDate(max),
          })
        )
      }

      if (initialPeriod > finalPeriod) {
        return setFeedback(t('validation.calendar.date-invalid'))
      }

      return setFeedback('')
    }

    verifyFeedback()
  }, [t, initialPeriod, finalPeriod, max, min])

  useEffect(() => {
    setMin(removeDateHours(minDate))
    setMax(removeDateHours(maxDate))
  }, [minDate, maxDate])

  return (
    <Wrapper invalid={!isEmpty(feedback)}>
      <DateRangePicker
        calendarAriaLabel="Toggle Calendar"
        calendarIcon={
          <img src={CalendarSelect} alt="ícone de calendário" width={24} />
        }
        clearIcon={
          <IoIosCloseCircle onClick={onClear} size={24} color={Colors.gray3} />
        }
        dayPlaceholder="dd"
        format="dd/MM/yyyy"
        locale={getLanguage()}
        minDetail="decade"
        monthPlaceholder="mm"
        onChange={onChange}
        tileDisabled={handleTileDisabled}
        value={value}
        yearPlaceholder="yyyy"
      />
      {feedback && (
        <Feedback data-testid="calendar-range-feedback">{feedback}</Feedback>
      )}
    </Wrapper>
  )
}
