import axios, { CancelTokenSource } from 'axios'
import { ICreditLines } from 'Interfaces'
import getCreditLines from './getCreditLines'

export interface IGetCreditLinesProps {
  getCancelTokenSource(source: CancelTokenSource): void
  stoneCode: string
}

export interface ICreditLinesModel {
  getCreditLines(props?: IGetCreditLinesProps): Promise<ICreditLines[]>
}

export default (): ICreditLinesModel => {
  return {
    getCreditLines: ({
      getCancelTokenSource,
      stoneCode,
    }: IGetCreditLinesProps) => {
      const source = axios.CancelToken.source()

      const request = getCreditLines({
        axiosConfig: {
          cancelToken: source.token,
        },
        stoneCode,
      })

      getCancelTokenSource(source)

      return request
    },
  }
}
