import axios, { CancelTokenSource } from 'axios'
import { IPasswordValidation } from 'Interfaces'

import validatePassword from './validatePassword'

export interface IValidatePasswordProps {
  getCancelTokenSource(source: CancelTokenSource): void
  password: string
}

export interface IValidatePasswordModel {
  validatePassword(props?: IValidatePasswordProps): Promise<IPasswordValidation>
}

export default (): IValidatePasswordModel => {
  return {
    validatePassword: ({
      getCancelTokenSource,
      password,
    }: IValidatePasswordProps) => {
      const source = axios.CancelToken.source()

      const request = validatePassword({
        axiosConfig: {
          cancelToken: source.token,
        },
        password,
      })

      getCancelTokenSource(source)

      return request
    },
  }
}
