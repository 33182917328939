import styled from 'styled-components'
import { Col } from 'react-bootstrap'

export const Container = styled.div`
  * {
    font-family: CircularStd;
  }

  .space-button {
    margin-bottom: 24px;
  }

  background-color: #ffffff;
`

export const SideSection = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`
