import React from 'react'
import { appConfig } from 'Config'
import { hasStoneCode } from 'Services/base.auth'

interface Props {
  baseAuthHasStoneCode?(): boolean
}

export default function Search({ baseAuthHasStoneCode = hasStoneCode }: Props) {
  const searchURL = `${appConfig.portalURL}/busca-avancada`
  if (baseAuthHasStoneCode()) {
    return (
      <div className="menu__area menu__area--secundary">
        <ul className="menu__list">
          <li className="menu__item menu__item--7000">
            <a className="menu__handle menu__handle--7000" href={searchURL}>
              Busca
            </a>
          </li>
        </ul>
      </div>
    )
  }
  return <></>
}
