import styled, { keyframes } from 'styled-components'

const rotate = keyframes`  
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`

export const Container = styled.div`
  width: 50px;
  height: 50px;
  border: 3px solid #ccc;
  border-radius: 100%;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
  display: block;
  z-index: 2;
  animation: ${rotate} 1s linear infinite;

  .emd-loader__inner {
    width: 50px;
    height: 50px;
    border: 4px solid #0db14b;
    border-radius: 100%;
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    margin: -3px 0 0 -3px;
    box-sizing: border-box;
    border-left: solid 3px transparent;
  }
`
export const Spinner = styled.div`
  &:before {
    -webkit-animation: ${rotate} 0.5s linear infinite;
    animation: ${rotate} 0.5s linear infinite;
    content: ':';
    font-weight: bold;
    font-size: 40px;
    line-height: 16px;
    text-align: center;
    display: block;
    width: 24px;
    height: 24px;
    margin: 0 auto;
    color: #000;
    opacity: 0.6;
  }

  &:after {
    clear: both;
    content: '';
    display: block;
  }
`
