import React from 'react'
import { BellBallon } from 'Assets'
import { LayoutState } from 'Components/LayoutState'

const AlertCreated = (): JSX.Element => {
  return (
    <LayoutState
      icon={BellBallon}
      title="Seu aviso foi criado com sucesso!"
      subtitle={
        <>
          Agora é só aguardar. Avisaremos por e-mail e SMS assim que o
          Empréstimo
          <br />
          Stone estiver disponível para contratação
        </>
      }
    />
  )
}

export default AlertCreated
