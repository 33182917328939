import React, { useCallback, useContext, useEffect, useState } from 'react'
import PinCodeConfirModal from 'BusinessComponent/PinCodeConfirmModal'

// todo: fazer o hook e a interface
import usePinCodeValidation, {
  IUsePinCodeValidation,
} from 'Hooks/usePinCodeValidation'

import NetworkErrorContext from 'Contexts/NetworkErrorContext'

interface IPinCodeConfirm {
  show: boolean
  hasPinCodeError?: boolean
  onSuccess(pinCode: string, challengeId: string): void
  onCancel(): void
  pinCodeValidationHook?(): IUsePinCodeValidation
  onClearError?(): void
}

export default function PinCodeConfirm({
  show,
  hasPinCodeError,
  onSuccess,
  onCancel,
  onClearError,
  pinCodeValidationHook = usePinCodeValidation,
}: IPinCodeConfirm) {
  const [value, setValue] = useState<string>('')
  const { updateError } = useContext(NetworkErrorContext)
  const {
    challengeRequest,
    attempts,
    isLoading,
    error,
    challengeId,
  } = pinCodeValidationHook()

  useEffect(() => {
    if (show && !hasPinCodeError) {
      challengeRequest()
    }
  }, [show, challengeRequest, hasPinCodeError])

  useEffect(() => {
    updateError(error)
  }, [error, updateError])

  const handleConfirm = useCallback(() => {
    if (value) {
      onSuccess(value, challengeId)
      setValue('')
    }
  }, [challengeId, onSuccess, value])

  function handleResendCode() {
    if (!hasPinCodeError) {
      challengeRequest()
    }

    onClearError && onClearError()
  }

  return (
    <PinCodeConfirModal
      isLoading={isLoading || !attempts}
      show={show}
      hasPinCodeError={hasPinCodeError}
      value={value}
      attempts={attempts}
      onResendCode={handleResendCode}
      onChange={setValue}
      onConfirm={handleConfirm}
      onCancel={onCancel}
    />
  )
}
