import styled from 'styled-components'
import { Colors } from 'Styles/theme'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 175px;
  background-color: #fff;
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  z-index: 0;

  .subheader--content {
    display: block;
    max-width: 1200px;
    width: 100%;
    padding: 15px 0px 0px 36px;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-end;
  }

  section {
    max-width: 1200px;
  }

  h1 {
    margin-bottom: 7px;
    color: ${Colors.darkazureishgray};
    font-family: CircularStd;
    font-size: 32px;
    font-weight: 600;
  }

  h2 {
    color: ${Colors.stonegreen1};
    font-family: CircularStd;
    font-size: 20px;
    font-weight: 600;
  }

  div {
    display: flex;
    margin-top: 19px;

    a {
      cursor: pointer;

      &.active {
        color: ${Colors.stonegreen1};
        border-bottom: 6px solid ${Colors.stonegreen1};
      }

      &:not(:last-child) {
        margin-right: 40px;
      }
    }
  }

  .rav-menu li {
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    padding: 0;
    margin: 0;
    a {
      color: #5d5d5d;
      font-size: 1em;
      text-transform: uppercase;
      border-bottom: 6px solid white;
      padding: 1em 0;
      margin-right: 5em;
      display: inline-block;
      &:hover,
      &.current {
        color: ${Colors.stonegreen1};
        border-color: ${Colors.stonegreen1};
      }
    }
    a.active {
      color: ${Colors.stonegreen1};
      border-bottom: 6px solid ${Colors.stonegreen1};
    }
  }
`
