/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

import { hasStoneCode } from 'Services/base.auth'
import { appConfig } from 'Config'

const baseURL = appConfig.portalURL
const servicesURL = `${baseURL}/servicos`
const requestNewBrandsUrl = `${baseURL}/gerenciamento-de-bandeiras`

export default function Services() {
  if (hasStoneCode()) {
    return (
      <li className="menu__item">
        <a className="menu__handle menu__handle--controls" href={servicesURL}>
          Serviços
        </a>
        <div className="menu__area">
          <div className="menu__target menu__target--sub">
            <div className="menu__area">
              <ul className="menu__list">
                <li className="menu__item">
                  <a className="menu__handle" href={requestNewBrandsUrl}>
                    Habilitar novas bandeiras
                  </a>
                </li>
                {/* <li className="menu__item">
                  <a className="menu__handle">
                    Pedir bobina
                  </a>
                </li> */}
                {/* <li className="menu__item ">
                  <a className="menu__handle">
                    Solicitar equipamento
                  </a>
                </li> */}
                <li className="menu__item">
                  <a className="menu__handle" href={servicesURL}>
                    Solicitar suporte
                  </a>
                </li>
                <li className="menu__item">
                  <a className="menu__handle" href={servicesURL}>
                    Baixar DIRF
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </li>
    )
  }
  return <></>
}
