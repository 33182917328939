import styled from 'styled-components'
import { Colors } from 'Styles/theme'

import { Status } from './shared'

interface WrapperProps {
  type?: Status
  active?: boolean
}

const getColorByType = (type?: Status): string => {
  switch (type) {
    case Status.ERROR:
      return Colors.red
    case Status.SUCCESS:
      return Colors.darkgreen
    default:
      return Colors.gray3
  }
}

const getBorderByType = (props: WrapperProps): string => {
  const borderWith = props.active ? '2px' : '1px'
  return `solid ${borderWith} ${getColorByType(props.type)}`
}

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  border: ${props => getBorderByType(props)};
  border-radius: 2px;
  padding: ${({ active }) => (active ? '0px' : '1px')};
`
export const Input = styled.input`
  align-items: center;
  border: none;
  padding: 1px 17px;
  width: 100%;
  height: 56px;
  color: ${Colors.darkblue};
  font-family: CircularStd;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;

  &:focus {
    outline-width: 0;
  }
`

export const IconWrapper = styled.div`
  display: flex;
  position: relative;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  padding: 0px 13px 0px 13px;
`
export const Label = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex-grow: 1;
  margin-top: 8px;
  height: 20px;
  color: #e74c3c;
`
