import styled, { keyframes } from 'styled-components'
import { Col, Row } from 'react-bootstrap'
import Card from 'Components/Card'

import { Colors } from 'Styles/theme'
import { ExtractBanner } from 'Assets'

const fadeIn = keyframes`
  from { opacity: 0; margin-top: 10px; }
  to { opacity: 1; margin-top: 0px; }
`

export const Content = styled(Col)`
  animation: ${fadeIn} 0.1s linear;

  * {
    font-family: CircularStd;
  }
`

export const Wrapper = styled(Row)`
  width: 100%;
  margin-left: 40px;

  @media screen and (max-width: 1200px) {
    margin-left: 0;
  }
`

export const SCard = styled(Card)`
  margin: 8px;
  padding: 24px;
  text-align: left;
`

export const Line = styled.hr`
  display: flex;
  border: 1px solid ${Colors.lavenderblue};
  margin: 24px 0px;
`

export const Link = styled.a`
  color: ${Colors.darkgreen};
  font-size: 14px;
  font-weight: 500;

  &:link,
  &:visited,
  &:hover,
  &:active {
    text-decoration: none;
    color: ${Colors.darkgreen};
  }
`

export const BannerExtract = styled.img.attrs({
  src: ExtractBanner,
})`
  cursor: pointer;
  margin-top: 55px;
  min-width: 264px;

  @media screen and (max-width: 1200px) {
    min-width: 244px;
  }
`
