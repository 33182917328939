import React, { useEffect, useState } from 'react'

import { TabEnum, TabFullScreenEnum } from 'Enums'
import { Container, STabList, TabThemes } from './styles'

export interface ITabs {
  name: string
  id: string
  hidden?: boolean
  dataTestId?: string
}

interface IPanel {
  children: JSX.Element[]
  tabSelected: TabEnum | TabFullScreenEnum
}
interface Props {
  tabs: ITabs[]
  selected?: TabEnum
  theme?: TabThemes
  onSelect(index: number): void
}

export function Tabs({
  tabs,
  theme,
  selected = TabEnum.SIMULATION,
  onSelect,
}: Props) {
  const [tabIndex, setTabIndex] = useState<TabEnum>(selected)

  useEffect(() => {
    onSelect(tabIndex)
  }, [tabIndex, onSelect])

  useEffect(() => {
    setTabIndex(selected)
  }, [selected])

  const handeUpdateIndex = (index: number) => () => setTabIndex(index)

  return (
    <STabList theme={theme}>
      {tabs.map(
        (tab, index) =>
          !tab.hidden && (
            <li
              key={tab.id}
              role="presentation"
              data-testid={tab.dataTestId}
              onClick={handeUpdateIndex(index)}
              className={index === tabIndex ? 'selected' : ''}
            >
              {tab.name}
            </li>
          )
      )}
    </STabList>
  )
}

export function Panel({ children, tabSelected, ...rest }: IPanel) {
  return <Container {...rest}>{children[tabSelected] ?? children}</Container>
}
