import React from 'react'

import { number2price, numberFormat } from 'Utils'

import { LoanStatusEnum } from 'Enums'
import { Column, InfoLabel, Label, Row, Title } from './styles'

interface TaxDetails {
  creditAmount: number
  operationFeeAmount: number
  interestRate: number
  taxAmount: number
  currentDebtAmount: number
  totalPaidAmount: number
  status: LoanStatusEnum
}

const disbursements = [
  LoanStatusEnum.DISBURSEMENT_REQUESTED,
  LoanStatusEnum.DISBURSEMENT_CONFIRMED,
]

export default function TaxDetails({
  creditAmount,
  operationFeeAmount,
  interestRate,
  taxAmount,
  currentDebtAmount,
  totalPaidAmount,
  status,
}: TaxDetails) {
  const operationFeeAmountPercentage = Number(
    ((operationFeeAmount / creditAmount) * 100).toFixed(1)
  )
  return (
    <>
      <Title>Detalhamento de tarifas</Title>
      <Row>
        <Column>
          <Label>Valor contratado</Label>
          {number2price(creditAmount)}
          <Label>IOF Estimado</Label>
          {number2price(taxAmount)}
        </Column>
        <Column>
          <Label>Taxa de juros</Label>
          {numberFormat(interestRate)}% ao mês
          {!disbursements.includes(status) && (
            <>
              <Label>Valor total (com taxas)</Label>
              {number2price(currentDebtAmount + totalPaidAmount)}
            </>
          )}
        </Column>
        <Column>
          <Label>Tarifa de contratação</Label>
          {numberFormat(operationFeeAmountPercentage)}% (
          {number2price(operationFeeAmount)})
        </Column>
      </Row>
      <InfoLabel>
        O valor total é estimado e inclui o valor contratado, a tarifa de
        contratação e o adicional de IOF.
      </InfoLabel>
    </>
  )
}
