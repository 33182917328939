import styled from 'styled-components'

export const Option = styled.button`
  margin: 0;
  border: none;
  background: #0db14b;
  padding: 12px 48px 12px 48px;
  text-align: left;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
`
