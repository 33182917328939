import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { IProposalSubmission } from 'Interfaces'
import { customerApi } from 'Services/base.auth'

export interface IProposalCustonHeaders {
  'Authorization-ChallengeCode'?: string
  'Authorization-ChallengeID'?: string
}

export interface IProposalSubmition {
  axiosConfig?: AxiosRequestConfig
  customHeaders?: IProposalCustonHeaders
  document: string
  proposalId: string
}

export interface LoanProposalApiResponse {
  data: IProposalSubmission
  messages: []
}

export default async function proposalSubmition(
  props: IProposalSubmition
): Promise<IProposalSubmission> {
  const { axiosConfig, customHeaders, document, proposalId } = props
  const {
    data: { data },
  }: AxiosResponse<LoanProposalApiResponse> = await customerApi.post(
    `/v0/credit/${document}/proposals/${proposalId}/loans`,
    {
      ...axiosConfig,
    },
    {
      headers: customHeaders,
    }
  )

  return data
}
