export enum Permission {
  CANCEL_TRANSACTION = 'CANCEL_TRANSACTION',
  USER_MANAGEMENT = 'USER_MANAGEMENT',
  PREPAY_PAYMENT = 'PREPAY_PAYMENT',
  REQUEST_PAPER_ROLL = 'REQUEST_PAPER_ROLL',
  REQUEST_EQUIPMENT_INSTALLATION = 'REQUEST_EQUIPMENT_INSTALLATION',
  MANAGE_MESSAGE = 'MANAGE_MESSAGE',
  ALTER_BANK_ACCOUNT = 'ALTER_BANK_ACCOUNT',
  REQUEST_CREDIT = 'REQUEST_CREDIT',
}

export enum Roles {
  CLIENT = 'Client',
  OPERATOR = 'Operator',
  RISK = 'Risk',
}
