import { Modal } from 'react-bootstrap'
import styled from 'styled-components'
import { Colors } from 'Styles/theme'

export const SModal = styled(Modal)`
  .modal-content {
    border-radius: 10px;
  }
`
export const CloseButton = styled.button`
  border: none;
  background: transparent;

  &:focus {
    outline: none;
  }
`

export const Body = styled(Modal.Body)`
  border-radius: 0px 0px 10px 10px;
  padding: 24px;

  * {
    font-family: CircularStd;
  }
`
export const Header = styled(Modal.Header)`
  border-radius: 10px 10px 0px 0px;
  padding: 25px 20px 0px;
  border: none;
`
export const Title = styled(Modal.Title)`
  line-height: 30px;
  letter-spacing: 0.4px;
  color: ${Colors.darkblue};
  font-family: CircularStd;
  font-size: 27px;
  font-weight: 500;
  margin-left: 10px;
`

export const Label = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.4px;
  color: ${Colors.darkblue};
  margin: 10px;
`
