import { AxiosError, AxiosRequestConfig } from 'axios'
import { IProposal } from 'Interfaces'
import { customerApi } from 'Services/base.auth'
import { handleError } from './helper/error-mapper'

export interface IGetProposalProps {
  axiosConfig?: AxiosRequestConfig
  document: string
  creditAmount: number
  creditLineId: string
}

export default async function getProposal(
  props: IGetProposalProps
): Promise<IProposal> {
  try {
    const axiosConfig = props && props.axiosConfig
    const { document, creditAmount, creditLineId } = props
    const res = await customerApi.post(
      `/v0/credit/${props.document}/proposals`,
      {
        ...axiosConfig,
        document,
        creditAmount,
        creditLineId,
      }
    )
    const { data }: { data: IProposal } = res.data

    return data
  } catch (error) {
    const axiosError = error as AxiosError

    axiosError.message = handleError(error)

    throw axiosError
  }
}
