import styled from 'styled-components'
import { Colors } from 'Styles/theme'

export const Wrapper = styled.div`
  font-family: CircularStd;
  text-align: center;
  letter-spacing: 0.4px;
  color: ${Colors.darkblue};
  margin-bottom: 35px;

  img {
    margin-bottom: 30px;
    width: 150px;
  }
`
export const Highlight = styled.span`
  color: ${Colors.darkgreen};
  font-weight: 700;
`
export const Title = styled.h1`
  font-family: CircularStd;
  font-weight: bold;
  font-size: 20px;
  line-height: 31px;
`
export const Text = styled.p`
  font-weight: 500;
  font-size: 15px;
  line-height: 15px;
`

export const SecondaryText = styled(Text)`
  margin-top: 26px;
  margin-bottom: 60px;
`
