import React, { useCallback, useContext } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Col } from 'react-bootstrap'
import { ILoanExtract } from 'Interfaces/loanExtract.interface'
import { useTranslation } from 'react-multi-lang'
import { number2price } from 'Utils'
import { IPagination } from 'Interfaces'
import { BaseLayoutState } from 'Components/LayoutState'
import { Warning } from 'Assets'
import { ExtractFiltersContext } from 'Contexts/ExtractFiltersContext'
import { LoaderTable } from './datatable'
import {
  AmountToPayData,
  AmountToPayHead,
  ContainerBody,
  ContainerHead,
  ReleaseTypeData,
  ReleaseTypeHead,
} from './styles'

interface ExtractDataTableProps {
  loanExtract: IPagination<ILoanExtract>
  onLoadMore(): void
}

const ExtractDataTable: React.FC<ExtractDataTableProps> = ({
  loanExtract,
  onLoadMore,
}) => {
  const { statements } = useContext(ExtractFiltersContext)
  const t = useTranslation()

  const hasMore = useCallback(() => {
    return Boolean(loanExtract.data.dailyStatements.length)
  }, [loanExtract])

  if (!statements.length) {
    return (
      <BaseLayoutState
        icon={Warning}
        title="Nenhum resultado encontrado."
        subtitle=""
        noCard
      />
    )
  }

  return (
    <>
      <ContainerHead>
        <Col md={1}>Dias</Col>
        <Col md={1}>Data</Col>
        <ReleaseTypeHead md={3}>Tipo de lancamento</ReleaseTypeHead>
        <Col md={3}>Método de pagamento</Col>
        <Col md={2}>Valor</Col>
        <AmountToPayHead md={2}>Valor a pagar</AmountToPayHead>
      </ContainerHead>

      <InfiniteScroll
        dataLength={statements.length}
        next={onLoadMore}
        hasMore={hasMore()}
        style={{ overflowX: 'hidden', marginBottom: 71 }}
        loader={<LoaderTable />}
      >
        {statements.map(daily => (
          <ContainerBody key={`${daily.day}-${daily.type}`}>
            <Col md={1}>{daily.day}</Col>
            <Col md={1}>{new Date(daily.date).toLocaleDateString('pt-BR')}</Col>
            <ReleaseTypeData md={3}>
              {t(`statements.entries.${daily.type}`)}
            </ReleaseTypeData>
            <Col md={3}>
              {daily.repaymentEntries
                ? t(
                    `statements.repaymentMethods.${daily.repaymentEntries[0].repaymentMethod}`
                  )
                : '-'}
            </Col>
            <Col md={2}>{number2price(daily.amount)}</Col>
            <AmountToPayData md={2}>
              {number2price(daily.debtAmountAfterEntry)}
            </AmountToPayData>
          </ContainerBody>
        ))}
      </InfiniteScroll>
    </>
  )
}

export default ExtractDataTable
