import { Modal } from 'react-bootstrap'
import styled from 'styled-components'
import { Colors } from 'Styles/theme'
import { Wave } from 'Assets'

export const SModal = styled(Modal)`
  .modal-content {
    border-radius: 10px;
    background-color: transparent;
  }
`

export const Body = styled(Modal.Body)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0px 0px 10px 10px;
  background: ${Colors.lightazureishgray};
  padding: 24px;

  p {
    text-align: center;
    line-height: 30px;
    color: ${Colors.darkblue};
    font-size: 16px;
  }

  * {
    font-family: CircularStd;
  }
`

export const Button = styled.button`
  display: flex;
  justify-content: center;
  margin: 20px 0px 3px;
  border: none;
  border-radius: 2px;
  background-color: ${Colors.darkgreen};
  padding: 12px 24px;
  width: 182px;
  color: white;
  font-size: 18px;
  font-weight: 400;

  &:focus {
    outline: none;
  }
`

export const Header = styled(Modal.Header)`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 10px 10px 0px 0px;
  background: ${Colors.lightazureishgray};
  background-image: url(${Wave});
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100%;
  padding: 25px 20px 0px;
  height: 220px;

  @media screen and (max-width: 550px) {
    background-size: cover;
  }
`
export const Title = styled(Modal.Title)`
  margin-bottom: 20px;
  text-align: center;
  line-height: 30px;
  color: #ffffff;
  font-family: CircularStd;
  font-size: 20px;
  font-weight: normal;
`
