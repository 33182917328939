import { AxiosRequestConfig } from 'axios'
import { customerApi } from 'Services/base.auth'

export interface IVerifyProps {
  axiosConfig?: AxiosRequestConfig
  email: string
  document: string
}

export default async function verify(props: IVerifyProps): Promise<any> {
  const axiosConfig = props && props.axiosConfig
  const res = await customerApi.post(
    `/v0/users/identity/${props.email}/verify`,
    {
      document: props.document,
      ...axiosConfig,
    }
  )
  const { data }: { data: any } = res.data

  return data
}
