import React from 'react'

import { CloseBlack } from 'Assets'
import { ILoanExtract } from 'Interfaces/loanExtract.interface'
import { formatDate } from 'Helper/date'
import { IPagination } from 'Interfaces'
import { Container, FlexAlignmnet } from './styles'
import MarginWrapper from '../MarginWrapper'

interface Props {
  loanExtract: IPagination<ILoanExtract>
}
interface Dispatch {
  onClose(): void
}

type HeaderProps = Props & Dispatch

export default function Header({ onClose, loanExtract }: HeaderProps) {
  const { loanCreationDate } = loanExtract.data

  return (
    <Container>
      <MarginWrapper>
        <FlexAlignmnet>
          <h1 className="title">
            <span>Extrato do Empréstimo</span>{' '}
            {formatDate(new Date(loanCreationDate))}
          </h1>

          <div data-testid="close-area" onClick={onClose} aria-hidden="true">
            <img className="close" src={CloseBlack} alt="icone de fechar" />
          </div>
        </FlexAlignmnet>
      </MarginWrapper>
    </Container>
  )
}
