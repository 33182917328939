import styled from 'styled-components'
import { SIZES } from './index.interface'
import { Colors } from '../../Styles/theme'

export interface StyleProps extends React.HTMLAttributes<HTMLParagraphElement> {
  'data-size'?: SIZES
  'data-bold'?: boolean
  'data-color'?: string
}

export const StyleText = styled.p`
  margin: 0;
  font-family: 'CircularStd';
  font-size: ${(props: StyleProps) => props['data-size'] || '14px'};
  font-weight: ${(props: StyleProps) =>
    props['data-bold'] ? 'bold' : 'normal'};
  color: ${(props: StyleProps) => props.color ?? Colors.black};
`
