import { AxiosError } from 'axios'
import { APIErrorResponseMapperEnum, ErrorMapperEnum } from 'Enums/proposal'

export function isInvalidCnpj(message: string) {
  return message.includes(
    APIErrorResponseMapperEnum[ErrorMapperEnum.INVALID_CNPJ]
  )
}

export function isInvalidBankAccount(message: string) {
  return message.includes(
    APIErrorResponseMapperEnum[ErrorMapperEnum.INVALID_BANK_ACCOUNT]
  )
}

export function isSavingBankAccountType(message: string) {
  return message.includes(
    APIErrorResponseMapperEnum[ErrorMapperEnum.INVALID_BANK_ACCOUNT_SAVING]
  )
}

export function isCreditLineNotActive(message: string) {
  return message.includes(
    APIErrorResponseMapperEnum[ErrorMapperEnum.CREDIT_LINE_IS_NOT_ACTIVE]
  )
}

export function doCreditLineAlreadyHasLoan(message: string) {
  return message.includes(
    APIErrorResponseMapperEnum[ErrorMapperEnum.CREDIT_LINE_ALREADY_HAS_LOAN]
  )
}

export function doBorrowerAlreadyHasLoan(message: string) {
  return message.includes(
    APIErrorResponseMapperEnum[ErrorMapperEnum.BORROWER_ALREADY_HAS_LOAN]
  )
}

export function hasCreditLock(message: string) {
  return message.includes(
    APIErrorResponseMapperEnum[ErrorMapperEnum.CREDIT_LOCK]
  )
}

export function handleError(responseError: AxiosError): string {
  const FIRST_INDEX = 0
  let message = ErrorMapperEnum.GENERAL

  if (responseError.response) {
    const { messages } = responseError.response?.data
    message = messages[FIRST_INDEX] ?? ''
  }

  if (isInvalidCnpj(message)) {
    return ErrorMapperEnum.INVALID_CNPJ
  }

  if (isInvalidBankAccount(message)) {
    return ErrorMapperEnum.INVALID_BANK_ACCOUNT
  }

  if (isSavingBankAccountType(message)) {
    return ErrorMapperEnum.INVALID_BANK_ACCOUNT_SAVING
  }

  if (isCreditLineNotActive(message)) {
    return ErrorMapperEnum.CREDIT_LINE_IS_NOT_ACTIVE
  }

  if (doCreditLineAlreadyHasLoan(message)) {
    return ErrorMapperEnum.CREDIT_LINE_ALREADY_HAS_LOAN
  }

  if (doBorrowerAlreadyHasLoan(message)) {
    return ErrorMapperEnum.BORROWER_ALREADY_HAS_LOAN
  }

  if (hasCreditLock(message)) {
    return ErrorMapperEnum.CREDIT_LOCK
  }

  return ErrorMapperEnum.GENERAL
}
