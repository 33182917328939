import React from 'react'
import { Input, InputWrapper, Label, Wrapper } from './styles'

export interface Props {
  value: string
  onChange(value: string): void
}

export default function CpfInput({ value, onChange }: Props) {
  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const onlyNumberValue = event.target.value.replace(/\D/, '')
    if (onlyNumberValue.length <= 11) {
      onChange(onlyNumberValue)
    }
  }

  return (
    <Wrapper>
      <Label>Digite seu CPF (apenas números)</Label>
      <InputWrapper>
        <Input
          type="text"
          value={value}
          onChange={handleChange}
          data-testid="cpf-input"
        />
      </InputWrapper>
    </Wrapper>
  )
}
