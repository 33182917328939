import React, { useEffect } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import ReactGA from 'react-ga'
import { hotjar } from 'react-hotjar'

import { analyticsConfig, hotjarConfig } from 'Config'
import { Navigation } from 'Containers/navigation'
import LoanManagement from 'Pages/LoanManagement'
import * as AppcuesService from 'Services/appcues'

ReactGA.initialize(analyticsConfig.trackingCode, analyticsConfig.options)
hotjar.initialize(hotjarConfig.hjid, hotjarConfig.hjsv)

export default function Routes(): JSX.Element {
  const location = useLocation()

  useEffect(() => {
    ReactGA.set({ page: location.pathname })
    ReactGA.pageview(location.pathname)
    AppcuesService.trackPage()
  }, [location])

  return (
    <Navigation>
      <Switch>
        <Route path="/" component={LoanManagement} />
      </Switch>
    </Navigation>
  )
}
