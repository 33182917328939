import React, { useState } from 'react'
import { number2price, numberFormat } from 'Utils'

import {
  Collapse,
  Column,
  Currency,
  Details,
  Label,
  ListWrapper,
  Row,
  RowWithBorder,
  SJumbotron,
  Section,
  Small,
  SubTitle,
  Symbol,
  Title,
} from './styles'

interface Props {
  loanValue: number
  hiringFee: number
  estimatedIOF: number
  interestRate: number
  totalEffectiveCostPerMonth: number
  totalEffectiveCostPerYear: number
}

export default function CostInfo({
  loanValue,
  hiringFee,
  estimatedIOF,
  interestRate,
  totalEffectiveCostPerMonth,
  totalEffectiveCostPerYear,
}: Props) {
  const [isOpen, setIsOpen] = useState<boolean>(true)
  return (
    <SJumbotron>
      <Section>
        <Row>
          <div>
            <Title>Valor do Empréstimo</Title>
            <Symbol>R$</Symbol>
            <Currency>{number2price(loanValue, false)}</Currency>
          </div>
          <Column>
            <Details onClick={() => setIsOpen(!isOpen)}>Detalhes</Details>
          </Column>
        </Row>
      </Section>
      <Collapse isOpen={isOpen}>
        <ListWrapper>
          <RowWithBorder>
            <Label>Taxa de Juros</Label>
            <Label>{numberFormat(interestRate)}% ao mês</Label>
          </RowWithBorder>
          <RowWithBorder>
            <Label>Tarifa de Contratação </Label>
            <Label>{number2price(hiringFee, true)}</Label>
          </RowWithBorder>
          <RowWithBorder>
            <Label>IOF Estimado</Label>
            <Label>{number2price(estimatedIOF, true)}</Label>
          </RowWithBorder>
        </ListWrapper>
      </Collapse>
      <Section>
        <Row>
          <Column>
            <h1>Custo Efetivo Total</h1>
          </Column>
          <Column>
            <SubTitle>
              {numberFormat(totalEffectiveCostPerMonth)}% ao mês
            </SubTitle>
            <Small>({numberFormat(totalEffectiveCostPerYear)}% ao ano)</Small>
          </Column>
        </Row>
      </Section>
    </SJumbotron>
  )
}
