/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import LockBar from 'Containers/navigation/partials/Header/LockBar'
import Profile from '../Profile'
import Logo from './Logo'
import NavBar from './NavBar'
import { MenuSection, Wrapper } from './styles'

export default function Header() {
  return (
    <>
      <LockBar />
      <Wrapper>
        <MenuSection className="menu--portal-stone">
          <Logo />
          <NavBar />
          <Profile />
        </MenuSection>
      </Wrapper>
    </>
  )
}
