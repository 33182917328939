import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { Colors } from 'Styles/theme'
import { Container as SummaryContainer } from './Summary/styles'
import { Container as RootContainer, SideSection } from './styles'
import { FlexAlignmnet, Container as HeaderContainer } from './Header/styles'
import { Container as FiltersContainer } from './Filters/styles'
import {
  AmountToPayData,
  AmountToPayHead,
  ContainerBody,
  ContainerHead,
  ReleaseTypeData,
  ReleaseTypeHead,
} from './ExtractDataTable/styles'
import MarginWrapper from './MarginWrapper'
import Divider from './Divider'

const Header = () => (
  <HeaderContainer>
    <MarginWrapper>
      <FlexAlignmnet>
        <Skeleton width={450} height={35} />

        <Skeleton width={35} height={35} />
      </FlexAlignmnet>
    </MarginWrapper>
  </HeaderContainer>
)

const Summary = () => (
  <SummaryContainer>
    <Col md={4}>
      <Skeleton width={102} height={54} />
    </Col>

    <Col md={4}>
      <Skeleton width={150} height={54} />
    </Col>

    <Col md={4}>
      <Skeleton width={98} height={54} />
    </Col>
  </SummaryContainer>
)

const Filters = () => (
  <FiltersContainer>
    <Col sm={12} md={6} lg={4}>
      <Skeleton width={130} height={14} />
      <Skeleton width={263} height={49} />
    </Col>

    <Col sm={12} md={6} lg={4}>
      <Skeleton width={130} height={14} />
      <Skeleton width={263} height={49} />
    </Col>

    <Col sm={12} md={6} lg={4}>
      <Skeleton width={1} height={1} />
      <Skeleton width={263} height={49} />
    </Col>
  </FiltersContainer>
)

const ExtractDataTable = () => (
  <>
    <ContainerHead data-testid="skeleton-container-head">
      <Col md={1}>Dias</Col>
      <Col md={1}>Data</Col>
      <ReleaseTypeHead md={3}>Tipo de lancamento</ReleaseTypeHead>
      <Col md={3}>Método de pagamento</Col>
      <Col md={2}>Valor</Col>
      <AmountToPayHead md={2}>Valor a pagar</AmountToPayHead>
    </ContainerHead>

    <>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(index => (
        <ContainerBody key={`skeleton-${index}`}>
          <Col md={1}>
            <Skeleton width={32} height={16} />
          </Col>
          <Col md={1}>
            <Skeleton width={60} height={16} />
          </Col>
          <ReleaseTypeData md={3}>
            <Skeleton width={163} height={16} />
          </ReleaseTypeData>
          <Col md={3}>
            <Skeleton width={163} height={16} />
          </Col>
          <Col md={2}>
            <Skeleton width={91} height={16} />
          </Col>
          <AmountToPayData md={2}>
            <Skeleton width={91} height={16} />
          </AmountToPayData>
        </ContainerBody>
      ))}
    </>
  </>
)

const ExportActions = () => (
  <>
    <Skeleton width={235} height={49} style={{ marginBottom: 24 }} />
    <Skeleton width={235} height={49} />
  </>
)

export default function LoansSkeleton() {
  return (
    <SkeletonTheme
      color={Colors.lightazureishgray}
      highlightColor={Colors.white}
    >
      <RootContainer style={{ paddingBottom: 50 }}>
        <Header />
        <MarginWrapper style={{ marginTop: 42 }}>
          <Row>
            <Col md={9}>
              <Summary />
              <Divider />
              <Filters />
              <ExtractDataTable />
            </Col>

            <SideSection md={3}>
              <ExportActions />
            </SideSection>
          </Row>
        </MarginWrapper>
      </RootContainer>
    </SkeletonTheme>
  )
}
