import React from 'react'

import { number2price } from 'Utils'

import { IProposalSubmission } from 'Interfaces'

import { Body, Button, Header, SModal, Title } from './styles'

interface Props {
  proposalSubmission: IProposalSubmission
  handleConfirm(): void
}

export default function LoanSuccessModal({
  proposalSubmission,
  handleConfirm,
}: Props) {
  return (
    <SModal show size="md" data-testid="success-modal" onHide={handleConfirm}>
      <Header>
        <Title>
          <strong>Empréstimo solicitado com sucesso!</strong>
        </Title>
      </Header>
      <Body>
        <p>
          Estamos analisando o seu pedido. Se aprovado, em até{' '}
          <strong>5 dias úteis</strong> o valor de
          <strong> {number2price(proposalSubmission.creditAmount)}</strong> será
          depositado na sua conta.
        </p>
        <Button onClick={handleConfirm}>Ver empréstimo</Button>
      </Body>
    </SModal>
  )
}
