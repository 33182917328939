import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Panel } from 'Components/Tabs'
import SubHeader from 'Containers/navigation/partials/SubHeader'
import LoanModel, { ILoanModel } from 'Models/Loan'

import { IStoneCode } from 'Interfaces'
import useStoneCodes from 'Hooks/useStoneCodes'
import { hasAlertCreated } from 'Services/firebase'
import useCreditLines, { IUseCreditLines } from 'Hooks/useCreditLines'
import NetworkErrorContext from 'Contexts/NetworkErrorContext'
import useNetworkError, { IUseNetworkError } from 'Hooks/useNetworkError'
import LoanProvider from 'Contexts/LoanDetailsContext'
import { IUseLoanProps, IUseLoanReturn, useLoan } from 'Hooks/useLoan'
import StoneCodesContext from 'Contexts/StoneCodesContext'
import LoansContext from 'Contexts/LoansContext'
import TabSelectedContext from 'Contexts/TabSelectedContext'
import { useTabs } from 'Hooks/useTabs'
import CreditLineContext from 'Contexts/CreditLineContext'
import {
  MobileAccessContext,
  MobileAccessContextStore,
} from 'Contexts/MobileAccessContext'
import DocumentSelect from './DocumentSelect'
import Details from './Details'
import History from './History'
import Simulation from './Simulation'
import { Container, ContainerPanel, ContainerPanelCenter } from './styles'
import Extract from './Extract'

interface Props {
  loanModel?(): ILoanModel
  stoneCodesHook?(): [IStoneCode[], boolean, boolean]
  firebaseHasAlertCreated?(
    document: string,
    stoneCode: string,
    email: string
  ): boolean
  creditLinesHook?(document: string): IUseCreditLines
  networkErrorHook?(): IUseNetworkError
  loanHook?(props: IUseLoanProps): IUseLoanReturn
}

export default function LoanManagement({
  loanModel = LoanModel,
  stoneCodesHook = useStoneCodes,
  firebaseHasAlertCreated = hasAlertCreated,
  creditLinesHook = useCreditLines,
  loanHook = useLoan,
  networkErrorHook = useNetworkError,
}: Props) {
  const [document, setDocument] = useState<string>('')
  const [hideHistoryTab, setHideHistoryTab] = useState<boolean>(true)
  const { show: filterBlur } = useContext<MobileAccessContextStore>(
    MobileAccessContext
  )

  const tabsHook = useTabs()
  const hookNetworkError = networkErrorHook()
  const stoneCodes = stoneCodesHook()
  const loansHook = loanHook({ document, loanModel })
  const { loans, getLoans } = loansHook

  const checkIfHasLoans = useCallback(async () => {
    if (!document) {
      return
    }

    await getLoans()
  }, [document, getLoans])

  const writeClassFilterBlur = (filterBlur && 'filter-blur') || ''

  useEffect(() => {
    const hasLoan = loans?.data.length ?? false
    setHideHistoryTab(!hasLoan)
  }, [loans])

  useEffect(() => {
    checkIfHasLoans()
  }, [document, checkIfHasLoans])

  return (
    <LoansContext.Provider value={loansHook}>
      <StoneCodesContext.Provider value={stoneCodes}>
        <CreditLineContext>
          <TabSelectedContext.Provider value={tabsHook}>
            <LoanProvider>
              <Container className={writeClassFilterBlur}>
                <SubHeader
                  title="Empréstimo"
                  subtitle="Reforce o orçamento do seu negócio"
                  hideHistory={hideHistoryTab}
                />
                <Panel tabSelected={tabsHook.tabFullScreenSelected}>
                  <ContainerPanel>
                    <ContainerPanelCenter>
                      <DocumentSelect onChange={setDocument} />
                      <Panel tabSelected={tabsHook.tabSelected}>
                        <NetworkErrorContext.Provider value={hookNetworkError}>
                          <Simulation
                            document={document}
                            firebaseHasAlertCreated={firebaseHasAlertCreated}
                          />
                        </NetworkErrorContext.Provider>

                        <History currentDocument={document} />
                      </Panel>
                    </ContainerPanelCenter>
                  </ContainerPanel>

                  <ContainerPanel>
                    <ContainerPanelCenter>
                      <Details />
                    </ContainerPanelCenter>
                  </ContainerPanel>

                  <Extract document={document} />
                </Panel>
              </Container>
            </LoanProvider>
          </TabSelectedContext.Provider>
        </CreditLineContext>
      </StoneCodesContext.Provider>
    </LoansContext.Provider>
  )
}
