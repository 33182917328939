import React, { useEffect, useState } from 'react'
import { Router } from 'react-router-dom'

import { currentUser } from 'Services/base.auth'
import { ICurrentUser } from 'Interfaces'
import GlobalStyle from 'Styles/global'
import history from 'Services/history'
import Chat from 'Components/Chat'
import UserContext from 'Contexts/UserContext'
import { logout } from 'Helper/logout'
import { setDefaultLanguage, setDefaultTranslations } from 'react-multi-lang'
import { Locale } from 'Enums/locale'
import ptBR from 'Translations/pt-BR.json'
import en from 'Translations/en.json'
import MobileAccessWarningModal from 'BusinessComponent/MobileAccessWarningModal'
import MobileAccessContextProvider from 'Contexts/MobileAccessContext'
import Routes from './routes'

interface Props {
  token: string
  handleLogout?(): void
}

setDefaultTranslations({ [Locale.PT_BR]: ptBR, en })
setDefaultLanguage(Locale.PT_BR)

export default function App({
  token,
  handleLogout = logout,
}: Props): JSX.Element {
  const [user, setUser] = useState<ICurrentUser | null>(null)

  useEffect(() => {
    async function loadCurrentUser(): Promise<void> {
      try {
        const response = await currentUser()
        if (response.status >= 400) {
          handleLogout()
        } else {
          const { data } = await response.json()

          setUser(data)
        }
      } catch (error) {
        handleLogout()
      }
    }

    loadCurrentUser()
  }, [handleLogout])

  if (!token) {
    handleLogout()
    return <></>
  }

  return (
    <UserContext.Provider value={user}>
      <MobileAccessContextProvider>
        <Router history={history}>
          <Routes />
          <Chat />
          <MobileAccessWarningModal />
          <GlobalStyle />
        </Router>
      </MobileAccessContextProvider>
    </UserContext.Provider>
  )
}
