import styled from 'styled-components'
import { Colors } from 'Styles/theme'

export const Wrapper = styled.div``

export const InputWrapper = styled.div`
  display: flex;
  margin: 6px 3px 20px 3px;
  border: 1px solid #c3c8d2;
  border-radius: 2px;
  height: 50px;
`

export const Label = styled.div`
  font-size: 12px;
  font-weight: 500;
  margin: 5px 5px;
`

export const Input = styled.input`
  border: none;
  padding: 16px;
  width: 100%;
  height: 100%;
  line-height: 21px;
  color: ${Colors.gray3};
  font-family: CircularStd;
  font-size: 14px;

  &:focus {
    outline: none;
  }

  &::placeholder {
    font-size: 14px;
    color: ${Colors.gray3};
    font-family: CircularStd;
  }
`
