import React from 'react'

import { utcFormatDate } from 'Utils'

import { Back } from 'Assets'
import { SecondaryTitle, Title, Wrapper } from './styles'

interface Header {
  date: string
  onBack(): void
}

export default function Header({ date, onBack }: Header) {
  return (
    <Wrapper>
      <button type="button" data-testid="back-button" onClick={onBack}>
        <img src={Back} alt="Icone de voltar" />
      </button>
      <Title>
        Detalhes do Empréstimo{' '}
        <SecondaryTitle>{utcFormatDate(date)}</SecondaryTitle>
      </Title>
    </Wrapper>
  )
}
