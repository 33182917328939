import React from 'react'

import { Column, Row, Title } from './styles'

export interface Props {
  onKnowMore(): void
}

export default function BankLockDetails({ onKnowMore }: Props) {
  return (
    <Row>
      <Column>
        <Title>Trava bancária</Title>
        Este empréstimo utiliza seus recebíveis como garantia.
      </Column>
      <Column>
        <button type="button" onClick={onKnowMore}>
          Saiba mais
        </button>
      </Column>
    </Row>
  )
}
