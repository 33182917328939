// TODO: esternalizar para um serviço
import { AxiosRequestConfig } from 'axios'
import Cookies from 'js-cookie'
import { customerApi } from 'Services/base.auth'
import { IChallengeRequest } from 'Interfaces'

export interface IDoChallenge {
  axiosConfig?: AxiosRequestConfig
}
export default async function doChallenge(
  props: IDoChallenge
): Promise<IChallengeRequest> {
  const token = Cookies.get('external_token')
  const { axiosConfig } = props

  const response = await customerApi.post(`/v0/challenge`, {
    ...axiosConfig,
    token,
  })

  const { data }: { data: IChallengeRequest } = response

  return data
}
